@charset "UTF-8";
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: star;
  src: url('../../../fonts/star.eot');
  src: url('../../../fonts/star.eot?#iefix') format("embedded-opentype"), url('../../../fonts/star.woff') format("woff"), url('../../../fonts/star.ttf') format("truetype"), url('../../../fonts/star.svg#star') format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: WooCommerce;
    src: url('../../../fonts/WooCommerce.eot');
    src: url('../../../fonts/WooCommerce.eot?#iefix') format("embedded-opentype"), url('../../../fonts/WooCommerce.woff') format("woff"), url('../../../fonts/WooCommerce.ttf') format("truetype"), url('../../../fonts/WooCommerce.svg#WooCommerce') format("svg");
    font-weight: 400;
    font-style: normal
}

.woocommerce-store-notice,
p.demo_store {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: 1em;
  padding: 1em 0;
  text-align: center;
  background-color: #a46497;
  color: #fff;
  z-index: 99998;
  -webkit-box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none;
}

.woocommerce-store-notice a,
p.demo_store a {
  color: #fff;
  text-decoration: underline;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.admin-bar p.demo_store {
  top: 32px;
}

.clear {
  clear: both;
}

.blockUI.blockOverlay {
  position: relative;
}

.blockUI.blockOverlay::before {
  height: 1em;
  width: 1em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -.5em;
  margin-top: -.5em;
  content: '';
  -webkit-animation: spin 1s ease-in-out infinite;
  animation: spin 1s ease-in-out infinite;
  //background: url(../images/icons/loader.svg) center center;
  background-size: cover;
  line-height: 1;
  text-align: center;
  font-size: 2em;
  color: rgba(0, 0, 0, 0.75);
}

.loader::before {
  height: 1em;
  width: 1em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -.5em;
  margin-top: -.5em;
  content: '';
  -webkit-animation: spin 1s ease-in-out infinite;
  animation: spin 1s ease-in-out infinite;
  //background: url(../images/icons/loader.svg) center center;
  background-size: cover;
  line-height: 1;
  text-align: center;
  font-size: 2em;
  color: rgba(0, 0, 0, 0.75);
}

a.remove {
  display: block;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  font-weight: 700;
  border: 0;
}

a.remove:hover {
  color: #fff !important;
  background: red;
}

small.note {
  display: block;
  color: #777;
  font-size: .857em;
  margin-top: 10px;
}

.woocommerce-breadcrumb {
  margin: 0 0 1em;
  padding: 0;
  font-size: .92em;
  color: #777;
}

.woocommerce-breadcrumb::after,
.woocommerce-breadcrumb::before {
  content: ' ';
  display: table;
}

.woocommerce-breadcrumb::after {
  clear: both;
}

.woocommerce-breadcrumb a {
  color: #777;
}

.quantity .qty {
  width: 3.631em;
  text-align: center;
}

div.product {
  margin-bottom: 0;
  position: relative;
}

div.product .product_title {
  clear: none;
  margin-top: 0;
  padding: 0;
}

div.product p.price,
div.product span.price {
  color: #77a464;
  font-size: 1.25em;
}

div.product p.price ins,
div.product span.price ins {
  background: inherit;
  font-weight: 700;
  display: inline-block;
}

div.product p.price del,
div.product span.price del {
  opacity: .5;
  display: inline-block;
}

div.product p.stock {
  font-size: .92em;
}

div.product .stock {
  color: #77a464;
}

div.product .out-of-stock {
  color: red;
}

div.product .woocommerce-product-rating {
  margin-bottom: 1.618em;
}

div.product div.images {
  margin-bottom: 2em;
}

div.product div.images img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}

div.product div.images div.thumbnails {
  padding-top: 1em;
}

div.product div.images.woocommerce-product-gallery {
  position: relative;
}

div.product div.images .woocommerce-product-gallery__wrapper {
  -webkit-transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
  transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
  margin: 0;
  padding: 0;
}

div.product div.images .woocommerce-product-gallery__wrapper .zoomImg {
  background-color: #fff;
  opacity: 0;
}

div.product div.images .woocommerce-product-gallery__image--placeholder {
  border: 1px solid #f2f2f2;
}

div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
  width: 25%;
  display: inline-block;
}

div.product div.images .woocommerce-product-gallery__trigger {
  position: absolute;
  top: .5em;
  right: .5em;
  font-size: 2em;
  z-index: 9;
  width: 36px;
  height: 36px;
  background: #fff;
  text-indent: -9999px;
  border-radius: 100%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

div.product div.images .woocommerce-product-gallery__trigger::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #000;
  border-radius: 100%;
  position: absolute;
  top: 9px;
  left: 9px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

div.product div.images .woocommerce-product-gallery__trigger::after {
  content: "";
  display: block;
  width: 2px;
  height: 8px;
  background: #000;
  border-radius: 6px;
  position: absolute;
  top: 19px;
  left: 22px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

div.product div.images .flex-control-thumbs {
  overflow: hidden;
  zoom: 1;
  margin: 0;
  padding: 0;
}

div.product div.images .flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
  list-style: none;
}

div.product div.images .flex-control-thumbs li img {
  cursor: pointer;
  opacity: .5;
  margin: 0;
}

div.product div.images .flex-control-thumbs li img.flex-active,
div.product div.images .flex-control-thumbs li img:hover {
  opacity: 1;
}

div.product .woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1) {
  clear: left;
}

div.product .woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1) {
  clear: left;
}

div.product .woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1) {
  clear: left;
}

div.product div.summary {
  margin-bottom: 2em;
}

div.product div.social {
  text-align: right;
  margin: 0 0 1em;
}

div.product div.social span {
  margin: 0 0 0 2px;
}

div.product div.social span span {
  margin: 0;
}

div.product div.social span .stButton .chicklets {
  padding-left: 16px;
  width: 0;
}

div.product div.social iframe {
  float: left;
  margin-top: 3px;
}

div.product .woocommerce-tabs ul.tabs {
  list-style: none;
  padding: 0 0 0 1em;
  margin: 0 0 1.618em;
  overflow: hidden;
  position: relative;
}

div.product .woocommerce-tabs ul.tabs li {
  border: 1px solid #d3ced2;
  background-color: #ebe9eb;
  display: inline-block;
  position: relative;
  z-index: 0;
  border-radius: 4px 4px 0 0;
  margin: 0 -5px;
  padding: 0 1em;
}

div.product .woocommerce-tabs ul.tabs li a {
  display: inline-block;
  padding: .5em 0;
  font-weight: 700;
  color: #515151;
  text-decoration: none;
}

div.product .woocommerce-tabs ul.tabs li a:hover {
  text-decoration: none;
  color: #6b6a6b;
}

div.product .woocommerce-tabs ul.tabs li.active {
  background: #fff;
  z-index: 2;
  border-bottom-color: #fff;
}

div.product .woocommerce-tabs ul.tabs li.active a {
  color: inherit;
  text-shadow: inherit;
}

div.product .woocommerce-tabs ul.tabs li.active::before {
  -webkit-box-shadow: 2px 2px 0 #fff;
  box-shadow: 2px 2px 0 #fff;
}

div.product .woocommerce-tabs ul.tabs li.active::after {
  -webkit-box-shadow: -2px 2px 0 #fff;
  box-shadow: -2px 2px 0 #fff;
}

div.product .woocommerce-tabs ul.tabs li::after,
div.product .woocommerce-tabs ul.tabs li::before {
  border: 1px solid #d3ced2;
  position: absolute;
  bottom: -1px;
  width: 5px;
  height: 5px;
  content: " ";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div.product .woocommerce-tabs ul.tabs li::before {
  left: -5px;
  border-bottom-right-radius: 4px;
  border-width: 0 1px 1px 0;
  -webkit-box-shadow: 2px 2px 0 #ebe9eb;
  box-shadow: 2px 2px 0 #ebe9eb;
}

div.product .woocommerce-tabs ul.tabs li::after {
  right: -5px;
  border-bottom-left-radius: 4px;
  border-width: 0 0 1px 1px;
  -webkit-box-shadow: -2px 2px 0 #ebe9eb;
  box-shadow: -2px 2px 0 #ebe9eb;
}

div.product .woocommerce-tabs ul.tabs::before {
  position: absolute;
  content: " ";
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #d3ced2;
  z-index: 1;
}

div.product .woocommerce-tabs .panel {
  margin: 0 0 2em;
  padding: 0;
}

div.product p.cart {
  margin-bottom: 2em;
}

div.product p.cart::after,
div.product p.cart::before {
  content: ' ';
  display: table;
}

div.product p.cart::after {
  clear: both;
}

div.product form.cart {
  margin-bottom: 2em;
}

div.product form.cart::after,
div.product form.cart::before {
  content: ' ';
  display: table;
}

div.product form.cart::after {
  clear: both;
}

div.product form.cart div.quantity {
  float: left;
  margin: 0 4px 0 0;
}

div.product form.cart table {
  border-width: 0 0 1px;
}

div.product form.cart table td {
  padding-left: 0;
}

div.product form.cart table div.quantity {
  float: none;
  margin: 0;
}

div.product form.cart table small.stock {
  display: block;
  float: none;
}

div.product form.cart .variations {
  margin-bottom: 1em;
  border: 0;
  width: 100%;
}

div.product form.cart .variations td,
div.product form.cart .variations th {
  border: 0;
  vertical-align: top;
  line-height: 2em;
}

div.product form.cart .variations label {
  font-weight: 700;
}

div.product form.cart .variations select {
  max-width: 100%;
  min-width: 75%;
  display: inline-block;
  margin-right: 1em;
}

div.product form.cart .variations td.label {
  padding-right: 1em;
}

div.product form.cart .woocommerce-variation-description p {
  margin-bottom: 1em;
}

div.product form.cart .reset_variations {
  visibility: hidden;
  font-size: .83em;
}

div.product form.cart .wc-no-matching-variations {
  display: none;
}

div.product form.cart .button {
  vertical-align: middle;
  float: left;
}

div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label {
  padding-right: 1em;
  padding-left: 1em;
}

div.product form.cart .group_table td {
  vertical-align: top;
  padding-bottom: .5em;
  border: 0;
}

div.product form.cart .group_table td:first-child {
  width: 4em;
  text-align: center;
}

div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  -webkit-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
}

span.onsale {
  min-height: 3.236em;
  min-width: 3.236em;
  padding: .202em;
  font-size: 1em;
  font-weight: 700;
  position: absolute;
  text-align: center;
  line-height: 3.236;
  top: -.5em;
  left: -.5em;
  margin: 0;
  border-radius: 100%;
  background-color: #77a464;
  color: #fff;
  font-size: .857em;
  z-index: 9;
}

.products ul,
ul.products {
  margin: 0 0 1em;
  padding: 0;
  list-style: none outside;
  clear: both;
}

.products ul::after,
.products ul::before,
ul.products::after,
ul.products::before {
  content: ' ';
  display: table;
}

.products ul::after,
ul.products::after {
  clear: both;
}

.products ul li,
ul.products li {
  list-style: none outside;
}

ul.products li.product .onsale {
  top: 0;
  right: 0;
  left: auto;
  margin: -.5em -.5em 0 0;
}

ul.products li.product .woocommerce-loop-category__title,
ul.products li.product .woocommerce-loop-product__title,
ul.products li.product h3 {
  padding: .5em 0;
  margin: 0;
  font-size: 1em;
}

ul.products li.product a {
  text-decoration: none;
}

ul.products li.product a img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 0 1em;
  -webkit-box-shadow: none;
  box-shadow: none;
}

ul.products li.product strong {
  display: block;
}

ul.products li.product .woocommerce-placeholder {
  border: 1px solid #f2f2f2;
}

ul.products li.product .star-rating {
  font-size: .857em;
}

ul.products li.product .button {
  margin-top: 1em;
}

ul.products li.product .price {
  color: #77a464;
  display: block;
  font-weight: 400;
  margin-bottom: .5em;
  font-size: .857em;
}

ul.products li.product .price del {
  color: inherit;
  opacity: .5;
  display: inline-block;
}

ul.products li.product .price ins {
  background: 0 0;
  font-weight: 700;
  display: inline-block;
}

ul.products li.product .price .from {
  font-size: .67em;
  margin: -2px 0 0 0;
  text-transform: uppercase;
  color: rgba(132, 132, 132, 0.5);
}

.woocommerce-result-count {
  margin: 0 0 1em;
}

.woocommerce-ordering {
  margin: 0 0 1em;
}

.woocommerce-ordering select {
  vertical-align: top;
}

nav.woocommerce-pagination {
  text-align: center;
}

nav.woocommerce-pagination ul {
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  clear: both;
  border: 1px solid #d3ced2;
  border-right: 0;
  margin: 1px;
}

nav.woocommerce-pagination ul li {
  border-right: 1px solid #d3ced2;
  padding: 0;
  margin: 0;
  float: left;
  display: inline;
  overflow: hidden;
}

nav.woocommerce-pagination ul li a,
nav.woocommerce-pagination ul li span {
  margin: 0;
  text-decoration: none;
  padding: 0;
  line-height: 1;
  font-size: 1em;
  font-weight: 400;
  padding: .5em;
  min-width: 1em;
  display: block;
}

nav.woocommerce-pagination ul li a:focus,
nav.woocommerce-pagination ul li a:hover,
nav.woocommerce-pagination ul li span.current {
  background: #ebe9eb;
  color: #8a7e88;
}

#respond input#submit,
a.button,
button.button,
input.button {
  font-size: 100%;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  overflow: visible;
  padding: .618em 1em;
  font-weight: 700;
  border-radius: 3px;
  left: auto;
  color: #515151;
  background-color: #ebe9eb;
  border: 0;
  display: inline-block;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}

#respond input#submit.loading,
a.button.loading,
button.button.loading,
input.button.loading {
  opacity: .25;
  padding-right: 2.618em;
}

#respond input#submit.loading::after,
a.button.loading::after,
button.button.loading::after,
input.button.loading::after {
  font-family: WooCommerce;
  content: "\e01c";
  vertical-align: top;
  font-weight: 400;
  position: absolute;
  top: .618em;
  right: 1em;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#respond input#submit.added::after,
a.button.added::after,
button.button.added::after,
input.button.added::after {
  font-family: WooCommerce;
  content: "\e017";
  margin-left: .53em;
  vertical-align: bottom;
}

#respond input#submit:hover,
a.button:hover,
button.button:hover,
input.button:hover {
  background-color: #dfdcde;
  text-decoration: none;
  background-image: none;
  color: #515151;
}

#respond input#submit.alt,
a.button.alt,
button.button.alt,
input.button.alt {
  background-color: #a46497;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

#respond input#submit.alt:hover,
a.button.alt:hover,
button.button.alt:hover,
input.button.alt:hover {
  background-color: #96588a;
  color: #fff;
}

#respond input#submit.alt.disabled,
#respond input#submit.alt.disabled:hover,
#respond input#submit.alt:disabled,
#respond input#submit.alt:disabled:hover,
#respond input#submit.alt:disabled[disabled],
#respond input#submit.alt:disabled[disabled]:hover,
a.button.alt.disabled,
a.button.alt.disabled:hover,
a.button.alt:disabled,
a.button.alt:disabled:hover,
a.button.alt:disabled[disabled],
a.button.alt:disabled[disabled]:hover,
button.button.alt.disabled,
button.button.alt.disabled:hover,
button.button.alt:disabled,
button.button.alt:disabled:hover,
button.button.alt:disabled[disabled],
button.button.alt:disabled[disabled]:hover,
input.button.alt.disabled,
input.button.alt.disabled:hover,
input.button.alt:disabled,
input.button.alt:disabled:hover,
input.button.alt:disabled[disabled],
input.button.alt:disabled[disabled]:hover {
  background-color: #a46497;
  color: #fff;
}

#respond input#submit.disabled,
#respond input#submit:disabled,
#respond input#submit:disabled[disabled],
a.button.disabled,
a.button:disabled,
a.button:disabled[disabled],
button.button.disabled,
button.button:disabled,
button.button:disabled[disabled],
input.button.disabled,
input.button:disabled,
input.button:disabled[disabled] {
  color: inherit;
  cursor: not-allowed;
  opacity: .5;
  padding: .618em 1em;
}

#respond input#submit.disabled:hover,
#respond input#submit:disabled:hover,
#respond input#submit:disabled[disabled]:hover,
a.button.disabled:hover,
a.button:disabled:hover,
a.button:disabled[disabled]:hover,
button.button.disabled:hover,
button.button:disabled:hover,
button.button:disabled[disabled]:hover,
input.button.disabled:hover,
input.button:disabled:hover,
input.button:disabled[disabled]:hover {
  color: inherit;
  background-color: #ebe9eb;
}

.cart .button,
.cart input.button {
  float: none;
}

a.added_to_cart {
  padding-top: .5em;
  display: inline-block;
}

#reviews h2 small {
  float: right;
  color: #777;
  font-size: 15px;
  margin: 10px 0 0;
}

#reviews h2 small a {
  text-decoration: none;
  color: #777;
}

#reviews h3 {
  margin: 0;
}

#reviews #respond {
  margin: 0;
  border: 0;
  padding: 0;
}

#reviews #comment {
  height: 75px;
}

#reviews #comments .add_review::after,
#reviews #comments .add_review::before {
  content: ' ';
  display: table;
}

#reviews #comments .add_review::after {
  clear: both;
}

#reviews #comments h2 {
  clear: none;
}

#reviews #comments ol.commentlist {
  margin: 0;
  width: 100%;
  background: 0 0;
  list-style: none;
}

#reviews #comments ol.commentlist::after,
#reviews #comments ol.commentlist::before {
  content: ' ';
  display: table;
}

#reviews #comments ol.commentlist::after {
  clear: both;
}

#reviews #comments ol.commentlist li {
  padding: 0;
  margin: 0 0 20px;
  border: 0;
  position: relative;
  background: 0;
  border: 0;
}

#reviews #comments ol.commentlist li .meta {
  color: #777;
  font-size: .75em;
}

#reviews #comments ol.commentlist li img.avatar {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px;
  width: 32px;
  height: auto;
  background: #ebe9eb;
  border: 1px solid #e4e1e3;
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#reviews #comments ol.commentlist li .comment-text {
  margin: 0 0 0 50px;
  border: 1px solid #e4e1e3;
  border-radius: 4px;
  padding: 1em 1em 0;
}

#reviews #comments ol.commentlist li .comment-text::after,
#reviews #comments ol.commentlist li .comment-text::before {
  content: ' ';
  display: table;
}

#reviews #comments ol.commentlist li .comment-text::after {
  clear: both;
}

#reviews #comments ol.commentlist li .comment-text p {
  margin: 0 0 1em;
}

#reviews #comments ol.commentlist li .comment-text p.meta {
  font-size: .83em;
}

#reviews #comments ol.commentlist ul.children {
  list-style: none outside;
  margin: 20px 0 0 50px;
}

#reviews #comments ol.commentlist ul.children .star-rating {
  display: none;
}

#reviews #comments ol.commentlist #respond {
  border: 1px solid #e4e1e3;
  border-radius: 4px;
  padding: 1em 1em 0;
  margin: 20px 0 0 50px;
}

#reviews #comments .commentlist > li::before {
  content: "";
}

.star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: star;
}

.star-rating::before {
  content: "\73\73\73\73\73";
  color: #d3ced2;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
}

.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}

.star-rating span::before {
  content: "\53\53\53\53\53";
  top: 0;
  position: absolute;
  left: 0;
}

.woocommerce-product-rating {
  line-height: 2;
  display: block;
}

.woocommerce-product-rating::after,
.woocommerce-product-rating::before {
  content: ' ';
  display: table;
}

.woocommerce-product-rating::after {
  clear: both;
}

.woocommerce-product-rating .star-rating {
  margin: .5em 4px 0 0;
  float: left;
}

.products .star-rating {
  display: block;
  margin: 0 0 .5em;
  float: none;
}

.hreview-aggregate .star-rating {
  margin: 10px 0 0;
}

#review_form #respond {
  position: static;
  margin: 0;
  width: auto;
  padding: 0;
  background: transparent none;
  border: 0;
}

#review_form #respond::after,
#review_form #respond::before {
  content: ' ';
  display: table;
}

#review_form #respond::after {
  clear: both;
}

#review_form #respond p {
  margin: 0 0 10px;
}

#review_form #respond .form-submit input {
  left: auto;
}

#review_form #respond textarea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
}

p.stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  line-height: 1;
  font-family: WooCommerce;
  content: "\e021";
  text-indent: 0;
}

p.stars a:hover ~ a::before {
  content: "\e021";
}

p.stars:hover a::before {
  content: "\e020";
}

p.stars.selected a.active::before {
  content: "\e020";
}

p.stars.selected a.active ~ a::before {
  content: "\e021";
}

p.stars.selected a:not(.active)::before {
  content: "\e020";
}

table.shop_attributes {
  border: 0;
  border-top: 1px dotted rgba(0, 0, 0, 0.1);
  margin-bottom: 1.618em;
  width: 100%;
}

table.shop_attributes th {
  width: 150px;
  font-weight: 700;
  padding: 8px;
  border-top: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  margin: 0;
  line-height: 1.5;
}

table.shop_attributes td {
  font-style: italic;
  padding: 0;
  border-top: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  margin: 0;
  line-height: 1.5;
}

table.shop_attributes td p {
  margin: 0;
  padding: 8px 0;
}

table.shop_attributes tr:nth-child(even) td,
table.shop_attributes tr:nth-child(even) th {
  background: rgba(0, 0, 0, 0.025);
}

table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px;
}

table.shop_table th {
  font-weight: 700;
  padding: 9px 12px;
  line-height: 1.5em;
}

table.shop_table td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 9px 12px;
  vertical-align: middle;
  line-height: 1.5em;
}

table.shop_table td small {
  font-weight: 400;
}

table.shop_table tbody:first-child tr:first-child td,
table.shop_table tbody:first-child tr:first-child th {
  border-top: 0;
}

table.shop_table tbody th,
table.shop_table tfoot td,
table.shop_table tfoot th {
  font-weight: 700;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

table.my_account_orders {
  font-size: .85em;
}

table.my_account_orders td,
table.my_account_orders th {
  padding: 4px 8px;
  vertical-align: middle;
}

table.my_account_orders .button {
  white-space: nowrap;
}

table.my_account_orders .order-actions {
  text-align: right;
}

table.my_account_orders .order-actions .button {
  margin: .125em 0 .125em .25em;
}

table.woocommerce-MyAccount-downloads td,
table.woocommerce-MyAccount-downloads th {
  vertical-align: top;
  text-align: center;
}

table.woocommerce-MyAccount-downloads td:first-child,
table.woocommerce-MyAccount-downloads th:first-child {
  text-align: left;
}

table.woocommerce-MyAccount-downloads td:last-child,
table.woocommerce-MyAccount-downloads th:last-child {
  text-align: left;
}

table.woocommerce-MyAccount-downloads td .woocommerce-MyAccount-downloads-file::before,
table.woocommerce-MyAccount-downloads th .woocommerce-MyAccount-downloads-file::before {
  content: "\2193";
  display: inline-block;
}

td.product-name .wc-item-meta,
td.product-name dl.variation {
  list-style: none outside;
}

td.product-name .wc-item-meta .wc-item-meta-label,
td.product-name .wc-item-meta dt,
td.product-name dl.variation .wc-item-meta-label,
td.product-name dl.variation dt {
  float: left;
  clear: both;
  margin-right: .25em;
  display: inline-block;
  list-style: none outside;
}

td.product-name .wc-item-meta dd,
td.product-name dl.variation dd {
  margin: 0;
}

td.product-name .wc-item-meta p,
td.product-name .wc-item-meta:last-child,
td.product-name dl.variation p,
td.product-name dl.variation:last-child {
  margin-bottom: 0;
}

td.product-name p.backorder_notification {
  font-size: .83em;
}

td.product-quantity {
  min-width: 80px;
}

ul.cart_list,
ul.product_list_widget {
  list-style: none outside;
  padding: 0;
  margin: 0;
}

ul.cart_list li,
ul.product_list_widget li {
  padding: 4px 0;
  margin: 0;
  list-style: none;
}

ul.cart_list li::after,
ul.cart_list li::before,
ul.product_list_widget li::after,
ul.product_list_widget li::before {
  content: ' ';
  display: table;
}

ul.cart_list li::after,
ul.product_list_widget li::after {
  clear: both;
}

ul.cart_list li a,
ul.product_list_widget li a {
  display: block;
  font-weight: 700;
}

ul.cart_list li img,
ul.product_list_widget li img {
  float: right;
  margin-left: 4px;
  width: 32px;
  height: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}

ul.cart_list li dl,
ul.product_list_widget li dl {
  margin: 0;
  padding-left: 1em;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}

ul.cart_list li dl::after,
ul.cart_list li dl::before,
ul.product_list_widget li dl::after,
ul.product_list_widget li dl::before {
  content: ' ';
  display: table;
}

ul.cart_list li dl::after,
ul.product_list_widget li dl::after {
  clear: both;
}

ul.cart_list li dl dd,
ul.cart_list li dl dt,
ul.product_list_widget li dl dd,
ul.product_list_widget li dl dt {
  display: inline-block;
  float: left;
  margin-bottom: 1em;
}

ul.cart_list li dl dt,
ul.product_list_widget li dl dt {
  font-weight: 700;
  padding: 0 0 .25em;
  margin: 0 4px 0 0;
  clear: left;
}

ul.cart_list li dl dd,
ul.product_list_widget li dl dd {
  padding: 0 0 .25em;
}

ul.cart_list li dl dd p:last-child,
ul.product_list_widget li dl dd p:last-child {
  margin-bottom: 0;
}

ul.cart_list li .star-rating,
ul.product_list_widget li .star-rating {
  float: none;
}

.widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
  border-top: 3px double #ebe9eb;
  padding: 4px 0 0;
}

.widget_shopping_cart .total strong,
.woocommerce.widget_shopping_cart .total strong {
  min-width: 40px;
  display: inline-block;
}

.widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
  padding-left: 2em;
  position: relative;
  padding-top: 0;
}

.widget_shopping_cart .cart_list li a.remove,
.woocommerce.widget_shopping_cart .cart_list li a.remove {
  position: absolute;
  top: 0;
  left: 0;
}

.widget_shopping_cart .buttons::after,
.widget_shopping_cart .buttons::before,
.woocommerce.widget_shopping_cart .buttons::after,
.woocommerce.widget_shopping_cart .buttons::before {
  content: ' ';
  display: table;
}

.widget_shopping_cart .buttons::after,
.woocommerce.widget_shopping_cart .buttons::after {
  clear: both;
}

.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a {
  margin-right: 5px;
  margin-bottom: 5px;
}

form .form-row {
  padding: 3px;
  margin: 0 0 6px;
}

form .form-row [placeholder]:focus::-webkit-input-placeholder {
  -webkit-transition: opacity .5s .5s ease;
  transition: opacity .5s .5s ease;
  opacity: 0;
}

form .form-row label {
  line-height: 2;
}

form .form-row label.hidden {
  visibility: hidden;
}

form .form-row label.inline {
  display: inline;
}

form .form-row .woocommerce-input-wrapper .description {
  background: #1e85be;
  color: #fff;
  border-radius: 3px;
  padding: 1em;
  margin: .5em 0 0;
  clear: both;
  display: none;
  position: relative;
}

form .form-row .woocommerce-input-wrapper .description a {
  color: #fff;
  text-decoration: underline;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

form .form-row .woocommerce-input-wrapper .description::before {
  left: 50%;
  top: 0;
  margin-top: -4px;
  -webkit-transform: translateX(-50%) rotate(180deg);
  transform: translateX(-50%) rotate(180deg);
  content: "";
  position: absolute;
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: #1e85be transparent transparent transparent;
  z-index: 100;
  display: block;
}

form .form-row select {
  cursor: pointer;
  margin: 0;
}

form .form-row .required {
  color: red;
  font-weight: 700;
  border: 0 !important;
  text-decoration: none;
  visibility: hidden;
}

form .form-row .optional {
  visibility: visible;
}

form .form-row .input-checkbox {
  display: inline;
  margin: -2px 8px 0 0;
  text-align: center;
  vertical-align: middle;
}

form .form-row input.input-text,
form .form-row textarea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  outline: 0;
  line-height: normal;
}

form .form-row textarea {
  height: 4em;
  line-height: 1.5;
  display: block;
  -webkit-box-shadow: none;
  box-shadow: none;
}

form .form-row .select2-container {
  width: 100%;
  line-height: 2em;
}

form .form-row.woocommerce-invalid label {
  color: #a00;
}

form .form-row.woocommerce-invalid .select2-container,
form .form-row.woocommerce-invalid input.input-text,
form .form-row.woocommerce-invalid select {
  border-color: #a00;
}

form .form-row.woocommerce-validated .select2-container,
form .form-row.woocommerce-validated input.input-text,
form .form-row.woocommerce-validated select {
  border-color: #6dc22e;
}

form .form-row::-webkit-input-placeholder {
  line-height: normal;
}

form .form-row:-moz-placeholder {
  line-height: normal;
}

form .form-row:-ms-input-placeholder {
  line-height: normal;
}

form.checkout_coupon,
form.login,
form.register {
  border: 1px solid #d3ced2;
  padding: 20px;
  margin: 2em 0;
  text-align: left;
  border-radius: 5px;
}

ul#shipping_method {
  list-style: none outside;
  margin: 0;
  padding: 0;
}

ul#shipping_method li {
  margin: 0 0 .5em;
  line-height: 1.5em;
  list-style: none outside;
}

ul#shipping_method li input {
  margin: 3px .4375em 0 0;
  vertical-align: top;
}

ul#shipping_method li label {
  display: inline;
}

ul#shipping_method .amount {
  font-weight: 700;
}

p.woocommerce-shipping-contents {
  margin: 0;
}

ul.order_details {
  margin: 0 0 3em;
  list-style: none;
}

ul.order_details::after,
ul.order_details::before {
  content: ' ';
  display: table;
}

ul.order_details::after {
  clear: both;
}

ul.order_details li {
  float: left;
  margin-right: 2em;
  text-transform: uppercase;
  font-size: .715em;
  line-height: 1;
  border-right: 1px dashed #d3ced2;
  padding-right: 2em;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

ul.order_details li strong {
  display: block;
  font-size: 1.4em;
  text-transform: none;
  line-height: 1.5;
}

ul.order_details li:last-of-type {
  border: none;
}

.wc-bacs-bank-details-account-name {
  font-weight: 700;
}

.woocommerce-customer-details,
.woocommerce-order-details,
.woocommerce-order-downloads {
  margin-bottom: 2em;
}

.woocommerce-customer-details:last-child,
.woocommerce-order-details:last-child,
.woocommerce-order-downloads:last-child {
  margin-bottom: 0;
}

.woocommerce-customer-details address {
  font-style: normal;
  margin-bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-width: 2px;
  border-right-width: 2px;
  text-align: left;
  width: 100%;
  border-radius: 5px;
  padding: 6px 12px;
}

.woocommerce-customer-details .woocommerce-customer-details--email,
.woocommerce-customer-details .woocommerce-customer-details--phone {
  margin-bottom: 0;
  padding-left: 1.5em;
}

.woocommerce-customer-details .woocommerce-customer-details--phone::before {
  font-family: WooCommerce;
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-right: .618em;
  content: "";
  text-decoration: none;
  margin-left: -1.5em;
  line-height: 1.75;
  position: absolute;
}

.woocommerce-customer-details .woocommerce-customer-details--email::before {
  font-family: WooCommerce;
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-right: .618em;
  content: "";
  text-decoration: none;
  margin-left: -1.5em;
  line-height: 1.75;
  position: absolute;
}

.woocommerce-widget-layered-nav-list {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none outside;
}

.woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item {
  padding: 0 0 1px;
  list-style: none;
}

.woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
.woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::before {
  content: ' ';
  display: table;
}

.woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after {
  clear: both;
}

.woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
.woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span {
  padding: 1px 0;
}

.woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before {
  font-family: WooCommerce;
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-right: .618em;
  content: "";
  text-decoration: none;
  color: #a00;
}

.woocommerce-widget-layered-nav-dropdown__submit {
  margin-top: 1em;
}

.widget_layered_nav_filters ul {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none outside;
  overflow: hidden;
  zoom: 1;
}

.widget_layered_nav_filters ul li {
  float: left;
  padding: 0 1em 1px 1px;
  list-style: none;
}

.widget_layered_nav_filters ul li a {
  text-decoration: none;
}

.widget_layered_nav_filters ul li a::before {
  font-family: WooCommerce;
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-right: .618em;
  content: "";
  text-decoration: none;
  color: #a00;
  vertical-align: inherit;
  margin-right: .5em;
}

.widget_price_filter .price_slider {
  margin-bottom: 1em;
}

.widget_price_filter .price_slider_amount {
  text-align: right;
  line-height: 2.4;
  font-size: .8751em;
}

.widget_price_filter .price_slider_amount .button {
  font-size: 1.15em;
  float: left;
}

.widget_price_filter .ui-slider {
  position: relative;
  text-align: left;
  margin-left: .5em;
  margin-right: .5em;
}

.widget_price_filter .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1em;
  height: 1em;
  background-color: #a46497;
  border-radius: 1em;
  cursor: ew-resize;
  outline: 0;
  top: -.3em;
  margin-left: -.5em;
}

.widget_price_filter .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  border-radius: 1em;
  background-color: #a46497;
}

.widget_price_filter .price_slider_wrapper .ui-widget-content {
  border-radius: 1em;
  background-color: #462940;
  border: 0;
}

.widget_price_filter .ui-slider-horizontal {
  height: .5em;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
  left: -1px;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
  right: -1px;
}

.widget_rating_filter ul {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none outside;
}

.widget_rating_filter ul li {
  padding: 0 0 1px;
  list-style: none;
}

.widget_rating_filter ul li::after,
.widget_rating_filter ul li::before {
  content: ' ';
  display: table;
}

.widget_rating_filter ul li::after {
  clear: both;
}

.widget_rating_filter ul li a {
  padding: 1px 0;
  text-decoration: none;
}

.widget_rating_filter ul li .star-rating {
  float: none;
  display: inline-block;
}

.widget_rating_filter ul li.chosen a::before {
  font-family: WooCommerce;
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-right: .618em;
  content: "";
  text-decoration: none;
  color: #a00;
}

.woocommerce-form-login .woocommerce-form-login__submit {
  float: left;
  margin-right: 1em;
}

.woocommerce-form-login .woocommerce-form-login__rememberme {
  display: inline-block;
}

.woocommerce-no-js form.woocommerce-form-coupon,
.woocommerce-no-js form.woocommerce-form-login {
  display: block !important;
}

.woocommerce-no-js .showcoupon,
.woocommerce-no-js .woocommerce-form-coupon-toggle,
.woocommerce-no-js .woocommerce-form-login-toggle {
  display: none !important;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  padding: 1em 2em 1em 3.5em;
  margin: 0 0 2em;
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  border-top: 3px solid #a46497;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
}

.woocommerce-error::after,
.woocommerce-error::before,
.woocommerce-info::after,
.woocommerce-info::before,
.woocommerce-message::after,
.woocommerce-message::before {
  content: ' ';
  display: table;
}

.woocommerce-error::after,
.woocommerce-info::after,
.woocommerce-message::after {
  clear: both;
}

.woocommerce-error::before,
.woocommerce-info::before,
.woocommerce-message::before {
  font-family: WooCommerce;
  content: "\e028";
  display: inline-block;
  position: absolute;
  top: 1em;
  left: 1.5em;
}

.woocommerce-error .button,
.woocommerce-info .button,
.woocommerce-message .button {
  float: right;
}

.woocommerce-error li,
.woocommerce-info li,
.woocommerce-message li {
  list-style: none outside !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.rtl.price_label,
.rtl.price_label span {
  direction: ltr;
  unicode-bidi: embed;
}

.woocommerce-message {
  border-top-color: #8fae1b;
}

.woocommerce-message::before {
  content: "\e015";
  color: #8fae1b;
}

.woocommerce-info {
  border-top-color: #1e85be;
}

.woocommerce-info::before {
  color: #1e85be;
}

.woocommerce-error {
  border-top-color: #b81c23;
}

.woocommerce-error::before {
  content: "\e016";
  color: #b81c23;
}

.woocommerce-account .woocommerce::after,
.woocommerce-account .woocommerce::before {
  content: ' ';
  display: table;
}

.woocommerce-account .woocommerce::after {
  clear: both;
}

.woocommerce-account .addresses .title::after,
.woocommerce-account .addresses .title::before {
  content: ' ';
  display: table;
}

.woocommerce-account .addresses .title::after {
  clear: both;
}

.woocommerce-account .addresses .title h3 {
  float: left;
}

.woocommerce-account .addresses .title .edit {
  float: right;
}

.woocommerce-account ol.commentlist.notes li.note p.meta {
  font-weight: 700;
  margin-bottom: 0;
}

.woocommerce-account ol.commentlist.notes li.note .description p:last-child {
  margin-bottom: 0;
}

.woocommerce-account ul.digital-downloads {
  margin-left: 0;
  padding-left: 0;
}

.woocommerce-account ul.digital-downloads li {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.woocommerce-account ul.digital-downloads li::before {
  font-family: WooCommerce;
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-right: .618em;
  content: "";
  text-decoration: none;
}

.woocommerce-account ul.digital-downloads li .count {
  float: right;
}

#add_payment_method table.cart .product-thumbnail,
.woocommerce-cart table.cart .product-thumbnail,
.woocommerce-checkout table.cart .product-thumbnail {
  min-width: 32px;
}

#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
  width: 32px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#add_payment_method table.cart td,
#add_payment_method table.cart th,
.woocommerce-cart table.cart td,
.woocommerce-cart table.cart th,
.woocommerce-checkout table.cart td,
.woocommerce-checkout table.cart th {
  vertical-align: middle;
}

#add_payment_method table.cart td.actions .coupon .input-text,
.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce-checkout table.cart td.actions .coupon .input-text {
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #d3ced2;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: 0;
}

#add_payment_method table.cart input,
.woocommerce-cart table.cart input,
.woocommerce-checkout table.cart input {
  margin: 0;
  vertical-align: middle;
}

#add_payment_method .wc-proceed-to-checkout,
.woocommerce-cart .wc-proceed-to-checkout,
.woocommerce-checkout .wc-proceed-to-checkout {
  padding: 1em 0;
}

#add_payment_method .wc-proceed-to-checkout::after,
#add_payment_method .wc-proceed-to-checkout::before,
.woocommerce-cart .wc-proceed-to-checkout::after,
.woocommerce-cart .wc-proceed-to-checkout::before,
.woocommerce-checkout .wc-proceed-to-checkout::after,
.woocommerce-checkout .wc-proceed-to-checkout::before {
  content: ' ';
  display: table;
}

#add_payment_method .wc-proceed-to-checkout::after,
.woocommerce-cart .wc-proceed-to-checkout::after,
.woocommerce-checkout .wc-proceed-to-checkout::after {
  clear: both;
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
  display: block;
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.25em;
  padding: 1em;
}

#add_payment_method .cart-collaterals .shipping-calculator-button,
.woocommerce-cart .cart-collaterals .shipping-calculator-button,
.woocommerce-checkout .cart-collaterals .shipping-calculator-button {
  float: none;
  margin-top: .5em;
  display: inline-block;
}

#add_payment_method .cart-collaterals .shipping-calculator-button::after,
.woocommerce-cart .cart-collaterals .shipping-calculator-button::after,
.woocommerce-checkout .cart-collaterals .shipping-calculator-button::after {
  font-family: WooCommerce;
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-left: .618em;
  content: "";
  text-decoration: none;
}

#add_payment_method .cart-collaterals .shipping-calculator-form,
.woocommerce-cart .cart-collaterals .shipping-calculator-form,
.woocommerce-checkout .cart-collaterals .shipping-calculator-form {
  margin: 1em 0 0 0;
}

#add_payment_method .cart-collaterals .cart_totals p small,
.woocommerce-cart .cart-collaterals .cart_totals p small,
.woocommerce-checkout .cart-collaterals .cart_totals p small {
  color: #777;
  font-size: .83em;
}

#add_payment_method .cart-collaterals .cart_totals table,
.woocommerce-cart .cart-collaterals .cart_totals table,
.woocommerce-checkout .cart-collaterals .cart_totals table {
  border-collapse: separate;
  margin: 0 0 6px;
  padding: 0;
}

#add_payment_method .cart-collaterals .cart_totals table tr:first-child td,
#add_payment_method .cart-collaterals .cart_totals table tr:first-child th,
.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td,
.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
.woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child td,
.woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child th {
  border-top: 0;
}

#add_payment_method .cart-collaterals .cart_totals table th,
.woocommerce-cart .cart-collaterals .cart_totals table th,
.woocommerce-checkout .cart-collaterals .cart_totals table th {
  width: 35%;
}

#add_payment_method .cart-collaterals .cart_totals table td,
#add_payment_method .cart-collaterals .cart_totals table th,
.woocommerce-cart .cart-collaterals .cart_totals table td,
.woocommerce-cart .cart-collaterals .cart_totals table th,
.woocommerce-checkout .cart-collaterals .cart_totals table td,
.woocommerce-checkout .cart-collaterals .cart_totals table th {
  vertical-align: top;
  border-left: 0;
  border-right: 0;
  line-height: 1.5em;
}

#add_payment_method .cart-collaterals .cart_totals table small,
.woocommerce-cart .cart-collaterals .cart_totals table small,
.woocommerce-checkout .cart-collaterals .cart_totals table small {
  color: #777;
}

#add_payment_method .cart-collaterals .cart_totals table select,
.woocommerce-cart .cart-collaterals .cart_totals table select,
.woocommerce-checkout .cart-collaterals .cart_totals table select {
  width: 100%;
}

#add_payment_method .cart-collaterals .cart_totals .discount td,
.woocommerce-cart .cart-collaterals .cart_totals .discount td,
.woocommerce-checkout .cart-collaterals .cart_totals .discount td {
  color: #77a464;
}

#add_payment_method .cart-collaterals .cart_totals tr td,
#add_payment_method .cart-collaterals .cart_totals tr th,
.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th,
.woocommerce-checkout .cart-collaterals .cart_totals tr td,
.woocommerce-checkout .cart-collaterals .cart_totals tr th {
  border-top: 1px solid #ebe9eb;
}

#add_payment_method .cart-collaterals .cart_totals .woocommerce-shipping-destination,
.woocommerce-cart .cart-collaterals .cart_totals .woocommerce-shipping-destination,
.woocommerce-checkout .cart-collaterals .cart_totals .woocommerce-shipping-destination {
  margin-bottom: 0;
}

#add_payment_method .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-cart .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-checkout .cart-collaterals .cross-sells ul.products li.product {
  margin-top: 0;
}

#add_payment_method .checkout .col-2 h3#ship-to-different-address,
.woocommerce-cart .checkout .col-2 h3#ship-to-different-address,
.woocommerce-checkout .checkout .col-2 h3#ship-to-different-address {
  float: left;
  clear: none;
}

#add_payment_method .checkout .col-2 .notes,
.woocommerce-cart .checkout .col-2 .notes,
.woocommerce-checkout .checkout .col-2 .notes {
  clear: left;
}

#add_payment_method .checkout .col-2 .form-row-first,
.woocommerce-cart .checkout .col-2 .form-row-first,
.woocommerce-checkout .checkout .col-2 .form-row-first {
  clear: left;
}

#add_payment_method .checkout .create-account small,
.woocommerce-cart .checkout .create-account small,
.woocommerce-checkout .checkout .create-account small {
  font-size: 11px;
  color: #777;
  font-weight: 400;
}

#add_payment_method .checkout div.shipping-address,
.woocommerce-cart .checkout div.shipping-address,
.woocommerce-checkout .checkout div.shipping-address {
  padding: 0;
  clear: left;
  width: 100%;
}

#add_payment_method .checkout .shipping_address,
.woocommerce-cart .checkout .shipping_address,
.woocommerce-checkout .checkout .shipping_address {
  clear: both;
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment {
  background: #ebe9eb;
  border-radius: 5px;
}

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods {
  text-align: left;
  padding: 1em;
  border-bottom: 1px solid #d3ced2;
  margin: 0;
  list-style: none outside;
}

#add_payment_method #payment ul.payment_methods::after,
#add_payment_method #payment ul.payment_methods::before,
.woocommerce-cart #payment ul.payment_methods::after,
.woocommerce-cart #payment ul.payment_methods::before,
.woocommerce-checkout #payment ul.payment_methods::after,
.woocommerce-checkout #payment ul.payment_methods::before {
  content: ' ';
  display: table;
}

#add_payment_method #payment ul.payment_methods::after,
.woocommerce-cart #payment ul.payment_methods::after,
.woocommerce-checkout #payment ul.payment_methods::after {
  clear: both;
}

#add_payment_method #payment ul.payment_methods li,
.woocommerce-cart #payment ul.payment_methods li,
.woocommerce-checkout #payment ul.payment_methods li {
  line-height: 2;
  text-align: left;
  margin: 0;
  font-weight: 400;
}

#add_payment_method #payment ul.payment_methods li input,
.woocommerce-cart #payment ul.payment_methods li input,
.woocommerce-checkout #payment ul.payment_methods li input {
  margin: 0 1em 0 0;
}

#add_payment_method #payment ul.payment_methods li img,
.woocommerce-cart #payment ul.payment_methods li img,
.woocommerce-checkout #payment ul.payment_methods li img {
  vertical-align: middle;
  margin: -2px 0 0 .5em;
  padding: 0;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#add_payment_method #payment ul.payment_methods li img + img,
.woocommerce-cart #payment ul.payment_methods li img + img,
.woocommerce-checkout #payment ul.payment_methods li img + img {
  margin-left: 2px;
}

#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after,
#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::before,
.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::before,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::before {
  content: ' ';
  display: table;
}

#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
.woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after {
  clear: both;
}

#add_payment_method #payment div.form-row,
.woocommerce-cart #payment div.form-row,
.woocommerce-checkout #payment div.form-row {
  padding: 1em;
}

#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  font-size: .92em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: #dfdcde;
  color: #515151;
}

#add_payment_method #payment div.payment_box input.input-text,
#add_payment_method #payment div.payment_box textarea,
.woocommerce-cart #payment div.payment_box input.input-text,
.woocommerce-cart #payment div.payment_box textarea,
.woocommerce-checkout #payment div.payment_box input.input-text,
.woocommerce-checkout #payment div.payment_box textarea {
  border-color: #c7c1c6;
  border-top-color: #bbb3b9;
}

#add_payment_method #payment div.payment_box::-webkit-input-placeholder,
.woocommerce-cart #payment div.payment_box::-webkit-input-placeholder,
.woocommerce-checkout #payment div.payment_box::-webkit-input-placeholder {
  color: #bbb3b9;
}

#add_payment_method #payment div.payment_box:-moz-placeholder,
.woocommerce-cart #payment div.payment_box:-moz-placeholder,
.woocommerce-checkout #payment div.payment_box:-moz-placeholder {
  color: #bbb3b9;
}

#add_payment_method #payment div.payment_box:-ms-input-placeholder,
.woocommerce-cart #payment div.payment_box:-ms-input-placeholder,
.woocommerce-checkout #payment div.payment_box:-ms-input-placeholder {
  color: #bbb3b9;
}

#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods {
  list-style: none outside;
  margin: 0;
}

#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token {
  margin: 0 0 .5em;
}

#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label {
  cursor: pointer;
}

#add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput {
  vertical-align: middle;
  margin: -3px 1em 0 0;
  position: relative;
}

#add_payment_method #payment div.payment_box .wc-credit-card-form,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form {
  border: 0;
  padding: 0;
  margin: 1em 0 0;
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number {
  font-size: 1.5em;
  padding: 8px;
  background-repeat: no-repeat;
  background-position: right .618em center;
  background-size: 32px 20px;
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.visa,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.visa {
  //background-image: url(../images/icons/credit-cards/visa.svg);
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.mastercard {
  //background-image: url(../images/icons/credit-cards/mastercard.svg);
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.laser,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.laser {
  //background-image: url(../images/icons/credit-cards/laser.svg);
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.dinersclub {
  //background-image: url(../images/icons/credit-cards/diners.svg);
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.maestro,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.maestro {
  //background-image: url(../images/icons/credit-cards/maestro.svg);
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.jcb,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.jcb {
  //background-image: url(../images/icons/credit-cards/jcb.svg);
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.amex,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.amex {
  //background-image: url(../images/icons/credit-cards/amex.svg);
}

#add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
#add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.discover,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.discover {
  //background-image: url(../images/icons/credit-cards/discover.svg);
}

#add_payment_method #payment div.payment_box span.help,
.woocommerce-cart #payment div.payment_box span.help,
.woocommerce-checkout #payment div.payment_box span.help {
  font-size: .857em;
  color: #777;
  font-weight: 400;
}

#add_payment_method #payment div.payment_box .form-row,
.woocommerce-cart #payment div.payment_box .form-row,
.woocommerce-checkout #payment div.payment_box .form-row {
  margin: 0 0 1em;
}

#add_payment_method #payment div.payment_box p:last-child,
.woocommerce-cart #payment div.payment_box p:last-child,
.woocommerce-checkout #payment div.payment_box p:last-child {
  margin-bottom: 0;
}

#add_payment_method #payment div.payment_box::before,
.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before {
  content: "";
  display: block;
  border: 1em solid #dfdcde;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -.75em;
  left: 0;
  margin: -1em 0 0 2em;
}

#add_payment_method #payment .payment_method_paypal .about_paypal,
.woocommerce-cart #payment .payment_method_paypal .about_paypal,
.woocommerce-checkout #payment .payment_method_paypal .about_paypal {
  float: right;
  line-height: 52px;
  font-size: .83em;
}

#add_payment_method #payment .payment_method_paypal img,
.woocommerce-cart #payment .payment_method_paypal img,
.woocommerce-checkout #payment .payment_method_paypal img {
  max-height: 52px;
  vertical-align: middle;
}

.woocommerce-terms-and-conditions {
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
}

.woocommerce-invalid #terms {
  outline: 2px solid red;
  outline-offset: 2px;
}

.woocommerce-password-strength {
  text-align: center;
  font-weight: 600;
  padding: 3px .5em;
  font-size: 1em;
}

.woocommerce-password-strength.strong {
  background-color: #c1e1b9;
  border-color: #83c373;
}

.woocommerce-password-strength.short {
  background-color: #f1adad;
  border-color: #e35b5b;
}

.woocommerce-password-strength.bad {
  background-color: #fbc5a9;
  border-color: #f78b53;
}

.woocommerce-password-strength.good {
  background-color: #ffe399;
  border-color: #ffc733;
}

.woocommerce-password-hint {
  margin: .5em 0 0;
  display: block;
}

#content.twentyeleven .woocommerce-pagination a {
  font-size: 1em;
  line-height: 1;
}

.single-product .twentythirteen #reply-title,
.single-product .twentythirteen #respond #commentform,
.single-product .twentythirteen .entry-summary {
  padding: 0;
}

.single-product .twentythirteen p.stars {
  clear: both;
}

.twentythirteen .woocommerce-breadcrumb {
  padding-top: 40px;
}

.twentyfourteen ul.products li.product {
  margin-top: 0 !important;
}

body:not(.search-results) .twentysixteen .entry-summary {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.twentysixteen .price ins {
  background: inherit;
  color: inherit;
}

.woocommerce-error .button,
.woocommerce-info .button,
.woocommerce-message .button,
.woocommerce-page .woocommerce-error .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-message .button {
  float: right;
}

.col2-set,
.woocommerce-page .col2-set {
  width: 100%;
}

.col2-set::after,
.col2-set::before,
.woocommerce-page .col2-set::after,
.woocommerce-page .col2-set::before {
  content: ' ';
  display: table;
}

.col2-set::after,
.woocommerce-page .col2-set::after {
  clear: both;
}

.col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
  float: left;
  width: 48%;
}

.col2-set .col-2,
.woocommerce-page .col2-set .col-2 {
  float: right;
  width: 48%;
}

img,
.woocommerce-page img {
  height: auto;
  max-width: 100%;
}

#content div.product div.images,
div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
  float: left;
  width: 48%;
}

#content div.product div.thumbnails::after,
#content div.product div.thumbnails::before,
div.product div.thumbnails::after,
div.product div.thumbnails::before,
.woocommerce-page #content div.product div.thumbnails::after,
.woocommerce-page #content div.product div.thumbnails::before,
.woocommerce-page div.product div.thumbnails::after,
.woocommerce-page div.product div.thumbnails::before {
  content: ' ';
  display: table;
}

#content div.product div.thumbnails::after,
div.product div.thumbnails::after,
.woocommerce-page #content div.product div.thumbnails::after,
.woocommerce-page div.product div.thumbnails::after {
  clear: both;
}

#content div.product div.thumbnails a,
div.product div.thumbnails a,
.woocommerce-page #content div.product div.thumbnails a,
.woocommerce-page div.product div.thumbnails a {
  float: left;
  width: 30.75%;
  margin-right: 3.8%;
  margin-bottom: 1em;
}

#content div.product div.thumbnails a.last,
div.product div.thumbnails a.last,
.woocommerce-page #content div.product div.thumbnails a.last,
.woocommerce-page div.product div.thumbnails a.last {
  margin-right: 0;
}

#content div.product div.thumbnails a.first,
div.product div.thumbnails a.first,
.woocommerce-page #content div.product div.thumbnails a.first,
.woocommerce-page div.product div.thumbnails a.first {
  clear: both;
}

#content div.product div.thumbnails.columns-1 a,
div.product div.thumbnails.columns-1 a,
.woocommerce-page #content div.product div.thumbnails.columns-1 a,
.woocommerce-page div.product div.thumbnails.columns-1 a {
  width: 100%;
  margin-right: 0;
  float: none;
}

#content div.product div.thumbnails.columns-2 a,
div.product div.thumbnails.columns-2 a,
.woocommerce-page #content div.product div.thumbnails.columns-2 a,
.woocommerce-page div.product div.thumbnails.columns-2 a {
  width: 48%;
}

#content div.product div.thumbnails.columns-4 a,
div.product div.thumbnails.columns-4 a,
.woocommerce-page #content div.product div.thumbnails.columns-4 a,
.woocommerce-page div.product div.thumbnails.columns-4 a {
  width: 22.05%;
}

#content div.product div.thumbnails.columns-5 a,
div.product div.thumbnails.columns-5 a,
.woocommerce-page #content div.product div.thumbnails.columns-5 a,
.woocommerce-page div.product div.thumbnails.columns-5 a {
  width: 16.9%;
}

#content div.product div.summary,
div.product div.summary,
.woocommerce-page #content div.product div.summary,
.woocommerce-page div.product div.summary {
  float: right;
  width: 48%;
  clear: none;
}

#content div.product .woocommerce-tabs,
div.product .woocommerce-tabs,
.woocommerce-page #content div.product .woocommerce-tabs,
.woocommerce-page div.product .woocommerce-tabs {
  clear: both;
}

#content div.product .woocommerce-tabs ul.tabs::after,
#content div.product .woocommerce-tabs ul.tabs::before,
div.product .woocommerce-tabs ul.tabs::after,
div.product .woocommerce-tabs ul.tabs::before,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::before,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::before {
  content: ' ';
  display: table;
}

#content div.product .woocommerce-tabs ul.tabs::after,
div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs::after,
.woocommerce-page div.product .woocommerce-tabs ul.tabs::after {
  clear: both;
}

#content div.product .woocommerce-tabs ul.tabs li,
div.product .woocommerce-tabs ul.tabs li,
.woocommerce-page #content div.product .woocommerce-tabs ul.tabs li,
.woocommerce-page div.product .woocommerce-tabs ul.tabs li {
  display: inline-block;
}

#content div.product #reviews .comment::after,
#content div.product #reviews .comment::before,
div.product #reviews .comment::after,
div.product #reviews .comment::before,
.woocommerce-page #content div.product #reviews .comment::after,
.woocommerce-page #content div.product #reviews .comment::before,
.woocommerce-page div.product #reviews .comment::after,
.woocommerce-page div.product #reviews .comment::before {
  content: ' ';
  display: table;
}

#content div.product #reviews .comment::after,
div.product #reviews .comment::after,
.woocommerce-page #content div.product #reviews .comment::after,
.woocommerce-page div.product #reviews .comment::after {
  clear: both;
}

#content div.product #reviews .comment img,
div.product #reviews .comment img,
.woocommerce-page #content div.product #reviews .comment img,
.woocommerce-page div.product #reviews .comment img {
  float: right;
  height: auto;
}

ul.products,
.woocommerce-page ul.products {
  clear: both;
}

ul.products::after,
ul.products::before,
.woocommerce-page ul.products::after,
.woocommerce-page ul.products::before {
  content: ' ';
  display: table;
}

ul.products::after,
.woocommerce-page ul.products::after {
  clear: both;
}

ul.products li.product,
.woocommerce-page ul.products li.product {
  float: left;
  margin: 0 3.8% 2.992em 0;
  padding: 0;
  position: relative;
  width: 22.05%;
  margin-left: 0;
}

ul.products li.first,
.woocommerce-page ul.products li.first {
  clear: both;
}

ul.products li.last,
.woocommerce-page ul.products li.last {
  margin-right: 0;
}

ul.products.columns-1 li.product,
.woocommerce-page ul.products.columns-1 li.product {
  width: 100%;
  margin-right: 0;
}

ul.products.columns-2 li.product,
.woocommerce-page ul.products.columns-2 li.product {
  width: 48%;
}

ul.products.columns-3 li.product,
.woocommerce-page ul.products.columns-3 li.product {
  width: 30.75%;
}

ul.products.columns-5 li.product,
.woocommerce-page ul.products.columns-5 li.product {
  width: 16.95%;
}

ul.products.columns-6 li.product,
.woocommerce-page ul.products.columns-6 li.product {
  width: 13.5%;
}

.woocommerce-page.columns-1 ul.products li.product,
.woocommerce.columns-1 ul.products li.product {
  width: 100%;
  margin-right: 0;
}

.woocommerce-page.columns-2 ul.products li.product,
.woocommerce.columns-2 ul.products li.product {
  width: 48%;
}

.woocommerce-page.columns-3 ul.products li.product,
.woocommerce.columns-3 ul.products li.product {
  width: 30.75%;
}

.woocommerce-page.columns-5 ul.products li.product,
.woocommerce.columns-5 ul.products li.product {
  width: 16.95%;
}

.woocommerce-page.columns-6 ul.products li.product,
.woocommerce.columns-6 ul.products li.product {
  width: 13.5%;
}

.woocommerce-result-count,
.woocommerce-page .woocommerce-result-count {
  float: left;
}

.woocommerce-ordering,
.woocommerce-page .woocommerce-ordering {
  float: right;
}

.woocommerce-pagination ul.page-numbers::after,
.woocommerce-pagination ul.page-numbers::before,
.woocommerce-page .woocommerce-pagination ul.page-numbers::after,
.woocommerce-page .woocommerce-pagination ul.page-numbers::before {
  content: ' ';
  display: table;
}

.woocommerce-pagination ul.page-numbers::after,
.woocommerce-page .woocommerce-pagination ul.page-numbers::after {
  clear: both;
}

.woocommerce-pagination ul.page-numbers li,
.woocommerce-page .woocommerce-pagination ul.page-numbers li {
  display: inline-block;
}

#content table.cart img,
table.cart img,
.woocommerce-page #content table.cart img,
.woocommerce-page table.cart img {
  height: auto;
}

#content table.cart td.actions,
table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right;
}

#content table.cart td.actions .input-text,
table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
  width: 80px;
}

#content table.cart td.actions .coupon,
table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left;
}

#content table.cart td.actions .coupon label,
table.cart td.actions .coupon label,
.woocommerce-page #content table.cart td.actions .coupon label,
.woocommerce-page table.cart td.actions .coupon label {
  display: none;
}

.cart-collaterals,
.woocommerce-page .cart-collaterals {
  width: 100%;
}

.cart-collaterals::after,
.cart-collaterals::before,
.woocommerce-page .cart-collaterals::after,
.woocommerce-page .cart-collaterals::before {
  content: ' ';
  display: table;
}

.cart-collaterals::after,
.woocommerce-page .cart-collaterals::after {
  clear: both;
}

.cart-collaterals .related,
.woocommerce-page .cart-collaterals .related {
  width: 30.75%;
  float: left;
}

.cart-collaterals .cross-sells,
.woocommerce-page .cart-collaterals .cross-sells {
  width: 48%;
  float: left;
}

.cart-collaterals .cross-sells ul.products,
.woocommerce-page .cart-collaterals .cross-sells ul.products {
  float: none;
}

.cart-collaterals .cross-sells ul.products li,
.woocommerce-page .cart-collaterals .cross-sells ul.products li {
  width: 48%;
}

.cart-collaterals .shipping_calculator,
.woocommerce-page .cart-collaterals .shipping_calculator {
  width: 48%;
  clear: right;
  float: right;
}

.cart-collaterals .shipping_calculator::after,
.cart-collaterals .shipping_calculator::before,
.woocommerce-page .cart-collaterals .shipping_calculator::after,
.woocommerce-page .cart-collaterals .shipping_calculator::before {
  content: ' ';
  display: table;
}

.cart-collaterals .shipping_calculator::after,
.woocommerce-page .cart-collaterals .shipping_calculator::after {
  clear: both;
}

.cart-collaterals .shipping_calculator .col2-set .col-1,
.cart-collaterals .shipping_calculator .col2-set .col-2,
.woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-1,
.woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-2 {
  width: 47%;
}

.cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
  float: right;
  width: 48%;
}

ul.cart_list li::after,
ul.cart_list li::before,
ul.product_list_widget li::after,
ul.product_list_widget li::before,
.woocommerce-page ul.cart_list li::after,
.woocommerce-page ul.cart_list li::before,
.woocommerce-page ul.product_list_widget li::after,
.woocommerce-page ul.product_list_widget li::before {
  content: ' ';
  display: table;
}

ul.cart_list li::after,
ul.product_list_widget li::after,
.woocommerce-page ul.cart_list li::after,
.woocommerce-page ul.product_list_widget li::after {
  clear: both;
}

ul.cart_list li img,
ul.product_list_widget li img,
.woocommerce-page ul.cart_list li img,
.woocommerce-page ul.product_list_widget li img {
  float: right;
  height: auto;
}

form .form-row::after,
form .form-row::before,
.woocommerce-page form .form-row::after,
.woocommerce-page form .form-row::before {
  content: ' ';
  display: table;
}

form .form-row::after,
.woocommerce-page form .form-row::after {
  clear: both;
}

form .form-row label,
.woocommerce-page form .form-row label {
  display: block;
}

form .form-row label.checkbox,
.woocommerce-page form .form-row label.checkbox {
  display: inline;
}

form .form-row select,
.woocommerce-page form .form-row select {
  width: 100%;
}

form .form-row .input-text,
.woocommerce-page form .form-row .input-text {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

form .form-row-first,
form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
  width: 47%;
  overflow: visible;
}

form .form-row-first,
.woocommerce-page form .form-row-first {
  float: left;
}

form .form-row-last,
.woocommerce-page form .form-row-last {
  float: right;
}

form .form-row-wide,
.woocommerce-page form .form-row-wide {
  clear: both;
}

#payment .form-row select,
.woocommerce-page #payment .form-row select {
  width: auto;
}

#payment .terms,
#payment .wc-terms-and-conditions,
.woocommerce-page #payment .terms,
.woocommerce-page #payment .wc-terms-and-conditions {
  text-align: left;
  padding: 0 1em 0 0;
  float: left;
}

#payment #place_order,
.woocommerce-page #payment #place_order {
  float: right;
}

.woocommerce-billing-fields::after,
.woocommerce-billing-fields::before,
.woocommerce-shipping-fields::after,
.woocommerce-shipping-fields::before,
.woocommerce-page .woocommerce-billing-fields::after,
.woocommerce-page .woocommerce-billing-fields::before,
.woocommerce-page .woocommerce-shipping-fields::after,
.woocommerce-page .woocommerce-shipping-fields::before {
  content: ' ';
  display: table;
}

.woocommerce-billing-fields::after,
.woocommerce-shipping-fields::after,
.woocommerce-page .woocommerce-billing-fields::after,
.woocommerce-page .woocommerce-shipping-fields::after {
  clear: both;
}

.woocommerce-terms-and-conditions,
.woocommerce-page .woocommerce-terms-and-conditions {
  margin-bottom: 1.618em;
  padding: 1.618em;
}

.woocommerce-oembed,
.woocommerce-page .woocommerce-oembed {
  position: relative;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
  float: left;
  width: 30%;
}

.woocommerce-account .woocommerce-MyAccount-content {
  float: right;
  width: 68%;
}

.woocommerce-page.left-sidebar #content.twentyeleven {
  width: 58.4%;
  margin: 0 7.6%;
  float: right;
}

.woocommerce-page.right-sidebar #content.twentyeleven {
  margin: 0 7.6%;
  width: 58.4%;
  float: left;
}

.twentyfourteen .tfwc {
  padding: 12px 10px 0;
  max-width: 474px;
  margin: 0 auto;
}

.twentyfourteen .tfwc .product .entry-summary {
  padding: 0 !important;
  margin: 0 0 1.618em !important;
}

.twentyfourteen .tfwc div.product.hentry.has-post-thumbnail {
  margin-top: 0;
}

@media screen and (min-width: 673px) {
  .twentyfourteen .tfwc {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (min-width: 1040px) {
  .twentyfourteen .tfwc {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (min-width: 1110px) {
  .twentyfourteen .tfwc {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (min-width: 1218px) {
  .twentyfourteen .tfwc {
    margin-right: 54px;
  }
  .full-width .twentyfourteen .tfwc {
    margin-right: auto;
  }
}

.twentyfifteen .t15wc {
  padding-left: 7.6923%;
  padding-right: 7.6923%;
  padding-top: 7.6923%;
  margin-bottom: 7.6923%;
  background: #fff;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
}

.twentyfifteen .t15wc .page-title {
  margin-left: 0;
}

@media screen and (min-width: 38.75em) {
  .twentyfifteen .t15wc {
    margin-right: 7.6923%;
    margin-left: 7.6923%;
    margin-top: 8.3333%;
  }
}

@media screen and (min-width: 59.6875em) {
  .twentyfifteen .t15wc {
    margin-left: 8.3333%;
    margin-right: 8.3333%;
    padding: 10%;
  }
  .single-product .twentyfifteen .entry-summary {
    padding: 0 !important;
  }
}

.twentysixteen .site-main {
  margin-right: 7.6923%;
  margin-left: 7.6923%;
}

.twentysixteen .entry-summary {
  margin-right: 0;
  margin-left: 0;
}

#content .twentysixteen div.product div.images,
#content .twentysixteen div.product div.summary {
  width: 46.42857%;
}

@media screen and (min-width: 44.375em) {
  .twentysixteen .site-main {
    margin-right: 23.0769%;
  }
}

@media screen and (min-width: 56.875em) {
  .twentysixteen .site-main {
    margin-right: 0;
    margin-left: 0;
  }
  .no-sidebar .twentysixteen .site-main {
    margin-right: 15%;
    margin-left: 15%;
  }
  .no-sidebar .twentysixteen .entry-summary {
    margin-right: 0;
    margin-left: 0;
  }
}

.rtl .col2-set .col-1,
.rtl .woocommerce-page .col2-set .col-1 {
  float: right;
}

.rtl .col2-set .col-2,
.rtl .woocommerce-page .col2-set .col-2 {
  float: left;
}

/*!*/
html {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-overflow-x: hidden;
  overflow-x: hidden;
}

body {
  -ms-overflow-x: hidden;
  overflow-x: hidden;
}

/*ul,
li{
	margin: 0;
	padding: 0;
}*/
/* fghf */
.single-content-wrapp table {
  border: 1px dashed #BBB;
  border-collapse: collapse;
}

th {
  border: 1px solid #BBB;
}

td {
  border: 1px solid #BBB;
  padding-left: 10px;
}

.single-content-wrapp {
  line-height: 1.8;
}

.single-content-wrapp {
  font-size: 16px;
  line-height: 1.8;
  color: #191e23;
}

.single-content-wrapp dd,
.single-content-wrapp li {
  margin-bottom: 6px;
}

.single-content-wrapp ol,
.single-content-wrapp ul {
  padding-left: 2.5em;
  margin-left: 0;
}

.single-content-wrapp ol {
  list-style-type: decimal;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border: solid 1px #aaa;
  border-collapse: collapse;
  text-align: left;
}

/* Sections
	 ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
  font-family: poppins;
  color: #404040;
  font-weight: 400;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
	 ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
	 ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 11px;
}

small a:hover {
  color: #e8650d;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
	 ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
  max-width: 100%;
  height: auto;
}

/* Forms
	 ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *		`fieldset` elements in all browsers.
 */
legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
	 ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
	 ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
optgroup,
textarea {
  font-family: poppins;
  color: #404040;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
}

p {
  margin-bottom: 1.5em;
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em 1.5em;
  position: relative;
  padding-left: 30px;
  border-left: 4px solid #000;
  padding-left: 1em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code, kbd, tt, var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark {
  background: #fff9c0;
  text-decoration: none;
}

ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  background: #fff;
  /* Fallback for when there is no custom background color defined. */
}

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

ul, ol {
  margin: 0 0 1.5em 3em;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

dd {
  margin-bottom: 6px;
}

/*img {
	height: 100%;
	 Make sure images are scaled correctly. 
	width: 100%;
	Adhere to container width. 
}
*/
figure {
  margin: 1em 0;
  /* Extra wide images within figure tags don't overflow the content area. */
}

table {
  margin: 0 0 1.5em;
  width: 100%;
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 1px solid;
  border-color: #ccc #ccc #bbb;
  border-radius: 3px;
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
  padding: .6em 1em .4em;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  border-color: #ccc #bbb #aaa;
}

button:active, button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
  border-color: #aaa #bbb #bbb;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  color: #111;
}

select {
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
}

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
  color: royalblue;
}

a:visited {
  color: royalblue;
}

a:hover, a:focus, a:active {
  color: midnightblue;
}

a:focus {
  outline: thin dotted;
}

a:hover, a:active {
  outline: 0;
}

code {
  background: #f3f4f5;
  border-radius: 2px;
  color: #23282d;
  font-family: Menlo,Consolas,monaco,monospace;
  font-size: 16px;
  padding: 2px;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.main-navigation ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.main-navigation ul ul {
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  float: left;
  position: absolute;
  top: 100%;
  left: -999em;
  z-index: 99999;
}

.main-navigation ul ul ul {
  left: -999em;
  top: 0;
}

a {
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.main-navigation ul ul li:hover > ul,
.main-navigation ul ul li.focus > ul {
  left: 100%;
}

/*.main-navigation ul ul a {
	width: 200px;
}*/
blockquote {
  margin: 0;
}

.main-navigation ul li:hover > ul,
.main-navigation ul li.focus > ul {
  left: auto;
}

.main-navigation li {
  position: relative;
}

.main-navigation a {
  display: block;
  text-decoration: none;
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
  display: block;
}

@media screen and (min-width: 800px) {
  .menu-toggle {
    display: none;
  }
}

/*.main-navigation ul {
		display: block;
	}
}*/
.site-main .comment-navigation, .site-main
.posts-navigation, .site-main
.post-navigation {
  margin: 0 0 1.5em;
  overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 16px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 16px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
  margin: 0 0 1.5em;
  /* Make sure select elements fit in widgets. */
}

.widget select {
  max-width: 100%;
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block;
}

.entry-content .page-links {
  display: block;
  text-align: center;
  margin-top: 50px;
  color: #404040;
}

.entry-content .page-links b {
  font-weight: normal;
  margin-right: 8px;
}

.entry-content .page-links span {
  padding: 0 10px;
  color: #FFF;
  font-weight: 600;
  display: inline-block;
  background: #e8650d;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  margin: 0 2px;
  border: solid 1px #e8650d;
}

.entry-content .page-links a {
  color: #404040;
  margin: 0;
}

.sticky .sml-blog-ct-main-wp {
  -webkit-box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.075);
  border: solid 1px #ededed;
  background: #f1f1f1;
}

article span.edit-link {
  margin-top: 15px;
  display: block;
}

form input[type="password"] {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
}

form input[type="submit"] {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 800;
  border-radius: 2px;
  -webkit-transition: background .2s;
  transition: background .2s;
  cursor: pointer;
}

form input[type="submit"]:hover {
  background-color: #767676;
}

h1 {
  font-size: 36px;
  margin: 0.67em 0;
  font-weight: 600;
}

h2 {
  font-size: 32px;
  font-weight: 600;
}

h3 {
  font-size: 28px;
  font-weight: 600;
}

h4 {
  font-size: 24px;
  font-weight: 600;
}

h5 {
  font-size: 20px;
  font-weight: 600;
}

h6 {
  font-size: 18px;
  font-weight: 600;
}

.hentry {
  margin: 0 0 1.5em;
}

.updated:not(.published) {
  display: none;
}

/*.page-content,
.entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}*/
.page-links {
  clear: both;
  margin: 0 0 1.5em;
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

/*..............................................................site headder................................................................*/
/*.....................store-mart-lite-header-icons.............................*/
.store-mart-lite-top-header-wrap {
  background-color: #293341;
  color: #fff;
  border-bottom: 1px solid #eee;
}

.top-header-left-text {
  font-size: 13px;
  font-weight: 400;
  font-family: poppins;
  padding: 8px 0px;
  color: #ffffff;
}

.top-header-call-title {
  padding: 8px 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  font-family: poppins;
  color: #ffffff;
}

.top-header-contact-num {
  padding: 8px 0;
  margin-left: 15px;
  font-size: 12px;
  font-weight: 400;
  font-family: poppins;
  color: #ffffff;
}

.store-mart-lite-header-icons a {
  margin-left: 8px;
  color: #ffffff;
  font-size: 16px;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.header-one .store-mart-lite-header-icons {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 10px;
}

.store-mart-lite-top-header-left {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.store-mart-lite-sc-icons {
  padding-top: 4px;
  padding-left: 8px;
}

.store-mart-lite-sc-icons a:hover {
  color: #e8650d;
}

/*......................store-mart-lite-logos.......................*/
.site-branding img {
  width: 140px;
}

.store-mart-lite-logos {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 10px;
}

.store-mart-lite-logos form {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
}

.search-wrap {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  border: 2px solid #ddd;
  border-radius: 3px;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  position: relative;
}

.user-logout-wrap.sm-icon-header {
  margin-right: 30px;
  position: relative;
}

.sm-wishlist-wrap.sm-icon-header {
  position: relative;
}

.sm_search_wrap select {
  border: none;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: right 50%;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  background-color: transparent;
  //background-image: url(assets/images/down-arrow.png);
}

.sm_search_form input[type="text"] {
  border: none;
  padding-right: 50px;
  width: 350px;
  background: transparent;
  text-transform: capitalize;
}

.sm_search_wrap {
  width: 150px;
  position: relative;
}

.sm_search_form {
  margin-left: 20px;
}

.sm_search_form button#searchsubmit {
  padding: 15px;
  color: #fff;
  background-color: #e8650d;
  border: none;
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: -1px;
  font-size: 18px;
  border-radius: 0px;
}

.sm_search_form input::-webkit-input-placeholder {
  font-size: 13px;
}

.sm_search_form input::-moz-placeholder {
  font-size: 13px;
}

.sm_search_form input:-ms-input-placeholder {
  font-size: 13px;
}

.sm_search_form input::-ms-input-placeholder {
  font-size: 13px;
}

.sm_search_form input::placeholder {
  font-size: 13px;
}

.user-logout-wrap.sm-icon-header a {
  color: #333;
  font-size: 25px;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.user-logout-wrap.sm-icon-header a:hover {
  color: #e8650d;
}

.sm-wishlist-wrap.sm-icon-header a.sm-wishlist-ct-class {
  color: #333;
  font-size: 25px;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sm_search_wrap:after {
  position: absolute;
  top: 0px;
  left: 110%;
  background-color: #818181;
  width: 1px;
  height: 25px;
  content: '';
}

.user-logout-wrap.sm-icon-header span {
  font-size: 16px;
  position: absolute;
  z-index: 100;
  top: 20px;
  right: 0;
  max-width: 150px;
  padding: 20px 20px;
  line-height: 40px;
  background: #FFF;
  color: #404040;
  visibility: hidden;
  opacity: 0;
  font-family: poppins;
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.browse-category i {
  font-size: 22px;
  font-weight: 800;
  padding-left: 15px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
  margin-top: -2px;
}

.user-logout-wrap.sm-icon-header a:hover span {
  opacity: 1;
  visibility: visible;
  color: #333;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}

.user-logout-wrap.sm-icon-header a.sm-wishlist-ct-class:hover {
  color: #e8650d;
  -webkit-transition: 1s;
  transition: 1s;
}

.store-mart-lite-login-wrap i {
  font-weight: 800;
}

.user-logout-wrap.sm-icon-header a span:hover {
  color: #e8650d;
  -webkit-transition: 1s;
  transition: 1s;
}

.sm-wishlist-wrap.sm-icon-header a.sm-wishlist-ct-class:hover {
  color: #e8650d;
  -webkit-transition: 1s;
  transition: 1s;
}

span.wishlist-counter {
  position: absolute;
  top: -5px;
  left: 18px;
  font-size: 12px;
  background-color: #e8650d;
  border-radius: 50%;
  color: #fff;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 2;
}

.wishlist-dropdown.product_list_widget {
  font-size: 16px;
  position: absolute;
  z-index: 99;
  top: 15px;
  right: 0;
  width: 350px;
  padding: 20px;
  padding-bottom: 0;
  color: #333;
  background: #FFF;
  visibility: hidden;
  overflow-y: auto;
  opacity: 0;
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sm-wishlist-wrap.sm-icon-header:hover .wishlist-dropdown.product_list_widget {
  opacity: 1;
  visibility: visible;
  color: #333;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}

.sm-wishlist-wrap.sm-icon-header a li {
  font-size: 16px;
  color: #000;
  font-family: poppins;
}

.sm-wishlist-wrap.sm-icon-header p {
  font-size: 16px;
  color: #7b7b7b;
  font-family: poppins;
  margin-top: 0px;
  text-align: center;
}

.store-mart-lite-login-wrap {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-right: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

ul.cart-widget-products {
  list-style: none;
  margin: 0;
  padding: 0;
}

.store-mart-lite-login-wrap a.product-mini-image {
  color: transparent;
}

.cart-widget-products li.cart-widget-prod {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}

.cart-widget-products .descr-box {
  padding-left: 15px;
  width: calc(100% - 80px);
}

.descr-box a {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  font-family: poppins;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.descr-box a:hover {
  color: #e8650d;
}

h4.product-title {
  margin: 0;
}

.descr-box h4.product-title a:hover {
  color: #e8650d;
}

.store-mart-lite-logos span.woocommerce-Price-amount.amount {
  color: #999;
  font-family: poppins;
}

p.sm-item-notice {
  position: relative;
}

p.sm-item-notice::after {
  position: absolute;
  content: '';
  width: 45px;
  height: 1px;
  background-color: #999;
  top: 100%;
  left: 0;
}

.wishlist-dropdown p.buttons a {
  display: block;
  color: #FFF;
  font-size: 16px;
  font-family: poppins;
  text-decoration: none;
  background-color: #e8650d;
  border-radius: 0px;
  height: 45px;
  line-height: 45px;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  border: 1px solid #e8650d;
}

.wishlist-dropdown p.buttons a:hover {
  background-color: #FFF;
  color: #e8650d;
  border: 1px solid #e8650d;
  /*-webkit-transition: all ease-in-out 0.5s;
	   -moz-transition: all ease-in-out 0.5s;
	    -ms-transition: all ease-in-out 0.5s;
	     -o-transition: all ease-in-out 0.5s;
	        transition: all ease-in-out 0.5s;*/
}

p.buttons span:after {
  position: absolute;
  content: '\f06e';
  font-family: fontawesome;
  right: 110%;
}

p.buttons span {
  position: relative;
}

.menu-primary-menu-container a:hover {
  color: #e8650d;
}

/*................................store-mart-lite-product-cat.........................................*/
.browse-category {
  display: inline-block;
  background-color: #e8650d;
  width: 250px;
  color: #fff;
  font-size: 16px;
  font-family: poppins;
  cursor: pointer;
  font-weight: 500;
  line-height: 50px;
  height: 50px;
}

.store-mart-lite-product-cat {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
}

.browse-category-wrap {
  position: relative;
  width: 250px;
}

.browse-category-wrap ul {
  list-style: none;
}

.menu li {
  margin: 5px 10px;
  position: relative;
  display: inline-block;
}

.main-navigation .menu li.menu-item-has-children {
  margin-right: 15px;
}

.header-one .main-navigation {
  width: calc(100% - 380px);
  width: -moz-calc(100% - 380px);
  width: calc(100% - 400px);
  width: -moz-calc(100% - 400px);
  text-align: center;
}

.header-two .main-navigation {
  width: 100%;
}

.header-one .cart-icon-wrap {
  width: 150px;
}

.main-navigation .menu-primary-menu-container ul > li.menu-item-has-children::after {
  position: absolute;
  bottom: 22px;
  right: 0px;
  color: #000;
  content: "\f107";
  font-family: fontawesome;
}

.main-navigation ul > li a {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #333;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.header-one .main-navigation .menu-primary-menu-container ul > li.menu-item-has-children::after {
  bottom: 2px;
}

.header-two .menu li {
  margin: 0px 0px;
}

.main-navigation ul li span {
  display: block;
  position: relative;
}

.main-navigation ul > .menu-item-has-children:hover > a {
  color: #e8650d;
}

.main-navigation ul > li.menu-item-has-children:hover::after {
  color: #e8650d;
}

.sm-cart-icon-wrap span.icon {
  color: #333;
  font-size: 30px;
  font-weight: 800;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sm-cart-icon-wrap span.lnr.lnr-cart {
  font-weight: 400;
  color: #333;
}

.sm-cart-icon-wrap span.sm-cart-count {
  bottom: 25px;
  position: absolute;
  left: 15px;
  background-color: #e8650d;
  color: #fff;
  border-radius: 50%;
  font-size: 11px;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 2.4;
}

span.sm-cart-icon-wrap {
  position: relative;
  padding-right: 15px;
}

span.sm-cart-wrap {
  font-size: 16px;
  padding-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column;
  font-family: poppins;
  font-weight: 500;
  color: #333;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.header-one .cart-icon-wrap:hover .sm-cart-icon-wrap span.lnr-cart,
.header-one .cart-icon-wrap:hover .sm-cart-wrap {
  color: #e8650d;
}

.cart-icon-wrap:hover .sm-cart-wrap span.sm-cart-text {
  border-color: #e8650d;
}

span.sm-cart-text {
  border-bottom: 1px solid #333;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
}

.widget.widget_shopping_cart {
  margin: 0;
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 11;
  top: 100%;
  max-height: 500px;
  overflow-y: auto;
  right: 0;
  min-width: 350px;
  padding: 20px;
  background: #FFF;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
  -webkit-box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.cart-icon-wrap:hover .widget.widget_shopping_cart {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
li.wishlist:hover ~ li > .widget.widget_shopping_cart{
  opacity: 0;
}

.cart-icon-wrap ul {
  list-style: none;
  margin: 0;
  position: relative;
  padding-left: 0px;
}

a.cart-contents {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu {
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  top: 15px;
  left: 0px;
  min-width: 220px;
  padding: 10px;
  background: #FFF;
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.main-navigation .menu-primary-menu-container ul.sub-menu {
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  top: 15px;
  left: 0px;
  min-width: 220px;
  padding: 10px;
  background: #FFF;
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 10px -2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.main-navigation .menu-primary-menu-container ul li.menu-item-has-children:hover > ul.sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}

.main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu li.menu-item-has-children:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}

.main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu ul.sub-menu {
  left: 110%;
  top: -10px;
}

.main-navigation .menu-primary-menu-container ul li ul.sub-menu li.menu-item-has-children {
  position: relative;
  display: block;
}

/*.main-navigation .menu-primary-menu-container ul li ul.sub-menu li > a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 1px;
    background: $mainColour;
    -webkit-transition: all .4s cubic-bezier(.77,0,.175,1);
    -moz-transition: all .4s cubic-bezier(.77,0,.175,1);
    -ms-transition: all .4s cubic-bezier(.77,0,.175,1);
    -o-transition: all .4s cubic-bezier(.77,0,.175,1);
    transition: all .4s cubic-bezier(.77,0,.175,1);
}*/
.main-navigation .menu-primary-menu-container ul li ul.sub-menu li > a {
  position: relative;
  display: inline-block;
}

.main-navigation .menu-primary-menu-container ul li ul.sub-menu li a:hover:before {
  width: 100%;
}

.browse-category-wrap .categorylist {
  max-height: 0;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0px 25px;
  width: 100%;
  background: #FFF;
  overflow: hidden;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 10;
}

.browse-category-wrap .categorylist.sm-cat-menu-active {
  max-height: 1000px;
  padding: 20px 25px;
  -webkit-box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.2);
}

.browse-category-wrap .categorylist ul li:first-child a {
  padding-top: 0px;
}

.browse-category-wrap .categorylist ul li a {
  color: #666;
  font-weight: 400;
  font-size: 14px;
  padding: 15px 25px;
  display: block;
  border-bottom: solid 1px #eee;
  position: relative;
  text-decoration: none;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.browse-category-wrap .categorylist ul li:last-child a {
  border-bottom: none;
  padding-bottom: 0;
}

.browse-category-wrap .categorylist ul li:last-child a:before {
  height: 0;
}

.browse-category-wrap .categorylist ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  height: 1px;
  width: 0;
  left: 0;
  background: #e8650d;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.browse-category-wrap .categorylist ul li a:hover:before {
  width: 100%;
}

.browse-category-wrap .categorylist ul li a:hover {
  color: #e8650d;
}

.browse-category-wrap ul {
  padding: 0;
  margin: 0;
}

input[type="text"]:focus,
select {
  outline: none;
}

.main-navigation .menu-primary-menu-container .menu.nav-menu a {
  position: relative;
  padding: 3px 15px;
}

.main-navigation .menu-primary-menu-container .menu.nav-menu span:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 1px;
  background: #e8650d;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.main-navigation .menu-primary-menu-container .menu.nav-menu a:hover span:before {
  width: 100%;
}

/*............................................plx_slider_promo_section.......................................*/
.store-mart-lite-slider-promo-banner {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.plx_slider_promo_section .store-mart-lite-banner-wrapper {
  width: 50%;
  position: relative;
}

.plx_slider_promo_section .store-mart-lite-container {
  width: 50%;
  overflow: hidden;
}

.banner-slider-info {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  text-align: center;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
}

h2.caption-title {
  font-size: 18px;
  font-weight: 500;
  font-family: poppins;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.caption-content {
  font-size: 44px;
  font-weight: 600;
  font-family: poppins;
  margin-bottom: 25px;
  line-height: 1;
  text-transform: capitalize;
}

.sml-slider-btn {
  display: inline-block;
}

a.slider-button {
  color: #fff;
  text-decoration: none;
  background-color: #e8650d;
  font-family: poppins;
  padding: 15px 30px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

a.slider-button:hover {
  color: #e8650d;
  background-color: #fff;
}

.store-mart-lite-wrapper {
  position: relative;
  height: 50%;
}

.promo-one-image {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.promo-one-image a {
  display: block;
  display: block;
  height: 100%;
  width: 100%;
}

.promo-one-image img {
  vertical-align: top;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.promo-one-image img {
  -webkit-appearance: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.promo-one-image:hover img {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}

/*.store-mart-lite-cat-prod-image:after {
    position: absolute;
    z-index: 9;
    content: '';
    background-color:rgba(255, 255, 255, 0.2);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}*/
.store-mart-lite-promo-content-wrap {
  position: absolute;
  top: 50%;
  right: 0;
  color: #fff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-transform: uppercase;
  z-index: 10;
  margin-right: 100px;
  text-align: right;
}

.sml-promo-price-btn {
  display: inline-block;
  margin-top: 0px;
}

a.promo-price-title {
  font-size: 22px;
  font-family: poppins;
  position: relative;
  color: #fff;
  display: block;
  line-height: 1;
  text-decoration: none;
  text-transform: capitalize;
}

.promo-subtitle {
  font-size: 18px;
  font-weight: 500;
  font-family: poppins;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.promo-title {
  font-size: 44px;
  font-weight: 600;
  font-family: poppins;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.promo-price-title:after {
  position: absolute;
  top: 12px;
  right: 110%;
  background-color: #fff;
  content: '';
  height: 1px;
  width: 100%;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.store-mart-lite-button.btn1 a {
  text-align: center;
  background-color: #e8650d;
  color: #fff;
  font-size: 16px;
  font-family: poppins;
  text-decoration: none;
  padding: 15px 30px;
  display: inline-block;
}

.store-mart-lite-button.btn1 a:hover {
  color: #e8650d;
  background-color: #fff;
}

/*.promo-one-image a::after {
    position: absolute;
    z-index: 9;
    content: '';
    background-color: rgba(0,0,0,.2);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    -webkit-transition: all ease-in-out 0.5s;
        -moz-transition: all ease-in-out 0.5s;
         -ms-transition: all ease-in-out 0.5s;
          -o-transition: all ease-in-out 0.5s;
             transition: all ease-in-out 0.5s; 
}*/
.banner-slider {
  position: relative;
  height: 100%;
}

.plx_slider_promo_section .owl-item.active {
  height: 100%;
}

.plx_slider_promo_section .owl-carousel .owl-stage {
  height: 100%;
}

.plx_slider_promo_section .owl-stage-outer {
  height: 100%;
}

.plx_slider_promo_section .owl-carousel.owl-loaded {
  display: block;
  height: 100%;
}

.banner-slider::after {
  position: absolute;
  z-index: 9;
  content: '';
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.store-mart-lite-button.btn1 {
  text-align: right;
  position: relative;
  margin-top: 0;
}

a.promo-price-title:hover {
  color: #e8650d;
}

a.promo-price-title:hover::after {
  background-color: #e8650d;
}

.store-mart-lite-slider-promo-banner .owl-carousel .owl-item img {
  vertical-align: bottom;
  -o-object-fit: cover;
  object-fit: cover;
}

.tagged_as a {
  font-size: 16px;
  color: #666;
}

.tagged_as a:hover {
  color: #e8650d;
}

/*...........................plx_prod_cat_section....................................*/
.plx_prod_cat_section {
  padding: 45px 0 45px 0px;
  margin: 15px 0;
}

.store-mart-lite-cat-pro-wrap {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 0 10px;
}

.store-mart-lite-prod-cat-wrapper-one {
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  padding: 0 15px 0 0px;
  position: relative;
  overflow: hidden;
}

.store-mart-lite-prod-cat-wrapper-two {
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  padding: 0 15px 0 15px;
}

.store-mart-lite-prod-cat-wrapper-three {
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  -webkit-flex-basis: 33.33%;
  padding: 0 0px 0 15px;
}

.store-mart-lite-cat-prod-content {
  position: absolute;
  top: 50%;
  text-align: center;
  line-height: 2;
  z-index: 12;
  padding: 0 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.store-mart-lite-prod-cat-wrapper-one .store-mart-lite-cat-prod-content {
  text-align: left;
  left: 0;
}

.store-mart-lite-prod-cat-wrapper-three .store-mart-lite-cat-prod-content {
  text-align: right;
  right: 0;
}

.store-mart-lite-cat-prod-content-down {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 30px;
  z-index: 10;
  top: 0;
  margin-top: 30px;
}

.store-mart-lite-cat-prod-title {
  font-family: poppins;
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #23232c;
  margin: 0;
}

.store-mart-lite-cat-prod-description {
  margin: 0;
  display: block;
  font-size: 42px;
  color: #23232C;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.15;
  margin-bottom: 35px;
}

a.store-mart-cat-prod-btn {
  color: #fff;
  font-size: 16px;
  font-family: poppins;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  position: relative;
}

.store-mart-lite-prod-cat-wrapper-two {
  position: relative;
  overflow: hidden;
}

.store-mart-lite-prod-cat-wrapper-three {
  position: relative;
  overflow: hidden;
}

a.store-mart-cat-prod-btn:hover {
  color: #e8650d;
}

a.store-mart-cat-prod-btn:hover::after {
  background-color: #e8650d;
}

a.store-mart-cat-prod-btn::after {
  position: absolute;
  content: "";
  background-color: #000;
  height: 2px;
  width: 100%;
  bottom: -5px;
  left: 0;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  z-index: 10;
}

/*.store-mart-lite-cat-prod-image::after{
	position: absolute;
	background-color: rgba(0,0,0,0.2);
	height: 100%;
	width: 100%;
	content: "";
	z-index: 9;
	top: 0;
	left: 0;
}*/
.store-mart-lite-cat-prod-image {
  position: relative;
  overflow: hidden;
}

.store-mart-lite-cat-prod-image img {
  vertical-align: bottom;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-appearance: hidden;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.store-mart-lite-prod-cat-wrapper-one:hover img,
.store-mart-lite-prod-cat-wrapper-two:hover img,
.store-mart-lite-prod-cat-wrapper-three:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

/*.......................widget woocommerce widget_shopping_cart..................................*/
li.woocommerce-mini-cart-item.mini_cart_item {
  position: relative;
  text-align: center;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 0 !important;
  border-bottom: 1px solid #eee;
}

li.woocommerce-mini-cart-item.mini_cart_item:first-of-type {
  padding-top: 0 !important;
}

.widget_shopping_cart .total,
.widget_shopping_cart .total {
  border: none !important;
}

a.remove.remove_from_cart_button {
  position: absolute;
  top: 10px;
  right: 0;
  color: #e8650d;
  text-decoration: none;
  font-size: 20px;
}

a.remove.remove_from_cart_button:hover {
  color: #333;
}

.woocommerce-mini-cart.cart_list.product_list_widget a {
  color: #e8650d;
  font-family: poppins;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  display: block;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.woocommerce-mini-cart.cart_list.product_list_widget h4.item-title a {
  color: #333;
  font-family: poppins;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  display: block;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.woocommerce-mini-cart.cart_list.product_list_widget h4.item-title a:hover {
  color: #e8650d;
}

.widget_shopping_cart .cart_list li a.remove,
.woocommerce.widget_shopping_cart .cart_list li a.remove {
  position: absolute;
  top: 50px !important;
  left: 95% !important;
}

.widget_shopping_cart_content p {
  margin: 0;
  font-size: 16px;
  color: #7b7b7b;
  font-family: poppins;
}

.widget_shopping_cart_content h4.item-title {
  margin: 0;
}

.store-mart-lite-banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.woocommerce-mini-cart.cart_list.product_list_widget a:hover {
  color: #333;
  background-color: transparent;
}

.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img {
  width: 80px;
}

.woocommerce-mini-cart-item span.quantity {
  display: block;
  text-align: center;
  color: #b5b5b5;
}

ul.woocommerce-mini-cart.cart_list.product_list_widget {
  margin: 0;
}

p.woocommerce-mini-cart__total.total {
  margin: 12px 0;
  font-size: 16px;
  font-family: poppins;
}

p.woocommerce-mini-cart__total.total {
  margin: 10px 0;
  font-size: 16px;
  font-family: poppins;
}

.widget_shopping_cart_content p.total .amount {
  color: #4f4f4f;
  font-family: poppins;
  font-weight: 600;
  display: block;
  float: right;
  text-align: right;
}

p.buttons a.wc-forward {
  color: #fff;
  background-color: #e8650d;
  text-decoration: none;
  font-size: 16px;
  font-family: poppins;
  border-radius: 0px;
  text-align: center;
  padding: 10px 0;
  width: 47%;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  float: left;
}

p.buttons a.wc-forward:hover {
  background: rgba(223, 53, 80, 0.8);
  color: #FFF !important;
}

p.buttons a.checkout.wc-forward {
  color: #fff;
  background-color: #000000;
  padding: 10px 36px;
  text-decoration: none;
  font-size: 16px;
  font-family: poppins;
  border-radius: 0px;
  margin-left: 5px;
  width: 47%;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  float: right;
}

p.buttons a.checkout.wc-forward:hover {
  background: #333333;
  color: #FFF !important;
}

.cart-widget-prod a.product-mini-image {
  height: 80px;
  width: 80px;
}

a.remove:hover {
  color: #000 !important;
  background: transparent !important;
}

/*....................................plx_feat_prod_cat_section..................................................*/
/*.minicart-item-thumb {
    width: 100px;
}*/
.right-sidebar .sml-add-to-cart-wrap,
.left-sidebar .sml-add-to-cart-wrap,
.both-sidebar .sml-add-to-cart-wrap {
  top: -8px;
}

.left-sidebar .sml-product-image-wrapp .sml-add-to-cart-wrap,
.right-sidebar .sml-product-image-wrapp .sml-add-to-cart-wrap,
.both-sidebar .sml-product-image-wrapp .sml-add-to-cart-wrap {
  height: 30px;
}

.both-sidebar .sml-add-to-cart-wrap a.add_to_cart_button.button {
  padding: 0px;
  line-height: 3;
}

.left-sidebar .sml-add-to-cart-wrap a.add_to_cart_button.button,
.right-sidebar .sml-add-to-cart-wrap a.add_to_cart_button.button {
  padding: 0px;
  line-height: 2.5;
}

.left-sidebar .columns-3 a.link-quickview.yith-wcqv-button,
.right-sidebar .columns-3 a.link-quickview.yith-wcqv-button {
  padding: 6px 0px;
}

.both-sidebar .columns-3 a.link-quickview.yith-wcqv-button {
  padding: 8px 0px;
  font-size: 11px;
}

.both-sidebar .sml-add-to-wishlist-wrap .add-to-wishlist-custom a span {
  padding: 2px 2px;
}

.both-sidebar .sml-add-to-cart-wrap a.add_to_cart_button.button {
  font-size: 12px;
}

.both-sidebar .compare-wrap span {
  padding: 4px 4px;
}

.plx_feat_prod_cat_section .hide-rating .star-rating {
  display: none;
}

.minicart-item-content {
  width: calc(100% - 100px);
  padding-left: 15px;
}

.store-mart-lite-feat-prod-cat-wrap ul.products.columns-4 li.product:nth-child(4n) {
  margin-right: 0;
}

.store-mart-lite-feat-prod-cat-wrap ul.products.columns-4 li.product {
  width: 25%;
  margin-right: 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 0 10px;
}

ul.products.columns-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 0px;
}

ul.products li.product .price del {
  color: #444;
}

/*ul.products {
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;    
}*/
li.product.type-product:hover span.price {
  //opacity: 0;
  //visibility: hidden;
}

li.product.type-product span.price {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.sml-price-wrap {
  position: relative;
}

ul.products li.product .woocommerce-loop-product__title,
a.button {
  padding: 0;
}

/*.sml-product-title-wrapp h2.woocommerce-loop-product__title {
    padding-top: 12px !important;
}*/
.sml-add-to-cart-wrap {
  position: absolute;
  top: -1px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
  line-height: 1.5;
}

li.product.type-product:hover .sml-add-to-cart-wrap {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.products li.product {
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sml-add-to-cart-wrap a.add_to_cart_button.button {
  font-size: 15px;
  font-weight: 600;
  font-family: poppins;
  background-color: transparent;
  color: #000;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sml-add-to-cart-wrap a.link-quickview.yith-wcqv-button {
  background-color: #ebe9eb;
  font-size: 13px;
  border-radius: 3px;
  color: #51518f;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  font-weight: 500;
  font-family: poppins;
  width: 50%;
  padding: 10px 24px;
  text-align: center;
  color: #000;
}

.type-product a.add_to_cart_button.button:hover {
  color: #e8650d;
  background-color: transparent;
}

.sml-product-image-wrapp .sml-add-to-cart-wrap {
  position: absolute;
  bottom: 0;
  left: 0px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-product-image-wrapp {
  position: relative;
  overflow: hidden;
}

.store-mart-lite-feat-prod-cat-wrap ul.products li:hover .sml-add-to-cart-wrap {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.sml-add-to-wishlist-wrap {
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 12px;
  width: 100%;
  color: #FFF;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.store-mart-lite-feat-prod-cat-wrap ul.products li:hover .sml-add-to-wishlist-wrap {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom,
.sml-add-to-wishlist-wrap .compare-wrap,
.sml-add-to-wishlist-wrap .sml-quick-view-wrapp {
  display: block;
  width: 100%;
  text-align: right;
  height: 48px;
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom a {
  position: relative;
  display: block;
  color: #FFF;
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom a:before {
  position: absolute;
  content: "\e813";
  top: 0;
  right: 5px;
  font-family: 'Linearicons-Free';
  z-index: 9;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  background-color: #fff;
  padding: 10px 15px;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom a:hover:before {
  color: #e8650d;
}

a.link-wishlist {
  position: relative;
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom a.add_to_wishlist.link-wishlist span {
  position: absolute;
  display: block;
  top: 8px;
  right: 0px;
  padding: 4px 10px;
  background: #e8650d;
  -webkit-transform: translate(-35px, 0) translateZ(0);
  transform: translate(-35px, 0) translateZ(0);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom .yith-wcwl-wishlistexistsbrowse.show a span,
.sml-add-to-wishlist-wrap .add-to-wishlist-custom .yith-wcwl-wishlistaddedbrowse.show a span {
  position: absolute;
  display: block;
  top: 8px;
  right: 0px;
  padding: 4px 10px;
  background: #e8650d;
  -webkit-transform: translate(-35px, 0) translateZ(0);
  transform: translate(-35px, 0) translateZ(0);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom span:after,
.sml-add-to-wishlist-wrap .add-to-wishlist-custom .yith-wcwl-wishlistexistsbrowse.show a span:after,
.sml-add-to-wishlist-wrap .add-to-wishlist-custom .yith-wcwl-wishlistaddedbrowse.show a span:after {
  content: "";
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #e8650d;
}

.sml-add-to-wishlist-wrap .compare-wrap a:before {
  position: absolute;
  top: 0;
  right: 5px;
  content: "\e862";
  font-family: 'Linearicons-Free';
  z-index: 9;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  background-color: #FFF;
  padding: 10px 15px;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sml-add-to-wishlist-wrap .compare-wrap a:hover:before {
  color: #e8650d;
}

.sml-add-to-wishlist-wrap .compare-wrap {
  position: relative;
}

.sml-add-to-wishlist-wrap .compare-wrap span {
  position: absolute;
  display: block;
  top: 8px;
  right: 0;
  padding: 4px 10px;
  background: #e8650d;
  color: #FFF;
  -webkit-transform: translate(-35px, 0) translateZ(0);
  transform: translate(-35px, 0) translateZ(0);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-add-to-wishlist-wrap .compare-wrap span:after {
  content: "";
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #e8650d;
}

.sml-product-image-wrapp .add-to-wishlist-custom a.add_to_wishlist.link-wishlist:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-60px, 0);
  transform: translate(-60px, 0);
}

.sml-add-to-wishlist-wrap .compare-wrap a.sml-compare.product-compare:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-60px, 0);
  transform: translate(-60px, 0);
}

img.ajax-loading {
  position: absolute;
  top: 15px;
  right: 21px;
  height: 15px;
  width: 15px;
  visibility: visible;
  z-index: 100;
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom .yith-wcwl-wishlistexistsbrowse.show a:hover span,
.sml-add-to-wishlist-wrap .add-to-wishlist-custom .yith-wcwl-wishlistaddedbrowse.show a:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-60px, 0);
  transform: translate(-60px, 0);
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom .yith-wcwl-wishlistaddedbrowse.show a.link-wishlist::before {
  color: #e8650d;
}

.sml-add-to-cart-wrap a.added {
  display: none;
}

a.added_to_cart {
  background-color: transparent;
  font-size: 15px;
  color: #000;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  font-weight: 600;
  font-family: poppins;
}

a.added_to_cart {
  padding: 0;
}

a.added_to_cart:hover {
  color: #e8650d;
}

.sml-product-title-wrapp h2:hover {
  color: #e8650d;
}

.sml-product-title-wrapp h2 {
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sml-product-wrapp {
  position: relative;
  overflow: hidden;
}

ul.products li.product a img {
  margin: 0 !important;
}

/*.sml-product-wrapp:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 8;
}*/
#yith-quick-view-close {
  color: #cdcdcd;
  border-radius: 50%;
}

.quantity input.input-text {
  height: 45px !important;
  width: 45px !important;
  text-align: center;
  -webkit-appearance: none !important;
  border-radius: 0;
}

.quantity button {
  background-color: #333;
  color: #FFF;
}

.quantity button:hover {
  background-color: #e8650d;
}

ul.products li.product .onsale {
  left: 22px !important;
  top: -6.5px !important;
  right: auto !important;
}

table.compare-list .add-to-cart td a {
  background-color: #333 !important;
}

table.compare-list .add-to-cart td a:hover {
  background-color: #e8650d !important;
}

.sml-product-wrapp span.onsale {
  position: absolute;
  background-color: #e8650d;
  color: #fff;
  z-index: 9;
  font-size: 12px;
  font-weight: 400;
  font-family: poppins;
  padding: 6px !important;
  line-height: 1 !important;
  min-height: 0 !important;
  min-width: 0 !important;
  -webkit-transform: rotate(-90deg) translate3d(-100%, 0, 0) translateZ(0);
  transform: rotate(-90deg) translate3d(-100%, 0, 0) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: left;
  transform-origin: left;
  border-radius: 0;
}

span.onsale::after {
  content: "";
  position: absolute;
  color: #e8650d;
  border: solid 13px transparent;
  border-left-color: #e8650d;
  -webkit-transform: rotate(-90deg) translateZ(0);
  transform: rotate(-90deg) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left: -16px;
  top: -2.5px;
}

span.onsale::before {
  content: "";
  position: absolute;
  color: #e8650d;
  border: solid 13px transparent;
  border-left-color: #e8650d;
  -webkit-transform: rotate(90deg) translateZ(0);
  transform: rotate(90deg) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0px;
  left: -16px;
}

.store-mart-lite-product-title-wrap {
  text-align: center;
  line-height: 1.5;
  padding-bottom: 40px;
}

.plx_blog_section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.product-title {
  color: #979797;
  font-family: poppins;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 5px;
}

.product_list_widget .product-title {
  color: #222;
  font-family: poppins;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.product_list_widget ins {
  background: none;
  text-decoration: none;
}

.product-subtitle {
  color: #2c2a2b;
  font-size: 24px;
  font-weight: 600;
  font-family: poppins;
  text-transform: uppercase;
  margin: 0;
}

.sml-quick-view-wrapp a::after {
  position: absolute;
  content: "\e86f";
  font-family: 'Linearicons-Free';
  z-index: 9;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  top: 0;
  right: 5px;
  background-color: #FFF;
  padding: 10px 15px;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sml-quick-view-wrapp a:hover::after {
  color: #e8650d;
}

.sml-quick-view-wrapp a:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-60px, 0);
  transform: translate(-60px, 0);
}

.sml-quick-view-wrapp {
  position: relative;
}

.sml-quick-view-wrapp a span {
  position: absolute;
  display: block;
  top: 8px;
  right: 0;
  padding: 4px 10px;
  background: #e8650d;
  color: #FFF;
  white-space: nowrap;
  -webkit-transform: translate(-35px, 0) translateZ(0);
  transform: translate(-35px, 0) translateZ(0);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-quick-view-wrapp a span:after {
  content: "";
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #e8650d;
}

/*.............................plx_cta_section......................................*/
.store-mart-lite-cta-wrapper {
  height: 400px;
  width: 100%;
  position: relative;
}

.store-mart-lite-cta-content-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-family: poppins;
  line-height: 1.5;
  text-align: center;
  z-index: 10;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cta-title {
  font-size: 20px;
}

.cta-subtitle {
  font-size: 35px;
  font-weight: 800;
}

.store-mart-lite-cta-button a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  position: relative;
  text-align: center;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.store-mart-lite-cta-button a::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background-color: #FFF;
  top: 100%;
  left: 0;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.store-mart-lite-cta-button a:hover {
  color: #e8650d;
}

.store-mart-lite-cta-button a:hover::after {
  background-color: #e8650d;
}

.cta-price-text {
  font-size: 25px;
  font-weight: 600;
  font-family: poppins;
}

.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.sm-cart-btn-wrap p.woocommerce-mini-cart__buttons.buttons {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/*....................................plx_lat_prod_cat_section.....................................*/
.plx_lat_prod_cat_section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.store-mart-lite-lat-pro-title-wrap {
  text-align: center;
  line-height: 2;
  padding-bottom: 40px;
}

.lat-pro-title {
  color: #979797;
  font-family: poppins;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 5px;
}

.lat-pro-subtitle {
  color: #2c2a2b;
  font-size: 24px;
  font-weight: 800;
  font-family: poppins;
  text-transform: uppercase;
  margin: 0;
}

.plx_lat_prod_cat_section ul.products.columns-3 {
  /*display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between; */
  margin: 0;
}

.plx_lat_prod_cat_section li.product {
  padding: 10px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 10px !important;
}

.plx_lat_prod_cat_section .sml-lat-prod-detail-wrap {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
}

.latest-product-image img {
  vertical-align: bottom;
}

.plx_lat_prod_cat_section .border-top .sml-lat-prod-detail-wrap {
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.plx_lat_prod_cat_section .latest-product-image {
  width: 40%;
  height: 100%;
}

.plx_lat_prod_cat_section .lat-prod-cat-info {
  width: 60%;
  padding-left: 20px;
}

h2.woocommerce-loop-product__title {
  line-height: 1;
}

h2.woocommerce-loop-product__title a {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #010101;
  margin: 0;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  cursor: pointer;
}

h2.woocommerce-loop-product__title a:hover {
  color: #e8650d;
}

.plx_lat_prod_cat_section .prod-content {
  font-size: 16px;
  color: #2d2a2a;
  line-height: 1.4;
}

.plx_lat_prod_cat_section .product-price {
  position: relative;
  overflow: hidden;
}

.plx_lat_prod_cat_section span.price {
  /*font-size: 16px ;
    font-weight: 600 ;
    color: $mainColour ;*/
  font-family: poppins;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  visibility: visible;
  opacity: 1;
}

ul.products li.product .price {
  font-size: 16px;
  font-weight: 600;
}

.store-mart-lite-lat-prod-cat-wrap span.woocommerce-Price-amount.amount {
  color: #444;
}

.plx_lat_prod_cat_section .sml-lat-prod-detail-wrap:hover span.price {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(100%);
  transform: translate(100%);
}

.plx_lat_prod_cat_section .sml-latest-prod-add-to-cart {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(-100%, -50%) translateZ(0);
  transform: translate(-100%, -50%) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: none;
  font-size: 16px;
  text-transform: lowercase;
  font-weight: 400;
  color: #222;
  padding: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.plx_lat_prod_cat_section .sml-lat-prod-detail-wrap:hover .sml-latest-prod-add-to-cart {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  visibility: visible;
  opacity: 1;
}

.plx_lat_prod_cat_section .products .star-rating {
  margin: 0 !important;
  cursor: pointer;
}

.star-rating span::before {
  color: #ffbd67;
}

.plx_lat_prod_cat_section ul.products li.product .button {
  margin: 0 !important;
}

.plx_lat_prod_cat_section a.button.ajax_add_to_cart,
.plx_lat_prod_cat_section a.button.add_to_cart_button {
  background-color: transparent;
  font-size: 15px;
  font-weight: 400;
  font-family: poppins;
  position: relative;
  padding: 0 !important;
  color: #333;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.plx_lat_prod_cat_section a.button.ajax_add_to_cart:hover,
.plx_lat_prod_cat_section a.button.add_to_cart_button:hover {
  color: #e8650d;
  background: none;
}

.plx_lat_prod_cat_section a.button.ajax_add_to_cart:hover:before,
.plx_lat_prod_cat_section a.button.add_to_cart_button:hover:before {
  background-color: #e8650d;
  width: 100%;
}

.plx_lat_prod_cat_section a.button.ajax_add_to_cart:before,
.plx_lat_prod_cat_section a.button.add_to_cart_button:before {
  position: absolute;
  content: "";
  background-color: #222;
  height: 1px;
  width: 10%;
  top: 100%;
  left: 0;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.plx_lat_prod_cat_section ul.products li.product .price {
  margin: 0 !important;
}

.plx_lat_prod_cat_section a.button.ajax_add_to_cart:after {
  top: 0 !important;
  right: -5em !important;
}

.plx_lat_prod_cat_section a.button.ajax_add_to_cart.added {
  display: none;
}

.plx_lat_prod_cat_section a.added_to_cart {
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  font-family: poppins;
  position: relative;
  padding: 0 !important;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  color: #222;
}

.plx_lat_prod_cat_section a.added_to_cart:hover {
  color: #e8650d;
}

.plx_lat_prod_cat_section a.added_to_cart:hover:before {
  background-color: #e8650d;
  width: 100%;
}

.plx_lat_prod_cat_section a.added_to_cart:before {
  position: absolute;
  content: "";
  background-color: #222;
  height: 1px;
  width: 10%;
  top: 100%;
  left: 0;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sml-lat-prod-detail-wrap .lat-prod-cat-info h2.prod-title a {
  font-size: 16px;
  color: #222;
}

.sml-lat-prod-detail-wrap .lat-prod-cat-info h2.prod-title a:hover {
  color: #e8650d;
}

.sml-lat-prod-detail-wrap .lat-prod-cat-info h2.prod-title {
  line-height: 1;
  margin: 0px;
}

.plx_feat_prod_cat_section {
  padding: 60px 0;
}

/*.....................................site-footer.......................................................*/
.store-mart-lite-section-footer-wrap-main {
  background-color: #f6f6f6;
}

.store-mart-lite-section-footer-wrap-main .textwidget img {
  margin-bottom: 35px;
  width: 140px;
}

.store-mart-lite-section-footer-wrap-main h2 {
  color: #353535;
  font-family: poppins;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.store-mart-lite-widgets-wrap {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
}

.block.footer-widget-1 {
  width: 50%;
}

.block.footer-widget-2 {
  width: 16.66%;
}

.block.footer-widget-3 {
  width: 16.66%;
}

.block.footer-widget-4 {
  width: 16.66%;
}

.col-3 .block.footer-widget-1 {
  width: 40%;
  padding: 20px;
  border-right: 1px solid #e3e3e3;
}

.col-3 .block.footer-widget-2 {
  width: 30%;
  padding: 20px;
  border-right: 1px solid #e3e3e3;
}

.col-3 .block.footer-widget-3 {
  width: 30%;
  padding: 20px;
}

.col-2 .block.footer-widget-1 {
  width: 50%;
  padding: 20px;
  border-right: 1px solid #e3e3e3;
}

.col-2 .block.footer-widget-2 {
  width: 50%;
  padding: 20px;
}

.col-2 .block.footer-widget-3 {
  width: 50%;
  padding: 20px;
}

.col-1 .block.footer-widget-1 {
  width: 100%;
  padding: 20px;
}

.col-1 .block.footer-widget-2 {
  width: 100%;
  padding: 20px;
}

.col-1 .block.footer-widget-3 {
  width: 100%;
  padding: 20px;
}

.store-mart-lite-section-footer-wrap-main .textwidget p {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.8;
  padding-right: 60px;
  margin: 0;
  color: #707070;
}

.widget.widget_media_image img {
  width: 140px;
}

.team-members-contents {
  margin: 30px 0px;
  border-right: 1px solid #e3e3e3;
  width: 25%;
  padding-left: 20px;
}

.team-members-contents img {
  display: block;
  width: 200px;
}

.store-mart-lite-section-footer-wrap-main h4.widget-title {
  margin-bottom: 20px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: #333;
}

.store-mart-lite-section-footer-wrap-main ul.children {
  padding: 0;
}

.store-mart-lite-section-footer-wrap-main ul {
  margin: 0;
  list-style: none;
  font-family: poppins;
  padding: 0;
  margin-bottom: 35px;
}

.store-mart-lite-section-footer-wrap-main ul.menu li {
  margin: 0;
  display: block;
}

.store-mart-lite-section-footer-wrap-main ul li a {
  color: #707070;
  text-decoration: none;
  font-family: poppins;
  font-size: 13px;
  position: relative;
  line-height: 1.8;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  margin-bottom: 15px;
  display: inline-block;
}

/*.store-mart-lite-section-footer-wrap-main ul li a:after{
	position: absolute;
	top: 100%;
	left: 0;
	height: 1px;
	width: 0%;
	content: "";
	background-color:$mainColour;
	
}*/
.store-mart-lite-section-footer-wrap-main ul li a:hover {
  color: #e8650d;
}

.store-mart-lite-footer-wrap .store-mart-lite-container {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 0;
}

.store-mart-lite-footer-wrap .store-mart-lite-footer-copyright {
  margin: auto 0;
  font-size: 13px;
  font-family: poppins;
  color: #707070;
}

.store-mart-lite-footer-wrap .store-mart-lite-footer-image-control {
  width: 400px;
}

.store-mart-lite-footer-copyright a {
  text-decoration: none;
  position: relative;
  color: #e8650d;
}

.store-mart-lite-footer-copyright a {
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.store-mart-lite-footer-copyright a:hover {
  color: #222;
}

/*.store-mart-lite-footer-copyright a:after{
	position: absolute;
	top: 100%;
	left: 0;
	height: 1px;
	width: 100%;
	content: "";
	opacity: 0;
	visibility: hidden;
	background-color:$mainColour;
	-webkit-transition: all ease-in-out 0.5s;
        -moz-transition: all ease-in-out 0.5s;
         -ms-transition: all ease-in-out 0.5s;
          -o-transition: all ease-in-out 0.5s;
             transition: all ease-in-out 0.5s; 
}
.store-mart-lite-footer-copyright a:hover:after{
	opacity: 1;
	visibility: visible;
}
*/
/*...............................................end of home page..........................................................*/
/*................................store-mart-lite-bread-home..........................................*/
.left-sidebar .sidebar-left {
  -ms-order: 1;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.left-sidebar .content-area {
  -ms-order: 2;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  width: 65%;
}

.both-sidebar .content-area {
  width: 50%;
}

.both-sidebar .sidebar-left {
  width: 25%;
}

.both-sidebar .sidebar-right {
  width: 25%;
}

.both-sidebar .sidebar-left {
  -ms-order: 1;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.both-sidebar .content-area {
  -ms-order: 2;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.both-sidebar .sidebar-right {
  -ms-order: 3;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.store-mart-lite-bread-home {
  text-align: center;
  background: #ebebeb;
  color: #121212;
  margin: 0;
  padding: 50px 0 55px;
  @media screen and (max-width: 424px) {
    padding: 10px 0 10px;
  }
  position: relative;
}

ul.trail-items {
  margin: 0;
  list-style: none;
}

.trail-items li {
  list-style: none;
  display: inline-block;
  position: relative;
  padding-right: 10px;
  margin-right: 15px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.trail-items li a {
  text-decoration: none;
  color: #121212;
}

.trail-items li a::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 18px;
  background-color: #121212;
  top: 50%;
  right: 0;
  -webkit-transform: rotate(20deg) translateY(-50%);
  transform: rotate(20deg) translateY(-50%);
}

.archive-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 0;
  //margin-bottom: 60px;
  margin-top: 15px;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.archive-header span {
  font-size: 16px;
  font-weight: 600;
  color: #121212;
}

select.orderby {
  border: none;
  background: #f7f7f7;
  padding: 10px 10px;
  font-size: 13px;
  color: #a9a9a9;
  background-image: url("../../../assets/images/arrow-2.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  -webkit-appearance: none;
}

.trail-item.trail-end.current span {
  color: #e8650d;
}

/*................................product..................................*/
.sml-product-image-wrapp {
  position: relative;
}

ul.products li.product .sml-product-title-wrapp span.price {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
}

ul.products.columns-3 li.product {
  width: 32%;
  margin-right: 2%;
  float: left;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
  margin-bottom: 20px;
  overflow: hidden;
}

ul.products.columns-3 li.product:nth-child(3n) {
  margin-right: 0;
}

ul.products li:hover .sml-add-to-cart-wrap {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

ul.products li:hover .sml-add-to-wishlist-wrap {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.sml-add-to-wishlist-wrap .add-to-wishlist-custom a:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-42px, 0);
  transform: translate(-42px, 0);
}

.columns-3 a.added_to_cart {
  padding: 7px 16px;
}

.columns-3 a.link-quickview.yith-wcqv-button {
  padding: 10px 44px;
}

nav.woocommerce-pagination {
  padding: 20px;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

ul.products li.product .woocommerce-loop-product__title {
  display: inline-block;
  margin-top: 10px;
}

nav.woocommerce-pagination ul {
  border: none;
  margin: 0;
}

nav.woocommerce-pagination ul li {
  border: none;
}

nav.woocommerce-pagination ul li span.current {
  background: none;
  color: #121212;
}

nav.woocommerce-pagination ul li {
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  font-family: poppins;
}

nav.woocommerce-pagination ul li a {
  color: #808080;
  position: relative;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

nav.woocommerce-pagination ul li a:hover {
  color: #121212;
  background: none;
}

.woocommerce.post-type-archive-product nav.woocommerce-pagination ul li a.next:after {
  visibility: visible;
  position: absolute;
  top: 9px;
  font-family: 'Linearicons-Free';
  font-size: 16px;
  color: #000;
  font-weight: 700;
  content: "\e876";
  left: 0;
}

.woocommerce.post-type-archive-product nav.woocommerce-pagination ul li a.prev:after {
  visibility: visible;
  position: absolute;
  top: 9px;
  font-family: 'Linearicons-Free';
  font-size: 16px;
  color: #000;
  font-weight: 700;
  content: "\e875";
  left: 0;
}

a.next.page-numbers,
a.prev.page-numbers {
  color: transparent !important;
}

.sml-product-title-wrapp {
  border-top: 1px solid #ebe9eb;
}

.tagcloud a {
  color: #404040;
  border: 1px solid #404040;
  margin: 5px;
  padding: 5px;
  display: inline-block;
}

.tagcloud a:hover {
  color: #e8650d;
  border-color: #e8650d;
}

/*...............................................Blog...........................................................*/
.sml-single-post-wrapper {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.sml-single-post-wrapper.right-sidebar-enabled {
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.sml-single-post-wrapper.left-sidebar-enabled {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.content-area {
  width: 100%;
  padding-top: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.sidebar-left {
  width: 35%;
  padding: 20px 30px;
  margin-top: 40px;
  padding-left: 10px;
  margin-bottom: 40px;
}

.widget-area .widget:last-of-type {
  margin-bottom: 0;
}

.sidebar-right {
  width: 35%;
  padding: 20px 30px;
  padding-right: 10px;
}

.right-sidebar-enabled .content-area {
  width: 65%;
  padding-top: 20px;
}

.left-sidebar-enabled .content-area {
  width: 65%;
  padding-top: 20px;
}

.both-sidebar-enabled .content-area {
  width: 50%;
  -ms-order: 2;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.both-sidebar-enabled .sidebar-left {
  width: 25%;
  -ms-order: 1;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.both-sidebar-enabled .sidebar-right {
  width: 25%;
  -ms-order: 3;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.no-sidebar .content-area {
  width: 100%;
}

/*..............................................sidebar-right...................................................*/
.sidebar-right {
  width: 35%;
  padding: 20px 30px;
  margin-top: 40px;
  padding-right: 10px;
  margin-bottom: 40px;
}

.widget-area .widget ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.widget-area h2.widget-title {
  position: relative;
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  padding-left: 15px;
  margin-bottom: 30px;
  line-height: 1;
  margin-top: 0px;
}

.widget_price_filter .price_slider_amount .button {
  font-size: 16px;
  font-weight: 500;
  color: #FFF;
  background: #000;
  border-radius: 0;
  text-transform: uppercase;
  padding: 10px 25px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.widget_price_filter .price_slider_amount .button:hover {
  background-color: #e8650d;
}

.widget_price_filter .price_slider_amount .price_label {
  display: block;
  width: 100%;
  text-align: left;
  float: left;
}

.widget_price_filter .ui-slider {
  margin: 0;
}

.widget-area h2.widget-title::after {
  position: absolute;
  background-color: #e8650d;
  content: '';
  width: 3px;
  height: 100%;
  top: 0px;
  left: 0;
}

.price_slider_amount {
  margin-top: 15px;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range {
  height: 3px;
}

.widget-area .widget {
  margin-bottom: 45px;
  border: 1px solid #eee;
  padding: 30px;
}

.widget-area .widget li a {
  color: #222;
  font-weight: 500;
  padding-right: 5px;
  font-size: 16px;
  text-decoration: none;
}

.widget-area .widget li {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  color: #777777;
  font-weight: 500;
  padding-right: 5px;
  font-size: 16px;
}

.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img {
  float: left;
  margin-left: 0;
  margin-right: 15px;
}

.widget-area .menu li {
  display: block;
}

.widget-area .widget li:last-child {
  border-bottom: none;
  padding-bottom: 0 !important;
  margin-bottom: 0px !important;
}

.widget-area .widget select {
  background: transparent;
  height: 45px;
  line-height: 45px;
  padding: 0 45px 0 15px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  -ms-appearance: none;
  -o-appearance: none;
  background-repeat: no-repeat !important;
  //background-image: url("assets/images/angle-down.png") !important;
  position: relative;
  background-position: right 10px center !important;
  width: 100%;
  font-size: 16px;
  border: 1px solid #ddd;
  margin: 10px 0;
}

td#next {
  text-align: right;
}

.prev-link-wrapper h2,
.next-link-wrapper h2 {
  margin: 0;
}

.widget-area .widget.widget_categories {
  padding: 25px;
}

.widget-area .widget.widget_rss a {
  text-decoration: none;
  font-size: 22px;
  font-weight: 600;
  color: #1a1a1a;
}

.widget-area .widget.widget_rss h2.widget-title::after {
  bottom: 5px !important;
}

.widget-area .widget.widget_rss h2.widget-title {
  position: relative;
}

.widget-area a.rsswidget {
  display: inline-block;
}

.widget-area form.search-form {
  position: relative;
}

.widget-area input.search-submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.widget-area form.search-form::after {
  position: absolute;
  top: 0px;
  right: 0;
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 47px;
  background: #e8650d;
  color: #FFF;
  content: "\e86f";
  font-family: 'Linearicons-Free';
  z-index: 0;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.widget-area input.search-field {
  height: 45px;
  width: 100%;
  line-height: 45px;
  padding: 0 55px 0 10px;
  border: 1px solid #eee;
  font-size: 16px;
  border-radius: 0;
  -webkit-appearance: none;
}

.widget-area input.search-field:focus {
  outline: none;
}

#wp-calendar caption {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

table#wp-calendar {
  border-collapse: collapse;
}

table#wp-calendar thead tr th {
  text-align: center;
  border: 1px solid #eee;
}

table#wp-calendar tbody tr td {
  text-align: center;
  border: 1px solid #eee;
  font-size: 16px;
  color: #404040;
  font-weight: 400;
}

/*........................................................singlepost.................................................*/
.widget-area li a,
.widget-area h2.widget-title {
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.widget-area li a:hover {
  color: #e8650d;
}

.entry-content p {
  font-size: 16px;
  line-height: 1.65;
  color: #404040;
}

textarea#comment {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 10px;
  width: 100%;
}

input#author,
input#email {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 10px;
  width: 100%;
}

input#url {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 10px;
  width: 100%;
}

.widget-area p.form-submit {
  margin-top: 35px;
  display: block;
  margin-bottom: 40px;
}

input#submit {
  font-size: 100%;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  overflow: visible;
  padding: 0 35px;
  font-weight: 700;
  border-radius: 3px;
  left: auto;
  color: #fff;
  background-color: #252525;
  border: 0;
  display: inline-block;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  height: 51px;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

p.comment-form-author {
  width: 32%;
  margin-right: 1%;
  display: inline-block;
}

p.comment-form-email {
  width: 32%;
  display: inline-block;
  margin-right: 1%;
}

.comment-form label {
  margin-bottom: 7px;
  display: inline-block;
  color: #262626;
  font-size: 16px;
}

p.comment-form-url {
  display: inline-block;
  width: 32%;
}

.entry-content {
  padding: 0px 30px 30px 30px;
}

.comment-form-cookies-consent input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
  margin: 6px;
}

.nav-previous,
.nav-next {
  font-size: 16px;
}

span.posted-month {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

span.posted-day {
  font-size: 32px;
  display: block;
  font-weight: 600;
  line-height: 1;
  margin-top: 10px;
  padding-top: 10px;
}

.blog-date-inner {
  line-height: 1.5;
}

.sml-single-thumb-wrapp .blog-date {
  position: absolute;
  top: 30px;
  left: 30px;
  color: #fff;
  height: 75px;
  width: 75px;
  background-color: #e8650d;
  text-align: center;
  font-size: 16px;
}

.single-tag-wrapp span.tags-links a {
  color: #222;
  font-size: 16px;
}

.single-tag-wrapp span.tags-links a:hover {
  color: #e8650d;
}

.sml-single-thumb-wrapp {
  position: relative;
}

.single-meta-wrapp .author-wrapp img {
  max-width: 35px;
  border-radius: 50%;
  height: 100%;
}

.author-wrapp .comment-author {
  display: inline-block;
  vertical-align: middle;
}

.author-wrapp a {
  font-size: 16px;
  text-transform: capitalize;
  color: #222;
  font-weight: 500;
  display: block;
  padding: 10px 0 6px;
  border-bottom: 1px solid #ddd;
}

.single-meta-wrapp .author-wrapp {
  display: inline-block;
  margin-right: 20px;
  position: relative;
}

.blog-content-wrapp {
  border-bottom: 1px solid #ddd;
}

.single-content-wrapp {
  font-size: 16px;
  font-weight: 300;
}

.single-meta-wrapp .author-wrapp a {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  padding-left: 5px;
  text-decoration: none;
  display: inline-block;
  border-bottom: none;
}

.single-meta-wrapp .cat-links a {
  color: #6c6c6c;
  font-size: 16px;
  font-weight: 500;
  padding-left: 5px;
  text-decoration: none;
}

.single-meta-wrapp .author-wrapp a:hover,
.single-meta-wrapp .cat-links a:hover {
  color: #e8650d;
}

.single-meta-wrapp .cat-links {
  padding-left: 15px;
  display: inline-block;
  margin-right: 20px;
  position: relative;
}

.single-meta-wrapp .cat-links:before {
  position: absolute;
  content: "\e81d";
  font-family: 'Linearicons-Free';
  font-size: 15px;
  font-weight: 600;
  top: 1px;
  left: 0;
  color: #6c6c6c;
}

.single-meta-wrapp .comment {
  color: #6c6c6c;
}

.single-meta-wrapp .comment i {
  padding-right: 5px;
}

.single-meta-wrapp .author-wrapp, .single-meta-wrapp .cat-links, .single-meta-wrapp .comment {
  display: inline-block;
  position: relative;
  -ms-word-break: break-all;
  word-break: break-all;
}

.single-meta-wrapp .comment {
  color: #333;
  padding-top: 0px;
}

.sm-inner-wrapper .content-area p {
  font-size: 16px;
  line-height: 1.65;
}

nav.navigation.post-navigation {
  margin-top: 30px;
  margin-bottom: 30px;
}

.comments-area {
  margin-top: 30px;
}

h2.comments-title {
  margin-bottom: 20px;
}

.comment-list {
  padding-left: 0;
  margin-left: 0;
  list-style: none;
}

.comment-list li.comment .comment-author img {
  width: 50px;
  border-radius: 6px;
  vertical-align: top;
}

.comment-list li.comment {
  margin: 30px 0;
  padding-bottom: 25px;
}

.comment-list li.comment:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

article.comment-body {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

ol.comment-list li {
  list-style: none;
}

.comment-list li.comment .comment-author {
  display: inline-block;
}

.comment-list li.comment .comment-author b {
  padding-left: 10px;
  font-weight: 600;
}

span.says {
  display: none;
}

.comment-list li.comment .comment-metadata {
  display: inline-block;
  font-size: 12px;
  position: relative;
  padding-left: 12px;
  margin-left: 7px;
  font-weight: 300;
}

.comment-list li.comment .comment-metadata:before {
  top: 50%;
  left: 0;
  position: absolute;
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background: #aaa;
}

.comment-list li.comment .comment-metadata a {
  color: #aaa;
}

.comment-list li.comment .comment-content {
  padding-left: 65px;
  position: relative;
  margin-top: -20px;
}

b.fn a {
  font-size: 16px;
  color: #404040;
}

.comment-list li.comment .reply {
  margin-left: 65px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  padding-left: 16px;
}

.comment-list li.comment .reply:before {
  position: absolute;
  content: "\f122";
  font-family: 'fontawesome';
  font-size: 10px;
  font-weight: 600;
  top: 1px;
  left: 0;
  color: #aaa;
}

.reply a:hover {
  color: #e8650d;
}

.single_post_pagination_wrapper {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.prev-link,
.next-link {
  display: inline-block;
  width: 50%;
}

span.prev-image,
span.next-image {
  width: 115px;
  display: inline-block;
  vertical-align: top;
}

.prev-text,
.next-text {
  display: inline-block;
  width: calc(100% - 115px);
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: top;
  text-align: left;
}

.prev-text h4,
.next-text h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 1.45;
}

.prev-text h4 a,
.next-text h4 a {
  color: #212121;
}

.prev-text h4 a:hover,
.next-text h4 a:hover,
.prev-text h2 a:hover,
.next-text h2 a:hover {
  color: #e8650d;
}

.prev-text h2 a {
  color: #666666;
  position: relative;
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
}

.next-text h2 a {
  color: #666666;
  position: relative;
  padding-right: 35px;
  font-size: 15px;
  font-weight: 400;
}

.prev-link-wrapper,
.next-link-wrapper {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.single_post_pagination_wrapper .prev-link h2 a:before {
  position: absolute;
  content: "\e879";
  font-family: 'Linearicons-Free';
  font-size: 15px;
  font-weight: 600;
  top: 0;
  left: 0;
  color: #e8650d;
}

.single_post_pagination_wrapper .next-link h2 a:before {
  position: absolute;
  content: "\e87a";
  font-family: 'Linearicons-Free';
  font-size: 15px;
  font-weight: 600;
  top: 0;
  right: 0;
  color: #e8650d;
}

i.fa.fa-commenting-o {
  padding-right: 5px;
}

.no-thumb .blog-content-wrapp {
  padding-top: 120px;
}

/*........................................archieve..............................................*/
.sml-archive-wrapper.left-sidebar-enabled {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.sml-archive-wrapper {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.sml-blog-ct {
  position: relative;
  z-index: 0;
}

.has-post-thumbnail .blog-date-inner {
  line-height: 1.5;
  margin: -110px auto 35px;
}

.sml-blog-wrapp .blog-date {
  color: #fff;
  height: 75px;
  width: 75px;
  background-color: #e8650d;
  text-align: center;
  font-size: 16px;
  margin: -13px 0 35px 0;
}

.left-sidebar-enabled .sml-blog-wrapp .blog-date,
.right-sidebar-enabled .sml-blog-wrapp .blog-date,
.both-sidebar-enabled .sml-blog-wrapp .blog-date {
  left: 40px;
}

.sml-blog-ct img {
  vertical-align: text-bottom;
  width: 100%;
}

.sml-blog-ct-main-wp {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  background: #F8F9FD;
  padding: 50px 45px;
  position: relative;
  z-index: 1;
}

.post-meta-wrapp {
  width: 10%;
  display: inline-block;
  padding-top: 10px;
  text-align: center;
}

.content-wrapp-outer {
  width: 90%;
  padding-left: 40px;
}

.no-thumb .sml-blog-ct-main-wp {
  padding-top: 80px;
}

.sml-blog-wrapp .author-wrapp .comment-author img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin: 0 auto;
}

/*.sml-blog-wrapp .content-wrapp-outer .cat-links a:after {
    position: absolute;
    content: "\e81d";
    font-family: 'Linearicons-Free';
    font-size: 15px;
    font-weight: 600;
    top: -1px;
    left: 0;
    color: #aaa;
}*/
.sml-blog-wrapp .content-wrapp-outer .cat-links {
  position: relative;
  margin-bottom: 10px;
  display: block;
}

/*.sml-blog-wrapp .content-wrapp-inner {
    padding-left: 40px;
}*/
.sml-blog-wrapp .content-wrapp-outer .cat-links a {
  display: inline-block;
  font-size: 16px;
  color: #e8650d;
  margin-right: 10px;
}

.sml-blog-wrapp .content-wrapp-outer .cat-links a:hover {
  color: #e8650d;
}

.sml-blog-wrapp .content-wrapp-outer .entry-title a {
  color: #000000;
  white-space: nowrap;
}

.sml-blog-wrapp .content-wrapp-outer .entry-title a:hover {
  color: #e8650d;
}

.sm-blog-wrapp .content-wrapp-outer .entry-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.35;
}

.sml-blog-ct-main-wp .content-wrapp-inner .entry-content {
  padding: 0;
  border-bottom: none;
  display: block;
  color: #535;
  font-size: 16px;
  line-height: 1.714;
}

.sml-blog-wrapp .sml-read-more {
  display: block;
  margin-top: 25px;
}

.sml-blog-wrapp .content-wrapp-inner .sml-read-more a {
  display: inline-block;
  height: 45px;
  line-height: 45px;
  padding: 0 30px;
  font-size: 15px;
  font-weight: 500;
  color: #FFF;
  background: #e8650d;
}

.sml-blog-wrapp .content-wrapp-inner .sml-read-more a:hover {
  background-color: #000;
}

/*........................................single page..............................................................*/
.sml-page-wrap {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sml-page-wrap.left-sidebar-enabled {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.sml-single-page-wrapp .entry-content {
  padding: 0;
}

.right-sidebar .content-area {
  width: 65%;
}

.leftt-sidebar .content-area {
  width: 65%;
}

.both-sidebar .content-area {
  width: 50%;
}

/*................................widget.....................................*/
.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  border-radius: 0;
  background-color: #000;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  width: 3px;
  background-color: #000000;
  border-radius: 0;
  margin-left: 0;
  top: -7px;
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  height: 3px;
}

.price_slider_amount button {
  background-color: #000;
  color: #ffffff !important;
  cursor: pointer;
  border: none;
  padding: 10px 15px;
}

.widget.woocommerce.widget_product_search button {
  position: absolute;
  top: 10px;
  right: 0;
  height: 45px;
  width: 45px;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.price_slider_amount button:hover,
.widget.woocommerce.widget_product_search button:hover {
  background-color: #DF3550;
}

.yith-woocompare-widget ul.products-list li .remove {
  right: 100%;
  color: #000 !important;
  bottom: 20px;
  left: unset;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.yith-woocompare-widget ul.products-list li .remove:hover {
  color: #DF3550 !important;
  background: none !important;
}

a.clear-all {
  text-decoration: underline;
  color: #DF3550;
}

a.clear-all:hover {
  color: #000;
}

.woocommerce a.button.compare {
  position: relative;
}

.woocommerce a.button.compare:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  content: "\e862";
  font-family: 'Linearicons-Free';
  font-size: 20px;
  font-weight: 400;
  color: #000;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  visibility: visible;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

a.compare.added.button {
  font-size: 0;
  background: none;
}

.yith-woocompare-widget ul.products-list li:nth-last-child(1) {
  border: none;
}

a.button.compare:hover:before {
  color: #FFF;
  background-color: #000;
  /*border: none;*/
}

ul.children {
  padding: 0px 25px;
}

.both-sidebar-enabled .widget-area ul.product_list_widget li img {
  width: 100% !important;
}

/*...............................................single product layout....................................................*/
.store-mart-lite-share-buttons ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: inline-block;
}

.store-mart-lite-share-buttons ul li {
  margin: 5px;
  display: inline-block;
}

.store-mart-lite-share-buttons ul li a.facebook-share {
  background: #4267b2;
}

.store-mart-lite-share-buttons ul li a.twitter-share {
  background: #38A1F3;
}

.store-mart-lite-share-buttons ul li a.googleplus-share {
  background: #CC3333;
}

.store-mart-lite-share-buttons ul li a.linkedin-share {
  background: #0077B5;
}

.store-mart-lite-share-buttons ul li a.pinterest-share {
  background: #BD081C;
}

.store-mart-lite-share-buttons ul li a.stumbleupon-share {
  background-color: #f74425;
}

.store-mart-lite-share-buttons ul li a.email-share {
  background: #000;
}

.store-mart-lite-share-buttons ul li a {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  border-radius: 50%;
}

.store-mart-lite-share-buttons ul li a:hover {
  background-color: #000;
  color: #FFF;
}

/*................................error page.................................................*/
.error-404.not-found {
  text-align: center;
  margin-bottom: -30px;
}

.sml-error-wrapper {
  margin-top: 20px;
}

.store-mart-lite-404 {
  color: #222;
  font-size: 200px;
  font-weight: 700;
  margin: 0;
  line-height: 1;
  padding-bottom: 0;
}

.error-404.not-found p.search-sorry {
  color: #333;
  font-weight: 300;
  font-size: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 50px;
}

.error-404.not-found p.search-not-exists {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #e8650d;
  margin-bottom: 20px;
}

.error-404.not-found a {
  color: #e8650d;
  font-size: 16px;
  border-bottom: 1px solid #e8650d;
  line-height: 1;
  display: inline-block;
  opacity: 0.9;
}

.home-404-link {
  text-align: center;
  margin-bottom: 50px;
}

.no-results.not-found {
  margin-bottom: 100px;
}

.no-results.not-found form.search-form {
  max-width: 430px;
  margin: 0 auto;
  position: relative;
}

.no-results.not-found input.search-field {
  width: 100%;
  background-color: #eef4f7;
  color: #7d7d7d;
  height: 45px;
  padding: 0 50px 0 20px;
  border: none;
}

.no-results.not-found .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0 !important;
  height: 45px;
  width: 45px;
  padding: 0;
  opacity: 0;
  border: none;
  color: #000;
  font-size: 0px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 100;
}

/*.no-results.not-found .search-form:after {
    position: absolute;
    content: '\e86f';
    font-family: Linearicons-Free;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: 20px;
}*/
.store-mart-lite-archive-navigation {
  padding: 20px;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.store-mart-lite-archive-navigation ul li {
  display: inline-block;
  padding: 5px;
}

.store-mart-lite-archive-navigation ul {
  margin: 0;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.store-mart-lite-archive-navigation ul li.active a {
  font-size: 16px;
  background-color: #e8650d;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  text-align: center;
  padding: 5px 15px;
  color: #fff;
}

.store-mart-lite-archive-navigation ul li a {
  font-size: 16px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  text-align: center;
  padding: 5px 15px;
  color: #000;
}

.store-mart-lite-archive-navigation ul li a:hover {
  background-color: #e8650d;
  color: #fff;
}

.store-mart-lite-archive-navigation .next {
  margin-left: 10px;
  width: 35px;
  display: inline-block;
  font-size: 0;
  line-height: 0;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0;
  visibility: collapse;
  vertical-align: middle;
}

.store-mart-lite-archive-navigation .prev a,
.store-mart-lite-archive-navigation .next a {
  display: block;
  position: relative;
  font-size: 0;
}

.store-mart-lite-archive-navigation .next a:before {
  content: "\e876";
  font-size: 16px;
  visibility: visible;
  position: relative;
  display: inline-block;
  font-weight: 500;
  font-family: Linearicons-Free;
  left: 0;
  color: #000;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 0px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.store-mart-lite-archive-navigation .prev a:before {
  content: "\e875";
  font-size: 16px;
  visibility: visible;
  position: relative;
  display: inline-block;
  font-weight: 500;
  font-family: Linearicons-Free;
  left: 0;
  color: #000;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 0px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.store-mart-lite-archive-navigation .next a:hover:before,
.store-mart-lite-archive-navigation .prev a:hover:before {
  background-color: #e8650d;
  color: #fff;
}

/*
sdfgsdfgsdfsd*/
div.product .woocommerce-tabs ul.tabs li {
  border: none;
  background: none;
}

div.product .woocommerce-tabs ul.tabs li::after,
div.product .woocommerce-tabs ul.tabs li::before {
  border: none;
}

div.product .woocommerce-tabs ul.tabs li.active {
  z-index: 0;
}

.description_tab.active a {
  position: relative;
}

/*.description_tab.active a:after{
	position: absolute;
	content: "";
	background-color: #000;
	width: 100%;
	height: 4px;
	bottom: 0;
	left: 0;
}*/
div.product .woocommerce-tabs ul.tabs::before {
  border-bottom: 2px solid #d3ced2;
}

div.product .woocommerce-tabs ul.tabs li a {
  font-weight: 500;
  color: #BEBEBE;
}

div.product .woocommerce-tabs ul.tabs li a:hover {
  color: #000;
}

.woocommerce-Tabs-panel.entry-content {
  padding: 0px 30px 30px 30px;
  border-bottom: none;
}

.woocommerce-product-details__short-description ul {
  margin-left: 0px;
}

button.button.alt {
  background-color: #000;
  color: #FFF;
  border-radius: 0;
  height: 45px;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

button.button.alt:hover {
  background-color: #e8650d;
}

.summary.entry-summary p.price {
  font-size: 32px !important;
  font-weight: 600 !important;
  margin: 10px 0 20px;
  border-bottom: solid 1px #eee;
  padding-bottom: 20px;
}

div.product p.price {
  color: #121212;
}

.sml-single-cart-wrapp a.add_to_wishlist,
.sml-single-cart-wrapp a.link-wishlist {
  position: relative;
}

.sml-single-cart-wrapp a.add_to_wishlist:before,
.sml-single-cart-wrapp a.link-wishlist:before {
  content: "\e813";
  font-size: 20px;
  visibility: visible;
  position: relative;
  display: inline-block;
  font-weight: 500;
  font-family: Linearicons-Free;
  left: 0;
  color: #000;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 0px;
  border: 1px solid #ddd;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  box-shadow: 0px 0px 10px 1px rgba(100, 100, 100, 0.1);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-single-cart-wrapp a.add_to_wishlist:hover:before,
.sml-single-cart-wrapp a.link-wishlist:hover:before {
  background-color: #000;
  color: #FFF;
}

.sml-single-cart-wrapp.sml-single-product-outer {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
}

.sml-single-cart-wrapp .sml-compare-wrapp,
.sml-compare-wrapp {
  padding-left: 5px;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.sml-single-cart-wrapp .compare-wrap {
  display: inline-block;
  position: relative;
  margin-left: 50px;
}

.sml-single-cart-wrapp .compare-wrap a:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "\e862";
  font-family: 'Linearicons-Free';
  z-index: 9;
  font-size: 22px;
  line-height: 2;
  color: #000;
  font-weight: 700;
  height: 45px;
  width: 45px;
  border: 1px solid #ddd;
  text-align: center;
  font-weight: 400;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.sml-single-cart-wrapp .compare-wrap a:hover:before {
  background-color: #000;
  color: #FFF;
}

.sml-single-cart-wrapp .yith-wcwl-add-button a.add_to_wishlist span {
  position: absolute;
  bottom: 190%;
  left: -10px;
  color: #FFF;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  background: #4C4C4C;
  padding: 5px 10px;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-single-cart-wrapp a.link-wishlist span {
  position: absolute;
  bottom: 190%;
  left: -10px;
  color: #FFF;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  background: #4C4C4C;
  padding: 5px 10px;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-single-cart-wrapp a.compare {
  position: relative;
}

.sml-single-cart-wrapp a.compare span {
  position: absolute;
  bottom: 195%;
  right: 100%;
  color: #FFF;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  background: #4C4C4C;
  padding: 5px 10px;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sml-single-cart-wrapp a.add_to_wishlist span:after {
  position: absolute;
  content: "";
  top: 100%;
  left: 15px;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #4c4c4c transparent transparent transparent;
}

.sml-single-cart-wrapp .yith-wcwl-wishlistexistsbrowse a span:after {
  position: absolute;
  content: "";
  top: 100%;
  left: 10%;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #4c4c4c transparent transparent transparent;
}

.sml-single-cart-wrapp a.compare span:after {
  position: absolute;
  content: "";
  top: 100%;
  right: 15px;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #4c4c4c transparent transparent transparent;
}

.sml-single-cart-wrapp a.add_to_wishlist:hover span {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.sml-single-cart-wrapp a.link-wishlist:hover span {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.sml-single-cart-wrapp a.compare:hover span {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  opacity: 1;
  visibility: visible;
}

.sml-single-cart-wrapp .compare-wrap a.added:before {
  top: -17px;
}

a.sml-compare.product-compare.compare.compare-link.added {
  font-size: 0;
}

span.posted_in {
  font-size: 16px;
  color: #666;
  display: block;
}

span.posted_in a {
  color: #aaa;
}

span.posted_in a:hover {
  color: #e8650d;
}

.product_meta {
  padding: 20px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 20px 0;
}

div.product form.cart {
  margin-bottom: 0;
}

.store-mart-lite-share-buttons span {
  font-size: 16px;
  color: #666;
  margin-right: 10px;
}

/*.yith-wcwl-wishlistaddedbrowse.show span{
	position: absolute;
    bottom: 190%;
    left: -10px;
    color: #FFF;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    background: #4C4C4C;
    padding: 5px 10px;
    -webkit-transition: all .4s cubic-bezier(.77,0,.175,1);
    -moz-transition: all .4s cubic-bezier(.77,0,.175,1);
    -ms-transition: all .4s cubic-bezier(.77,0,.175,1);
    -o-transition: all .4s cubic-bezier(.77,0,.175,1);
    transition: all .4s cubic-bezier(.77,0,.175,1);
}*/
.yith-wcwl-wishlistaddedbrowse.show span:after {
  position: absolute;
  content: "";
  top: 100%;
  left: 15px;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #4c4c4c transparent transparent transparent;
}

.yith-wcwl-wishlistaddedbrowse.show a.link-wishlist:hover span {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

/*................................responsive...............................*/
.sml-scrollup {
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 15px;
}

.sml-scrollup span {
  display: block;
  height: 60px;
  width: 60px;
  margin: 20px;
  text-align: center;
  line-height: 60px;
  border-radius: 0;
  position: relative;
  font-size: 16px;
  color: #FFF;
  opacity: 1;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.8, 0.9, 0.8);
  transition: all 0.3s cubic-bezier(0.5, 0.8, 0.9, 0.8);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.sml-scrollup span:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
  -webkit-box-shadow: inset 0 0 0 35px #e8650d;
  box-shadow: inset 0 0 0 35px #e8650d;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: box-shadow 0.3s, transform 0.3s;
  -webkit-transition: -webkit-box-shadow 0.3s, -webkit-transform 0.3s;
  transition: -webkit-box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, -webkit-box-shadow 0.3s, -webkit-transform 0.3s;
}

.sml-scrollup span:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.025), 0 10px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.025), 0 10px 15px rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.8, 0.9, 0.8);
  transition: all 0.3s cubic-bezier(0.5, 0.8, 0.9, 0.8);
}

.sml-scrollup span:before {
  -webkit-box-shadow: inset 0 0 0 35px #e8650d;
  box-shadow: inset 0 0 0 35px #e8650d;
}

.sml-scrollup span:hover {
  color: #e8650d;
}

.sml-scrollup span:hover:before {
  -webkit-box-shadow: inset 0 0 0 1px #e8650d;
  box-shadow: inset 0 0 0 1px #e8650d;
}

.sml-scrollup span:hover:before {
  -webkit-box-shadow: inset 0 0 0 1px #e8650d;
  box-shadow: inset 0 0 0 1px #e8650d;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.sml-scrollup span:hover:after {
  visibility: visible;
  opacity: 1;
}

.woocommerce-cart-form table.shop_table {
  border: none;
}

table.shop_table thead {
  background: #f3f3f3;
  border: none;
}

a.remove {
  font-size: 16px;
}

.woocommerce-cart-form .cart .product-name a {
  color: #404040;
  font-size: 16px;
}

.woocommerce-cart-form .cart .product-name a:hover {
  color: #e8650d;
}

.woocommerce-cart-form .cart thead th {
  font-size: 16px;
  font-weight: 600;
  padding: 20px 12px;
}

.woocommerce-cart-form .cart .actions {
  padding: 20px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#content table.cart td.actions .input-text,
table.cart td.actions .input-text,
.woocommerce-page #content table.cart td.actions .input-text,
.woocommerce-page table.cart td.actions .input-text {
  width: 140px;
  border-radius: 0;
}

button.button {
  background-color: #000;
  color: #fff;
  border-radius: 0;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

button.button:hover {
  background-color: #e8650d;
  color: #FFF;
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
  background-color: #000;
  padding: 15px 30px;
  display: inline-block;
  font-size: 16px;
  border-radius: 0;
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover {
  background-color: #e8650d;
}

.wc-proceed-to-checkout {
  text-align: right;
}

.cart_totals h2 {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.cart-collaterals .cart_totals table.shop_table th {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 400;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 400;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.cart-collaterals .cart_totals table.shop_table th:nth-child(2) {
  border-top: none;
  border-bottom: none;
}

table.shop_table th {
  border: none !important;
}

table.shop_table td {
  border-color: #e7e7e7 !important;
}

.woocommerce-cart-form .cart .product-remove a.remove:after {
  position: absolute;
  visibility: visible;
  content: "\e870";
  top: 0;
  left: 0;
  font-family: 'Linearicons-Free';
  font-size: 16px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

table.wishlist_table tbody .product-thumbnail,
.woocommerce-cart table.cart.shop_table .product-thumbnail {
  width: 150px;
  text-align: center;
}

.woocommerce-cart-form .cart tbody .product-thumbnail {
  padding: 20px 0;
}

.woocommerce-cart-form .cart tbody .product-thumbnail img {
  width: 90px;
}

.woocommerce-cart-form .cart .product-remove a.remove {
  position: relative;
  visibility: collapse;
}

.woocommerce-cart-form .cart .coupon input.input-text, .checkout_coupon input.input-text {
  min-width: 200px;
  border: 1px solid #eee !important;
  font-size: 16px;
  border-radius: 0;
  height: 40px;
  line-height: 40px;
  padding: 0 10px !important;
}

.checkout_coupon button[type="submit"],
.woocommerce-cart-form .cart .coupon button[type="submit"] {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0;
  background-color: #222;
  color: #FFF;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.woocommerce-cart-form .cart .product-name a {
  color: #404040;
  font-size: 16px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.woocommerce-cart .cart-collaterals .cart_totals tbody {
  border-collapse: collapse;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#respond input#submit {
  background-color: #000;
  color: #FFF;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

#respond input#submit:hover {
  background-color: #e8650d;
  color: #fff;
}

.cart_totals table.shop_table th {
  border: 1px solid #e7e7e7 !important;
}

.cart-collaterals .cart_totals table.shop_table {
  border-radius: 0;
}

.wc-proceed-to-checkout a.checkout-button {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  width: auto;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  background: #222222;
  color: #FFF;
  border-radius: 0;
  float: right;
}

button.button:disabled,
button.button:disabled[disabled] {
  font-weight: 400;
}

.sm-cart-btn-wrap p {
  margin: 0;
}

p.stars a {
  color: #FFBC6B;
}

/*scroll*/
/* width */
/*.widget.widget_shopping_cart::-webkit-scrollbar{
    width: 2px;
}
*/
.widget.widget_shopping_cart::-webkit-scrollbar {
  width: 4px;
  background-color: #e8650d;
}

.widget.widget_shopping_cart::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.widget.widget_shopping_cart::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.shop_table.cart.wishlist_table th {
  border: none;
}

table.wishlist_table tbody .product-thumbnail {
  padding: 20px 0;
}

table.wishlist_table .product-name a.yith-wcqv-button {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 0;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  margin-left: 20px;
  color: #000;
  background-color: #f3f3f3;
}

table.wishlist_table .product-name a.yith-wcqv-button:hover {
  background-color: #000;
  color: #FFF;
}

.wishlist_table tr td.product-thumbnail,
.wishlist_table tr td.product-name,
.wishlist_table tr td.wishlist-name,
.wishlist_table tr td.wishlist-privacy,
table.wishlist_table tfoot td {
  text-align: left;
}

table.wishlist_table .product-name a {
  color: #404040;
  font-size: 16px;
}

table.wishlist_table .product-name a:hover {
  color: #e8650d;
}

table.wishlist_table tbody .product-stock-status {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #6ca288;
}

a.button.alt {
  background-color: #000;
}

a.button.alt:hover {
  background-color: #e8650d;
}

table.shop_table.wishlist_table {
  border: none;
}

table.shop_table.cart.wishlist_table {
  border-collapse: collapse;
}

/*my account*/
nav.woocommerce-MyAccount-navigation {
  border: 1px solid #dddddd;
  padding: 10px 0;
}

.woocommerce-MyAccount-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0 20px;
}

.woocommerce-MyAccount-navigation ul li {
  border-bottom: 1px solid #ddd;
  padding: 10px 0px;
}

.woocommerce-MyAccount-navigation ul li a {
  color: #333;
}

.woocommerce-MyAccount-navigation ul li a:hover {
  color: #e8650d;
}

.woocommerce-MyAccount-navigation ul li:last-child {
  border: none;
  font-size: 16px;
  font-weight: 500;
}

.sml-single-page-wrapp .entry-content {
  border: none;
}

.woocommerce-MyAccount-content a {
  color: #e8650d;
}

.sml-single-page-wrapp .woocommerce {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

a.button {
  background-color: #000;
  color: #FFF;
}

a.button:hover {
  background-color: #e8650d;
  color: #FFF;
}

a.edit {
  background-color: #000;
  color: #fff;
  font-size: 16px;
  padding: 5px 30px;
  font-weight: 500;
}

a.edit:hover {
  background-color: #e8650d;
}

.logged-in-as a {
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.logged-in-as a:hover {
  color: #e8650d;
}

.form-submit input#submit {
  border-radius: 0;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}

.form-submit input#submit:hover {
  background-color: #e8650d;
  color: #fff;
}

.wc_payment_method.payment_method_ppec_paypal img {
  width: 50px;
  height: 50px;
}

form.woocommerce-cart-form {
  width: 100%;
}

.woocommerce-account .woocommerce-MyAccount-content {
  padding-left: 40px;
}

/*view*/
.store-mart-lite-banner-wrapper .owl-dots .owl-dot span {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  margin: 0px 5px;
  border-radius: 50%;
  border: solid 1px transparent;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.owl-dots .owl-dot span {
  background-color: #d2e0e1;
}

.owl-dots .owl-dot.active span:hover {
  background-color: #e8650d;
}

.owl-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 99999999;
  display: block;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.owl-dots button.owl-dot.active span, .owl-dots .owl-dot:hover span {
  background-color: #e8650d;
}

button.owl-dot.active {
  display: inline-block;
}

form.login {
  width: 100%;
}

.form-row .woocommerce-Button.button {
  background: transparent;
  color: #000;
  border: 2px solid #ddd;
  padding: 0 35px;
  height: 45px;
  border-radius: 3px;
}

.form-row .woocommerce-Button.button:hover {
  background-color: #000;
}

form .form-row input.input-text {
  height: 45px;
  border-radius: 0;
  padding: 0 10px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
  height: 45px;
}

label.woocommerce-form__label.woocommerce-form__label-for-checkbox.inline {
  margin-left: 20px;
}

.woocommerce-LostPassword.lost_password a {
  color: #e8650d;
}

.woocommerce-LostPassword.lost_password a:hover {
  color: #000;
}

.woocommerce-MyAccount-content a.button {
  background-color: transparent;
  color: #333;
}

.woocommerce-MyAccount-content a.button:hover {
  background-color: transparent;
  color: #e8650d;
}

#respond input#submit.loading::after,
a.button.loading::after,
button.button.loading::after,
input.button.loading::after {
  top: 0;
}

.wpcf7-form input,
.wpcf7-form textarea {
  border-radius: 0;
  height: 45px;
  padding: 0 10px;
  width: 100%;
  border-color: #eee;
}

.sml-ct-form-wrap {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.wpcf7-form-control-wrap.your-name {
  width: 48%;
}

.wpcf7-form-control-wrap.your-email {
  width: 48%;
}

.panel-grid.panel-no-style {
  padding-top: 60px;
}

.panel-grid-cell input.wpcf7-form-control.wpcf7-submit {
  margin-top: 15px;
  background-color: #000;
  color: #FFF;
}

.panel-grid-cell input.wpcf7-form-control.wpcf7-submit:hover {
  background-color: #444;
}

h2.sml-ct-get-in-touch {
  font-size: 26px;
  color: #333;
  font-weight: 700;
  padding-bottom: 15px;
}

h2.sml-ct-details,
h2.sml-ct-op-hours {
  font-size: 16px;
  font-weight: 600;
}

.panel-layout {
  margin-top: -20px;
}

.store-mart-lite-section-footer-wrap-main {
  padding-top: 65px;
}

.yith-wcqv-main .single-product {
  padding: 40px;
}

#yith-quick-view-content .onsale {
  top: -12px;
  right: auto;
  left: 25px;
}

.yith-wcqv-main h1.product_title.entry-title {
  font-size: 30px;
  font-weight: 500;
  color: #333;
  margin-bottom: 15px;
}

.yith-wcqv-main del .woocommerce-Price-amount.amount {
  color: #e8650d;
  font-size: 24px;
}

.yith-wcqv-main ins .woocommerce-Price-amount.amount {
  color: #000;
  font-size: 32px;
  font-weight: 600;
}

.yith-wcqv-main .woocommerce-product-details__short-description {
  display: block;
  font-size: 16px;
  color: #7d7d7d;
  line-height: 1.7;
}

.single-meta-wrapp {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.wpcf7-form textarea {
  height: 250px;
  padding-top: 10px;
  resize: none;
}

textarea:focus,
input:focus {
  outline: none;
}

.wpcf7-form input::-webkit-input-placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form input::-moz-placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form input:-ms-input-placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form input::-ms-input-placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form input::placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form textarea::-webkit-input-placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form textarea::-moz-placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form textarea:-ms-input-placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form textarea::-ms-input-placeholder {
  font-size: 13px;
  font-weight: 400px;
}

.wpcf7-form textarea::placeholder {
  font-size: 13px;
  font-weight: 400px;
}

@-moz-document url-prefix() {
  span.onsale::after {
    left: -15px;
    top: -1.5px;
  }
  .sml-single-cart-wrapp .compare-wrap a::before {
    top: 17px;
  }
  .sml-single-cart-wrapp a.compare span {
    bottom: 35%;
  }
}

.store-mart-lite-bread-home .img-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 0;
  top: 0;
  left: 0;
}

button.menu-toggle {
  background-color: #e8650d;
}

.single-tag-wrapp span.tags-links a {
  margin: 0 2px;
}

.single-tag-wrapp span.tag-title {
  font-weight: 600;
}

.woocommerce-cart-form .cart thead th.product-remove {
  width: 45px;
}

.site-title a {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  color: #333;
}

p.site-title {
  margin-bottom: 0;
}

.next-text {
  text-align: right;
}

.comment-metadata span.edit-link {
  margin-top: 15px;
  display: inline;
}

img.attachment-thumbnail.size-thumbnail.wp-post-image {
  width: 100%;
  height: 100%;
}

img.wp-image-907 {
  max-width: 100%;
}

.widget_nav_menu ul.sub-menu {
  padding-left: 0;
}

.both-sidebar-enabled .widget-area .widget,
.both-sidebar .widget-area .widget {
  padding-left: 5px;
}

h1.entry-title {
  margin: 0;
  font-size: 28px;
  font-weight: 500;
}

.sml-blog-ct-main-wp h1.entry-title {
  margin-bottom: 20px;
}

.widget_shopping_cart_content h4.item-title {
  margin: 0;
}

/*======================================new css==================================================*/
/*==============================head two============================================*/
.header-two .store-mart-lite-logos {
  padding: 0 10px;
}

.header-two .site-branding {
  max-width: 250px;
}

.header-two .site-branding img {
  width: 140px;
}

.header-two .store-mart-lite-header-wrap {
  display: inline-block;
  width: calc(100% - 500px);
  width: -moz-calc(100% - 500px);
  padding: 0 5%;
}

.header-two .store-mart-lite-product-cat {
  padding: 0;
}

.header-two .store-mart-lite-header-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 25px 0 0 0;
  text-align: center;
}

.header-two .top-header-left-text {
  color: #333333;
}

.header-two .top-header-left-text {
  padding: 0;
}

.header-two .menu-primary-menu-container {
  text-align: center;
}

.header-two .search-outer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header-two .search-form-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  padding: 22px 90px;
  background: #FFF;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.header-two .search-form-wrap.search-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.075);
}

.header-two .store-mart-lite-header-icons a {
  display: inline-block;
  margin-left: 15px;
  font-size: 15px;
  color: #666666;
}

.header-two .store-mart-lite-sc-icons {
  padding-top: 0px;
  padding-left: 0px;
}

.header-two .store-mart-lite-login-wrap {
  margin-right: 0px;
}

.header-two .user-logout-wrap.sm-icon-header a,
.header-two .sm-wishlist-wrap.sm-icon-header a.sm-wishlist-ct-class,
.header-two .sm-cart-icon-wrap span.icon,
.header-two .sml-search-icon-wrap span.sml-search-icon {
  font-weight: 400;
}

.header-two .main-navigation .menu-primary-menu-container .menu.nav-menu li {
  padding: 20px 0;
}

.header-two .main-navigation .menu-item-has-children::after {
  bottom: 20px;
}

.header-two .main-navigation .menu-item-has-children .sub-menu .menu-item-has-children::after {
  bottom: 0px;
}

.header-two .main-navigation .menu-item-has-children .sub-menu::after {
  bottom: 0px;
}

.header-two .sml-search-icon,
.header-two .user-logout-wrap.sm-icon-header a,
.header-two .sm-wishlist-wrap.sm-icon-header a.sm-wishlist-ct-class,
.header-two .sm-cart-icon-wrap span.icon {
  display: block;
  font-size: 18px;
  background: #EEF1F8;
  color: #404040;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  padding-left: 0px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: background 0.3s, color 0.3s;
  transition: background 0.3s, color 0.3s;
}

.header-two .sml-search-icon-wrap {
  margin-right: 5px;
}

.header-two .user-logout-wrap.sm-icon-header {
  margin: 0 5px;
}

.header-two .sm-wishlist-wrap.sm-icon-header {
  margin: 0 5px;
}

.header-two .cart-icon-wrap {
  margin-left: 5px;
}

.header-two span.sm-cart-wrap {
  display: none;
}

.header-two .cart-icon-wrap ul {
  padding: 0;
}

.header-two .sm-cart-icon-wrap span.sm-cart-count {
  bottom: 25px;
  position: absolute;
  left: 30px;
  background-color: #e8650d;
  color: #fff;
  border-radius: 50%;
  font-size: 11px;
  height: 15px;
  width: 15px;
  text-align: center;
  line-height: 1.5;
}

.header-two span.wishlist-counter {
  position: absolute;
  top: 15px;
  left: 30px;
  font-size: 12px;
  background-color: #e8650d;
  border-radius: 50%;
  color: #fff;
  height: 15px;
  width: 15px;
  text-align: center;
  line-height: 15px;
}

.header-two .sml-search-icon:hover,
.header-two .user-logout-wrap.sm-icon-header a:hover,
.header-two .sm-wishlist-wrap.sm-icon-header a.sm-wishlist-ct-class:hover {
  background: #e8650d;
  color: #fff;
}

.header-two .sm-cart-icon-wrap span.icon span.lnr-cart {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.header-two .sm-cart-icon-wrap span.icon:hover span.lnr-cart {
  color: #fff;
}

.header-two span.sm-cart-icon-wrap {
  padding-right: 0;
}

.header-two .sm-wishlist-wrap.sm-icon-header span.wishlist-counter,
.header-two .sm-cart-icon-wrap span.sm-cart-count {
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.header-two .sm-wishlist-wrap.sm-icon-header:hover span.wishlist-counter,
.header-two .sm-cart-icon-wrap:hover span.sm-cart-count {
  background-color: #FFF;
  color: #e8650d;
}

.header-two .main-navigation .menu-primary-menu-container .menu.nav-menu .sub-menu li {
  padding: 3px 0;
  text-align: left;
  display: block;
}

.header-two .main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu {
  top: 50px;
}

.header-two .user-logout-wrap.sm-icon-header span {
  top: 40px;
}

.header-two .wishlist-dropdown.product_list_widget {
  top: 40px;
}

.header-two .search-form-wrap h3 {
  font-size: 42px;
  margin: 0;
  font-weight: 500;
  width: 150px;
}

.header-two .search-form-wrap form.search-form {
  width: 60%;
  position: relative;
  height: 55px;
  line-height: 55px;
}

.header-two .search-form-wrap form.search-form input[type="search"] {
  width: 640px;
  height: 55px;
  line-height: 55px;
  border: 1px solid #e5e5e5;
  color: #8a929a;
  border-radius: 0;
  padding: 0 20px;
  font-size: 18px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.header-two .search-form-wrap form.search-form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  height: 55px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.header-two .search-form-wrap form.search-form:after {
  content: "\f002";
  font-family: 'fontawesome';
  color: #FFF;
  font-size: 20px;
  height: 55px;
  width: 55px;
  position: absolute;
  top: 0;
  right: 0;
  background: #e8650d;
  line-height: 55px;
  text-align: center;
}

.header-two .search-form-wrap a.btn-hide {
  width: 150px;
  text-align: right;
}

.header-two .search-form-wrap a.btn-hide i {
  font-size: 45px;
  font-weight: 600;
  color: #000;
}

.header-two .store-mart-lite-header-icons a:hover {
  color: #e8650d;
}

.header-two .search-form-wrap form.search-form input[type="search"]:focus {
  border-color: rgba(62, 178, 145, 0.3);
}

.header-two .main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu ul.sub-menu {
  left: 170px;
  top: 10px;
}

.plx_blog_section .section-title-sub-wrap {
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 40px;
}

.plx_blog_section .section-title-sub-wrap h2 {
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 400;
  text-transform: uppercase;
  color: #989898;
  margin: 0;
}

.plx_blog_section .section-title-sub-wrap h5 {
  color: #979797;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0;
  font-size: 16px;
  margin-bottom: 5px;
}

h3.blog-subtitle {
  margin: 0;
}

.plx_blog_section .store-mart-lite-blog-content {
  display: block;
  width: 100%;
}

.plx_blog_section .blog-inner-content {
  width: 33.33%;
  float: left;
  padding: 0 10px 0 10px;
}

.plx_blog_section .blog-inner-content:nth-child(3) {
  margin-right: 0;
}

.plx_blog_section .store-mart-blogs.layout-two .blog-wrapp {
  display: block;
  margin-top: 20px;
  padding: 5px 10px 5px 25px;
  border-left: 1px solid #eee;
}

.plx_blog_section .blog-date-inner {
  display: block;
  height: 70px;
  width: 70px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-flow: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column;
  margin: 0 auto;
  background: transparent !important;
  height: auto;
  width: 100%;
  text-align: left;
  -ms-flex-flow: unset;
  flex-flow: unset;
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.plx_blog_section .blog-date-inner .posted-day {
  font-size: 36px;
  display: inline-block;
  padding: 5px 15px 5px 0;
  border-right: 1px solid #e8650d;
  font-weight: 500;
  margin-right: 15px;
  color: #e8650d;
  line-height: 1;
}

.plx_blog_section .blog-date-inner .ym-wrapp {
  color: #e8650d;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
}

.plx_blog_section .blog-date-inner .posted-month,
.plx_blog_section .blog-date-inner .posted-year {
  display: block;
  line-height: 1;
}

.plx_blog_section .blog-date-inner .posted-month {
  margin-bottom: 2px;
  text-transform: capitalize;
}

.plx_blog_section .blog-inner-content .blog-title {
  font-size: 20px;
  line-height: 26px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}

.plx_blog_section .blog-inner-content .blog-title a {
  color: #222;
  -webkit-transition: all ease 0.35s;
  transition: all ease 0.35s;
}

.plx_blog_section .blog-inner-content .blog-title a:hover {
  color: #e8650d;
}

.plx_blog_section .blog-inner-content .blog-excerpt {
  font-size: 16px;
  color: #535353;
  line-height: 20px;
}

.plx_blog_section .blog-inner-content-wrapper {
  display: block;
  padding: 5px 10px 5px 25px;
  border-left: 1px solid #eee;
}

.plx_blog_section .blog-img-wrapper img {
  height: 100%;
  width: 100%;
  vertical-align: top;
}

.plx_blog_section .content-read-more {
  display: block;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 15px;
}

.plx_blog_section .content-read-more a {
  color: #666;
  line-height: 1;
  display: inline-block;
  position: relative;
  -webkit-transition: all ease 0.35s;
  transition: all ease 0.35s;
}

.plx_blog_section .content-read-more a:hover {
  color: #e8650d;
}

.plx_blog_section .content-read-more a:after {
  content: "\f105";
  font-size: 18px;
  position: absolute;
  margin-top: 0px;
  color: #000;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(12px, -50%) translateZ(0);
  transform: translate(12px, -50%) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: fontawesome;
  -webkit-transition: color 0.2s, transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition: color 0.2s, -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: color 0.2s, -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: color 0.2s, transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: color 0.2s, transform 0.4s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.plx_blog_section .content-read-more a:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translate(16px, -50%) translateZ(0);
  transform: translate(16px, -50%) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left: 100%;
  width: 0;
  height: 1px;
  background: #000;
  -webkit-transition: background 0.2s, width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: background 0.2s, width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.plx_blog_section .content-read-more a:hover:before {
  width: 20px;
  background: #e8650d;
}

.plx_blog_section .content-read-more a:hover:after {
  color: #e8650d;
  -webkit-transform: translate(32px, -50%);
  transform: translate(32px, -50%);
}

.sml-blog-wrapp span.posted-year {
  display: none;
}

.comment {
  padding-top: 6px;
  color: #222;
  font-size: 16px;
  font-weight: 400;
}

.author-wrapp a:hover {
  color: #e8650d;
}

.sml-single-thumb-wrapp span.posted-year {
  display: none;
}

.single-tag-wrapp {
  padding: 30px 0;
}

span.required {
  color: red;
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 30px;
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a {
  background: transparent !important;
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a span.normal {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  color: #333;
  background-color: rgba(38, 38, 38, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.13);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a span.hover {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  background: #e8650d;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a span.btn-normal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: #333;
  padding: 20px 40px;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a span.btn-hover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 20px 40px;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
  transition: all 0.3s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a span.btn-hover span.btn-label {
  color: #FFF;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a:hover span.normal {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a:hover span.hover {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a:hover span.btn-normal {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.sml-blog-wrapp .content-wrapp-outer .sm-read-more a:hover span.btn-hover {
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

textarea#comment:focus {
  border-color: #262626;
}

input#author:focus,
input#email:focus {
  border-color: #262626;
}

input#url:focus {
  border-color: #262626;
}

.store-mart-lite-logo-wrapper {
  position: relative;
}

.store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-prev,
.store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  visibility: collapse;
  height: 40px;
  width: 40px;
}

.store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-prev:after,
.store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-next:after {
  position: absolute;
  font-family: 'Linearicons-Free';
  font-size: 28px;
  font-weight: 600;
  top: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: #aaa;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all ease 0.35s;
  transition: all ease 0.35s;
}

.store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-prev:after {
  content: "\e875";
  left: 0px;
}

.store-mart-logo-slider.owl-carousel .owl-nav .owl-next:after {
  content: "\e876";
  right: 0px;
}

.store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-prev {
  left: 0;
}

.store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-next {
  right: 0;
}

.store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-next:after {
  content: "\e876";
  left: 0px;
}

.store-mart-lite-logo-wrapper.owl-carousel:hover .owl-nav .owl-prev:after,
.store-mart-lite-logo-wrapper.owl-carousel:hover .owl-nav .owl-next:after {
  visibility: visible;
  opacity: 1;
}

.store-mart-lite-logo-wrapper .owl-item img {
  height: auto !important;
  width: auto !important;
  margin: 0 auto;
  opacity: 0.5;
  -webkit-filter: contrast(200%);
  filter: contrast(200%);
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.store-mart-lite-logo-wrapper .owl-item img:hover {
  opacity: 1;
}

.plx_client_section {
  margin: 60px 0;
}

/*..........................................plx_pro_cat_slider_section ..................................................*/
.plx_pro_cat_slider_section {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #ebeae8;
}

.store-mart-lite-product-slider-title-wrap {
  text-align: center;
  margin-bottom: 40px;
}

.plx_pro_cat_slider_section .product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #FFF;
  padding: 15px;
  width: 100%;
}

.plx_pro_cat_slider_section .item-info-wrapp {
  width: 60%;
  padding-left: 19px;
  font-size: 16px;
}

.plx_pro_cat_slider_section .item-img {
  width: 40%;
}

.plx_pro_cat_slider_section .item-first-wrapper {
  margin: 7px;
  margin-bottom: 16px;
}

.plx_pro_cat_slider_section .sml-products .product .item-info-wrapp h2 a {
  color: #222;
  font-size: 16px;
  color: #222;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 12px 0;
  display: block;
  line-height: 1.5;
}

.plx_pro_cat_slider_section h2.woocommerce-loop-product__title {
  line-height: 1;
  margin: 0;
}

.plx_pro_cat_slider_section .product span.price {
  font-size: 16px;
  color: #333;
  display: block;
  margin: 12px 0 17px;
  font-weight: 600;
}

.plx_pro_cat_slider_section .sml-products .product .item-img img {
  height: 220px;
  -o-object-fit: cover;
  object-fit: cover;
}

.plx_pro_cat_slider_section a.add_to_cart_button,
.plx_pro_cat_slider_section a.added_to_cart {
  background: #000;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: solid 1px #000;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 20px;
  line-height: 35px;
  color: #FFF;
  white-space: nowrap;
}

.plx_pro_cat_slider_section .type-product a.add_to_cart_button:hover,
.plx_pro_cat_slider_section a.added_to_cart:hover {
  background-color: #e8650d;
  color: #fff;
  border: 1px solid #e8650d;
}

.plx_pro_cat_slider_section ul.slick-dots {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

.plx_pro_cat_slider_section .slick-dots button {
  height: 10px;
  width: 10px;
  overflow: hidden;
  padding: 0;
  border-radius: 50%;
  border: none;
  background: rgba(158, 130, 97, 0.4);
  font-size: 0;
}

.plx_pro_cat_slider_section .slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.plx_pro_cat_slider_section li.slick-active button {
  background: #e8650d;
}

.plx_pro_cat_slider_section .sml-products .product .item-info-wrapp a.button.add_to_cart_button.added {
  display: none;
}

.plx_pro_cat_slider_section .sml-products .product .item-info-wrapp a.button.add_to_cart_button.loading {
  padding-right: 35px;
}

.browse-category-wrap .categorylist ul li:first-child a:after {
  top: 3px;
}

.browse-category-wrap .categorylist ul li a:after {
  content: "\e876";
  position: absolute;
  top: 17px;
  left: 0;
  font-size: 10px;
  font-weight: 600;
  font-family: 'Linearicons-Free';
}

.browse-category-wrap .categorylist ul li a {
  position: relative;
}

.header-two .store-mart-lite-product-cat {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header-two .sm-cart-icon-wrap span.icon:hover i {
  color: #FFF;
}

.plx_prod_tab_cat_section .store-mart-lite-prod-tab-title-wrap {
  text-align: center;
}

.plx_prod_tab_cat_section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.plx_prod_tab_cat_section .pro-tab-title {
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 400;
  text-transform: uppercase;
  color: #989898;
  margin: 0;
  margin-bottom: 5px;
}

.plx_prod_tab_cat_section .pro-tab-subtitle {
  font-size: 24px;
  color: #222;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0;
  margin-bottom: 40px;
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper {
  text-align: center;
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper a.pwtb-active {
  margin: 10px 15px;
  font-size: 16px;
  color: #222;
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper a:before {
  content: "";
  position: absolute;
  top: calc(100% + 5px);
  top: -moz-calc(100% + 5px);
  left: 0;
  height: 2px;
  width: 0;
  background: #e8650d;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper a.pwtb-active:before {
  width: 100%;
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper a {
  display: inline-block;
  margin: 10px 15px;
  text-transform: uppercase;
  color: #777;
  font-weight: 500;
  position: relative;
  font-size: 16px;
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper a:hover:before {
  width: 100%;
}

.plx_prod_tab_cat_section .ajax-loader.disabled {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  z-index: -1;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.ajax-loader.disabled.enabled {
  visibility: visible;
  opacity: 1;
  z-index: 99;
}

.pwtb-catposts-wrapper {
  position: relative;
  margin: 0 -10px;
}

.plx_prod_tab_cat_section ul.products.columns-4 li.product {
  width: 24.8125%;
  margin-right: 0.25%;
}

.plx_prod_tab_cat_section ul.products.columns-4 li.product:nth-child(4n) {
  margin-right: 0;
}

.plx_prod_tab_cat_section ul.products li.product {
  margin-bottom: 15px;
  padding: 10px;
  margin-right: 0.25%;
  margin-bottom: 0;
  overflow: hidden;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper:after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  margin-top: -4px;
  background: #efefef;
  z-index: -1;
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper {
  position: relative;
  margin-bottom: 15px;
}

/*.plx_prod_tab_cat_section .products li.product:hover {
    -webkit-box-shadow: 0 3px 20px rgba(0,0,0,0.2), 0 0px 15px rgba(0,0,0,0.05);
    box-shadow: 0 3px 20px rgba(0,0,0,0.2), 0 0px 15px rgba(0,0,0,0.05);
}*/
.store-mart-lite-cat-prod-btn {
  margin-top: 10px;
  display: block;
}

.store-mart-lite-cat-prod-btn a {
  display: inline-block;
  font-size: 16px;
  line-height: 1.65;
  font-weight: 500;
  color: #23232c;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: all ease 0.7s;
  transition: all ease 0.7s;
}

/*.store-mart-lite-prod-cat-wrapper-two:before,
 .store-mart-lite-prod-cat-wrapper-two:after {
    background: #ffffff;
    width: 250%;
    height: 200%;
    position: absolute;
    content: '';
    opacity: 0.5;
    -webkit-transition: all .55s cubic-bezier(.77,0,.175,1);
    transition: all .55s cubic-bezier(.77,0,.175,1);
    z-index: 1;
}
.store-mart-lite-prod-cat-wrapper-two:after {
    top: 0;
    left: 0;
    -webkit-transform: skew(-45deg) translateX(-170%);
    transform: skew(-45deg) translateX(-170%);
}
.store-mart-lite-prod-cat-wrapper-two:before {
    right: 0;
    bottom: 0;
    -webkit-transform: skew(-45deg) translateX(150%);
    transform: skew(-45deg) translateX(150%);
}
.store-mart-lite-prod-cat-wrapper-two:hover:before,
 .store-mart-lite-prod-cat-wrapper-two:hover:after {
    -webkit-transform: skew(-45deg) translateX(-60%);
    transform: skew(-45deg) translateX(-60%);
}
.store-mart-lite-prod-cat-wrapper-one:before,
 .store-mart-lite-prod-cat-wrapper-one:after {
    background: #ffffff;
    width: 250%;
    height: 200%;
    position: absolute;
    content: '';
    opacity: 0.5;
    -webkit-transition: all .55s cubic-bezier(.77,0,.175,1);
    transition: all .55s cubic-bezier(.77,0,.175,1);
    z-index: 1;
}
.store-mart-lite-prod-cat-wrapper-one:after {
    top: 0;
    left: 0;
    -webkit-transform: skew(-45deg) translateX(-170%);
    transform: skew(-45deg) translateX(-170%);
}
.store-mart-lite-prod-cat-wrapper-one:before {
    right: 0;
    bottom: 0;
    -webkit-transform: skew(-45deg) translateX(150%);
    transform: skew(-45deg) translateX(150%);
}
.store-mart-lite-prod-cat-wrapper-one:hover:before,
 .store-mart-lite-prod-cat-wrapper-one:hover:after {
    -webkit-transform: skew(-45deg) translateX(-60%);
    transform: skew(-45deg) translateX(-60%);
}
.store-mart-lite-prod-cat-wrapper-three:before,
 .store-mart-lite-prod-cat-wrapper-three:after {
    background: #ffffff;
    width: 250%;
    height: 200%;
    position: absolute;
    content: '';
    opacity: 0.5;
    -webkit-transition: all .55s cubic-bezier(.77,0,.175,1);
    transition: all .55s cubic-bezier(.77,0,.175,1);
    z-index: 1;
}
.store-mart-lite-prod-cat-wrapper-three:after {
    top: 0;
    left: 0;
    -webkit-transform: skew(-45deg) translateX(-170%);
    transform: skew(-45deg) translateX(-170%);
}
.store-mart-lite-prod-cat-wrapper-three:before {
    right: 0;
    bottom: 0;
    -webkit-transform: skew(-45deg) translateX(150%);
    transform: skew(-45deg) translateX(150%);
}
.store-mart-lite-prod-cat-wrapper-three:hover:before,
 .store-mart-lite-prod-cat-wrapper-three:hover:after {
    -webkit-transform: skew(-45deg) translateX(-60%);
    transform: skew(-45deg) translateX(-60%);
}*/
.store-mart-lite-cat-prod-image a {
  display: block;
  height: 420px;
}

.store-mart-lite-section-footer-wrap-main ul .sub-menu {
  margin: 0;
}

.plx_blog_section h3.blog-subtitle {
  text-transform: uppercase;
  text-transform: uppercase;
  font-size: 24px;
  color: #2c2a2b;
}

.blog-date-inner.default-date .span.posted-month {
  font-size: 16px;
}

.blog-date-inner.default-date span.posted-day {
  font-size: 26px;
  margin-top: -3px;
}

.blog-date-inner.default-date span.posted-yearss {
  font-size: 16px;
  margin-top: 7px;
  display: block;
}

.blog-date-inner.default-date span.posted-month {
  margin-top: 7px;
  font-size: 11px;
  padding: 0 5px;
}

.breadcrumb-title h1.page-title {
  font-size: 30px;
  color: #121212;
}

.sml-blog-ct-main-wp .content-wrapp-inner .entry-content {
  color: #535353;
}

.store-mart-lite-logos p.site-title {
  margin: 0;
}

.store-mart-lite-logos h1.site-title {
  margin: 0;
}

.store-mart-lite-logos p.site-description {
  margin: 0;
}

.no-wocommerce .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.no-wocommerce .store-mart-lite-logos {
  width: 25%;
}

.no-wocommerce .store-mart-lite-product-cat {
  width: 75%;
}

.no-wocommerce .main-navigation {
  width: 100%;
}

form.woocommerce-product-search:after,
form.search-form:after {
  position: absolute;
  top: 0px;
  right: 0;
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 47px;
  background: #e8650d;
  color: #FFF;
  content: "\e86f";
  font-family: 'Linearicons-Free';
  z-index: 0;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

form.search-form:after {
  top: 0px;
}

form.woocommerce-product-search {
  position: relative;
}

.has-post-thumbnail .sml-blog-wrapp .blog-date {
  margin: -13px 0 20px 0;
}

.sml-single-thumb-wrapp span.posted-day {
  margin-top: 0px;
}

.sml-single-post-wrapper .entry-content {
  padding: 0;
}

.product .woocommerce-tabs ul.tabs {
  padding-left: 5px !important;
  margin: 0 !important;
}

div.product .woocommerce-tabs ul.tabs li {
  padding: 5px 0;
  margin-right: 25px;
}

div.product .woocommerce-tabs ul.tabs li:before {
  width: 100%;
  height: 5px;
  background: #000;
  border: none;
  left: 0;
  visibility: hidden;
  opacity: 0;
  bottom: -3px;
  z-index: 1;
}

div.product .woocommerce-tabs ul.tabs li.active:before {
  visibility: visible;
  opacity: 1;
}

.wc-tab h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0 10px;
}

.related.products h2 {
  margin-bottom: 30px;
  display: block;
  color: #1A1A1A;
  font-size: 24px;
  margin-top: 0px;
}

div.product .woocommerce-tabs ul.tabs::before {
  z-index: -1;
}

table.shop_attributes td {
  padding-left: 10px;
}

span.onsale {
  left: 20px;
}

form.search-form {
  position: relative;
}

div.product p.stock {
  margin-right: 5px;
}

.store-mart-lite-section-footer-wrap-main .widget_search .search-form input.search-field {
  height: 45px;
}

.store-mart-lite-section-footer-wrap-main .widget_search input.search-submit {
  height: 45px;
  width: 45px;
  position: absolute;
  top: -10px;
  right: 1px;
  margin: 0;
  z-index: 1;
  opacity: 0;
}

.store-mart-lite-section-footer-wrap-main form.search-form {
  position: relative;
  display: inline-block;
}

.blog-date-inner.default-date {
  line-height: 1;
}

.menu-primary-menu-container ul.sub-menu li {
  display: block;
  text-align: left;
}

div.product .woocommerce-tabs .panel {
  margin: 0;
}

div.product p.price del {
  font-size: 24px;
}

.mob-nav-wrapper {
  display: none;
}

.header-two .sm-cart-icon-wrap span.icon:hover {
  background: #e8650d;
}

.header-one .cart-icon-wrap:hover .sm-cart-icon-wrap span.sm-cart-count {
  color: #fff;
}

a.button.wc-forward {
  padding: 10px 10px;
  border-radius: 0;
}

.plx_cta_section .container {
  padding: 0 10px;
}

.store-mart-lite-section-footer-wrap-main .container {
  padding: 0 10px;
}

.store-mart-lite-prod-cat-slider-wrap {
  padding: 0 3px;
}

.category-blog {
  margin-bottom: 50px;
}

.category-blogs {
  margin-bottom: 50px;
}

.category-blog:last-of-type,
.category-blogs:last-of-type {
  margin-bottom: 0;
}

div.product .woocommerce-tabs ul.tabs li::after {
  display: none;
}

ul.products li.product .star-rating {
  margin-top: 5px;
}

.content-area {
  padding-left: 10px;
  padding-right: 10px;
}

.archive-header form.woocommerce-ordering {
  margin-bottom: 0;
}

#respond input#submit,
a.button,
button.button,
input.button {
  border-radius: 0;
}

.sml-archive-wrapper .content-area p:first-of-type {
  margin-top: 0;
}

.sml-archive-wrapper .content-area p:last-of-type {
  margin-bottom: 0;
}

div.product div.images .flex-control-thumbs {
  margin-top: 10px;
}

#review_form #respond p {
  margin-right: 4%;
}

a.button.wc-backward {
  padding: 10px;
  font-weight: 500;
  margin-left: 10px;
}

.woocommerce-info {
  border-top-color: #e8650d;
}

.woocommerce-info::before {
  color: #e8650d;
}

form .form-row textarea {
  border-radius: 0;
}

a.showcoupon:hover {
  color: #e8650d;
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment {
  border-radius: 0;
}

form .form-row textarea {
  padding: 10px;
}

.store-mart-lite-banner button:focus,
.plx_pro_cat_slider_section button:focus {
  outline: none;
}

.hentry {
  margin-bottom: 50px;
}

.plx_prod_tab_cat_section .pwtb-catname-wrapper a:focus {
  outline: none;
}

.item-inner-wrapp.slick-slide:focus {
  outline: none;
}

.store-mart-lite-prod-tab-cat-wrap {
  padding: 0 10px;
}

/*................................responsive...............................*/
@media screen and (min-width: 1400px) {
  .header-two .search-form-wrap form.search-form input[type="search"] {
    width: 1000px;
  }
}

@media screen and (max-width: 1366px) {
  .header-one .store-mart-lite-header-icons {
    max-width: 1140px;
  }
  .sml-blog-wrapp .content-wrapp-outer .sm-read-more a span.btn-hover {
    padding: 15px 30px;
  }
  .sml-blog-ct-main-wp .content-wrapp-inner .entry-content {
    font-size: 16px;
  }
  .container {
    max-width: 1140px;
  }
  h1.entry-title {
    margin: 0;
    font-size: 26px;
  }
  .widget-area .widget li a {
    font-size: 16px;
  }
  .widget-area h2.widget-title {
    font-size: 22px;
  }
  .sml-blog-wrapp .content-wrapp-outer .sm-read-more a span.btn-hover span.btn-label {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  /*.entry-content p {
    font-size: 16px;    
}*/
  .product_list_widget .product-title {
    font-size: 16px;
  }
  .author-wrapp a {
    white-space: nowrap;
  }
  .woocommerce-product-details__short-description ul {
    font-size: 16px;
  }
  .related.products h2 {
    margin-bottom: 20px;
  }
  .woocommerce-product-details__short-description h4 {
    font-size: 20px;
  }
  .wc-tab h2 {
    font-size: 22px;
  }
  .products h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 1200px) {
  .promo-title {
    font-size: 38px;
  }
  .caption-content {
    font-size: 38px;
  }
}

@media screen and (max-width: 1100px) {
  .store-mart-lite-promo-content-wrap {
    margin-right: 70px;
  }
}

@media screen and (max-width: 1000px) {
  .promo-title {
    margin-bottom: 10px;
  }
  .promo-subtitle {
    margin-bottom: 0px;
  }
  a.promo-price-title {
    font-size: 20px;
  }
  .promo-title {
    font-size: 32px;
  }
  .promo-subtitle {
    font-size: 16px;
  }
  .caption-content {
    font-size: 36px;
  }
}

@media screen and (max-width: 1080px) {
  .promo-title {
    font-size: 35px;
    white-space: nowrap;
  }
  .header-two .store-mart-lite-header-wrap {
    padding: 0;
  }
  .store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-prev {
    left: 20px;
  }
  .store-mart-lite-promo-content-wrap {
    margin-right: 70px;
  }
  .store-mart-lite-cat-prod-description {
    font-size: 40px;
  }
  .main-navigation .menu-primary-menu-container .menu.nav-menu a {
    padding: 3px 11px;
  }
  .header-two .store-mart-lite-logos {
    padding: 0 20px;
  }
  .store-mart-lite-cat-pro-wrap {
    padding: 0 15px;
  }
}

@media screen and (max-width: 992px) {
  .footer-widgets.col-4 {
    padding: 0 10px;
  }
  .main-navigation .menu-primary-menu-container ul > li.menu-item-has-children::after {
    top: 20px;
  }
  .cart-icon-wrap {
    margin-top: 15px;
  }
  .store-mart-lite-button.btn1 {
    margin-top: 5px;
  }
  .store-mart-lite-logo-wrapper.owl-carousel .owl-nav .owl-next {
    right: 15px;
  }
  .menu li {
    display: block;
  }
  .right-sidebar-enabled .content-area {
    width: 100%;
    padding: 15px;
  }
  .left-sidebar-enabled .content-area {
    width: 100%;
    padding: 15px;
  }
  .content-area {
    padding: 0px;
  }
  .header-one .site-branding {
    margin-left: 0;
  }
  .header-one .store-mart-lite-header-icons {
    display: none;
  }
  .cart-icon-wrap {
    margin-top: 0px;
  }
  .header-two .cart-icon-wrap {
    position: absolute;
    top: 0;
    right: 200px;
  }
  .main-navigation ul {
    display: block;
  }
  .header-two .store-mart-lite-header-icons {
    display: none;
  }
  .main-navigation {
    -ms-flex: none;
    -webkit-box-flex: 0;
    flex: none;
  }
  .main-navigation {
    position: relative;
  }
  /*.menu-primary-menu-container{
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 100;    
    background-color: #FFF;
    -webkit-transform: translateX(-200%);
       -moz-transform: translateX(-200%);
        -ms-transform: translateX(-200%);
         -o-transform: translateX(-200%);
            transform: translateX(-200%);
    padding: 0px 20px;
    -webkit-box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.075);
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.075);   
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    width: 85%;
    }*/
  /*.toggled .menu-primary-menu-container{
    	-webkit-transform: translateX(0%);
    	   -moz-transform: translateX(0%);
    	    -ms-transform: translateX(0%);
    	     -o-transform: translateX(0%);
    	        transform: translateX(0%);
    }*/
  /*.menu-primary-menu-container{
        width: 768px;
        height: 350px;
    }*/
  .menu.nav-menu li {
    padding: 11px 0;
  }
  button.menu-toggle {
    display: inline-block;
    padding: 20px 0;
    width: 50px;
    color: #fff;
    font-size: 20px;
    font-family: poppins;
    cursor: pointer;
    position: relative;
    line-height: 0;
    text-align: center;
    background-color: transparent;
    border: none;
  }
  .store-mart-lite-login-wrap {
    position: absolute;
    top: 45px;
    right: 20px;
  }
  .store-mart-lite-logos {
    position: relative;
  }
  .site-branding {
    width: 100%;
    text-align: center;
  }
  span.sm-cart-wrap {
    display: none;
  }
  .main-navigation {
    position: absolute;
    top: 40px;
    left: 0;
  }
  .browse-category-wrap {
    width: 100%;
  }
  .store-mart-lite-header-icons {
    display: none;
  }
  button.menu-toggle::after {
    position: absolute;
    content: '\f0c9';
    font-family: fontawesome;
    left: 20px;
    color: #e8650d;
  }
  .site-header {
    position: relative;
  }
  .cart-icon-wrap {
    position: absolute;
    top: 37px;
    right: 145px;
  }
  .store-mart-lite-logos {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  button.menu-toggle:focus {
    outline: none;
  }
  .browse-category-wrap {
    text-align: center;
  }
  .store-mart-lite-logos {
    padding: 20px 0;
  }
  .banner-slider-info {
    line-height: 2;
  }
  h2.caption-title {
    font-size: 13px;
  }
  .caption-content {
    font-size: 22px;
  }
  .promo-price-title::after {
    width: 60%;
  }
  .promo-title {
    font-size: 28px;
  }
  .promo-subtitle {
    font-size: 13px;
  }
  a.slider-button {
    padding: 10px 20px;
  }
  .plx_prod_tab_cat_section .woocommerce ul.products.columns-4 li.product {
    width: 50%;
    margin-right: 0%;
  }
  .store-mart-lite-feat-prod-cat-wrap .woocommerce ul.products.columns-4 li.product {
    width: 50%;
  }
  .woocommerce ul.products.columns-3 li.product {
    width: 49%;
  }
  .plx_cta_section .container {
    padding: 0 15px;
  }
  .plx_blog_section .store-mart-lite-blog-content {
    padding: 0 5px;
  }
  /*blog*/
  .widget-area h2.widget-title {
    font-size: 17px;
  }
  .both-sidebar-enabled .content-area {
    width: 100%;
    -ms-order: 1;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .both-sidebar-enabled .sidebar-left {
    width: 50%;
    -ms-order: 2;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .sidebar-left {
    padding: 20px;
  }
  .both-sidebar-enabled .sidebar-right {
    width: 50%;
  }
  .sidebar-right,
  .sidebar-left {
    padding: 15px;
  }
  .post-meta-wrapp {
    width: 100%;
  }
  .content-wrapp-outer {
    width: 100%;
    padding-left: 0px;
  }
  .sml-blog-ct-main-wp {
    padding: 50px 10px;
  }
  .both-sidebar .content-area {
    width: 100%;
    -ms-order: 1;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .both-sidebar .sidebar-left {
    width: 50%;
  }
  .both-sidebar .sidebar-right {
    width: 50%;
  }
  .plx_slider_promo_section .store-mart-lite-banner-wrapper {
    width: 100%;
  }
  .plx_slider_promo_section .store-mart-lite-container {
    width: 100%;
  }
  .store-mart-lite-footer-wrap {
    padding: 0 20px;
  }
  .store-mart-lite-login-wrap {
    position: absolute;
    top: 40px;
    right: 20px;
  }
  .store-mart-lite-logos form {
    margin-top: 10px;
  }
  .store-mart-lite-product-cat {
    padding-top: 0;
  }
  .browse-category {
    width: 525px;
    font-size: 13px;
    text-transform: uppercase;
  }
  .woocommerce .sml-shop-wrap.sml-page-wrap.sml-archive-wrapper ul.products.columns-3 li.product {
    width: 48%;
  }
  .sml-shop-wrap .site-main {
    padding-left: 15px;
    padding-right: 15px;
  }
  .widget-area h2.widget-title::after {
    top: 0px;
  }
  .comments-area {
    padding-left: 20px;
  }
  .panel-grid.panel-no-style {
    padding: 20px;
  }
  .site-footer .menu-menu-1-container .menu li {
    padding: 0;
  }
  .store-mart-lite-cat-prod-description {
    font-size: 26px;
  }
  .content-area {
    padding-left: 20px;
  }
  .sml-single-page-wrapp {
    padding: 0 20px;
  }
  .main-navigation .menu-item-has-children::after {
    top: 0;
  }
  .main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu {
    min-width: 720px;
  }
  .browse-category-wrap .categorylist {
    top: 42px;
  }
  .header-two .main-navigation {
    position: absolute;
    top: 30px;
    left: 0;
  }
  .header-two .store-mart-lite-login-wrap {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .header-two .search-form-wrap {
    left: auto;
    right: 60px;
  }
  .header-two .search-form-wrap h3 {
    font-weight: 500;
    width: 100%;
    text-align: center;
  }
  .header-two .search-form-wrap form.search-form {
    width: 100%;
  }
  .header-two .search-form-wrap form.search-form input[type="search"] {
    width: 500px;
  }
  .header-two .search-form-wrap a.btn-hide {
    position: absolute;
    top: 0;
    right: 0;
  }
  .sidebar-right,
  .sidebar-left {
    width: 100%;
  }
  .woocommerce ul.products.columns-3 li.product:nth-child(3n) {
    margin-right: 2%;
  }
  .woocommerce ul.products.columns-3 li.product:nth-child(2n) {
    margin-right: 0;
  }
  .plx_lat_prod_cat_section .border-top .sml-lat-prod-detail-wrap {
    border-top: none;
    padding-top: 0;
  }
  .header-two .store-mart-lite-logos {
    padding: 20px 0;
  }
  /*.menu-primary-menu-container {
    width: 100%;
    height: 350px;
}*/
  .right-sidebar .content-area,
  .left-sidebar .content-area {
    width: 100%;
  }
  .content-area {
    padding-left: 0px;
  }
  .header-two .site-branding {
    max-width: 100%;
  }
  .sml-blog-wrapp .content-wrapp-outer .cat-links {
    text-align: center;
  }
  .sml-blog-ct-main-wp h1.entry-title {
    text-align: center;
  }
  .sml-blog-ct-main-wp .content-wrapp-inner .entry-content {
    text-align: center;
  }
  .sml-blog-wrapp .content-wrapp-outer .sm-read-more {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .caption-content {
    font-size: 36px;
  }
  .store-mart-lite-section-footer-wrap-main .widget-area h2.widget-title {
    font-size: 16px;
  }
  .block.footer-widget-1 {
    width: 100%;
  }
  .block.footer-widget-1 {
    width: 100%;
  }
  .block.footer-widget-2 {
    width: 30.66%;
  }
  .block.footer-widget-3 {
    width: 30.66%;
  }
  .block.footer-widget-4 {
    width: 30.66%;
  }
  .header-one .main-navigation {
    text-align: left;
  }
  /*.menu-primary-menu-container {
    width: 800px;
}*/
  .store-mart-lite-prod-cat-wrapper-one:before,
  .store-mart-lite-prod-cat-wrapper-two:before,
  .store-mart-lite-prod-cat-wrapper-three:before {
    -webkit-transform: skew(-45deg) translateX(250%);
    transform: skew(-45deg) translateX(250%);
  }
  .store-mart-lite-feat-prod-cat-wrap {
    padding: 0 5px;
  }
  .store-mart-lite-prod-cat-slider-wrap {
    padding: 0px 5px;
  }
  .site-header {
    display: none;
  }
  .mob-nav-wrapper {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }
  .mob-nav-wrapper .mob-hiriz-wrapp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px;
    -webkit-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.075);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.075);
  }
  .mob-nav-wrapper .site-header-cart {
    list-style: none;
  }
  .mob-side-nav-wrapp {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background: #FFF;
    width: 100%;
    max-width: 380px;
    height: 100vh;
    overflow: auto;
    padding: 30px 20px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all ease 0.15s 0.25s;
    transition: all ease 0.15s 0.25s;
  }
  .mob-side-nav-wrapp.active {
    -webkit-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.075);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.075);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .menu-toggle {
    display: block;
  }
  .mob-nav-wrapper .lnr-menu:before {
    content: "\e871";
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
  }
  .mob-nav-wrapper .top-close-wrapp img.custom-logo {
    width: 140px;
  }
  .mob-nav-close {
    position: absolute;
    top: 50px;
    right: 15px;
  }
  .mob-nav-wrapper span.lnr.lnr-cross {
    display: block;
    height: 30px;
    width: 30px;
    font-size: 28px;
    text-align: center;
    line-height: 30px;
    font-weight: 400;
    cursor: pointer;
  }
  .mob-nav-wrapper input.search-field {
    width: 100%;
    height: 45px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 45px;
    padding: 0 55px 0 10px;
    border: 1px solid #eee;
    font-size: 16px;
    border-radius: 0;
  }
  .mob-nav-wrapper input.search-submit {
    position: absolute;
    top: 0;
    right: 0;
    height: 45px;
    width: 45px;
    margin: 0;
    font-size: 0px;
    z-index: 100;
    opacity: 0;
  }
  .mob-nav-wrapper .search-wrapp {
    margin: 20px 0;
  }
  .mob-nav-wrapper .menu {
    margin: 0;
    padding: 0;
  }
  .mob-nav-wrapper .menu li {
    display: block;
    margin: 10px 0;
  }
  .mob-side-nav-wrapp .menu-wrapp-outer .menu-primary-menu-container li a {
    color: #333;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }
  .mob-side-nav-wrapp .menu-wrapp-outer .menu-primary-menu-container li .sub-toggle:before {
    position: absolute;
    content: "\e876";
    font-family: 'Linearicons-Free';
    font-size: 16px;
    top: 0;
    left: 100%;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }
  .mob-side-nav-wrapp .menu-wrapp-outer .menu-primary-menu-container li.mob-menu-toggle > .sub-toggle:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .mob-side-nav-wrapp .menu-wrapp-outer .menu-primary-menu-container li.mob-menu-toggle > a {
    color: #e8650d;
  }
  .mob-side-nav-wrapp .menu-wrapp-outer .menu-primary-menu-container li > .sub-menu {
    margin: 0;
    padding: 0 0 0 15px;
  }
  .top-close-wrapp {
    padding-top: 20px;
  }
  .plx_lat_prod_cat_section ul.products.columns-3 {
    padding: 0 5px;
  }
  .store-mart-lite-prod-tab-cat-wrap {
    padding: 0 5px;
  }
  .woocommerce .sml-shop-wrap.sml-page-wrap.sml-archive-wrapper ul.products.columns-3 li.product:nth-child(2n) {
    margin-right: 0;
  }
  .woocommerce .sml-shop-wrap.sml-page-wrap.sml-archive-wrapper ul.products.columns-3 li.product {
    width: 49%;
  }
}

@media screen and (max-width: 900px) {
  .store-mart-lite-button.btn1 a {
    padding: 10px 20px;
  }
  .header-one .menu li {
    margin: 5px 10px;
  }
  .header-two .store-mart-lite-top-header-left {
    text-align: center;
    width: 100%;
    display: block;
  }
  .store-mart-lite-promo-content-wrap {
    margin-right: 60px;
  }
  .mob-nav-wrapper .site-header-cart {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .plx_pro_cat_slider_section .woocommerce .sml-products .product .item-info-wrapp h2 a {
    font-size: 16px;
  }
}

@media screen and (max-width: 700px) {
  .store-mart-lite-login-wrap {
    display: none;
  }
  .sml-blog-wrapp .content-wrapp-outer .entry-title a {
    color: #000000;
    white-space: normal;
    text-align: center;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .store-mart-lite-prod-cat-wrapper-one,
  .store-mart-lite-prod-cat-wrapper-two,
  .store-mart-lite-prod-cat-wrapper-three {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .plx_blog_section .blog-inner-content {
    width: 100%;
  }
  .browse-category {
    width: 100%;
  }
  .sm_search_form input[type="text"] {
    width: 100%;
  }
  .store-mart-lite-product-title-wrap {
    padding: 0 15px;
    padding-bottom: 40px;
  }
  .product-subtitle {
    font-size: 22px;
  }
  .store-mart-lite-prod-cat-wrapper-one,
  .store-mart-lite-prod-cat-wrapper-two,
  .store-mart-lite-prod-cat-wrapper-three {
    padding: 0;
  }
  p.comment-form-url {
    width: 100%;
  }
  .plx_blog_section .blog-inner-content {
    padding: 0 15px 30px 15px;
  }
  .plx_blog_section .store-mart-lite-blog-content {
    padding: 0;
  }
}

@media screen and (max-width: 550px) {
  .header-one .cart-icon-wrap {
    display: none;
  }
  .store-mart-lite-prod-cat-wrapper-one,
  .store-mart-lite-prod-cat-wrapper-two,
  .store-mart-lite-prod-cat-wrapper-three {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .plx_blog_section .blog-inner-content {
    width: 100%;
    margin-right: 0;
    float: none;
  }
  .woocommerce ul.products.columns-3 li.product:nth-child(3n) {
    margin-right: 0;
  }
  .woocommerce ul.products.columns-3 li.product {
    width: 100%;
    margin-right: 0;
  }
  .store-mart-lite-feat-prod-cat-wrap .woocommerce ul.products.columns-4 li.product {
    width: 100%;
    margin-right: 0;
  }
  .col-2 .block.footer-widget-1,
  .col-2 .block.footer-widget-2 {
    width: 100%;
  }
  .plx_lat_prod_cat_section .sml-lat-prod-detail-wrap {
    border-top: 1px solid #eee;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .plx_lat_prod_cat_section .sml-lat-prod-detail-wrap:first-of-type {
    border-top: none;
    padding-top: 0;
  }
  .prev-link, .next-link {
    display: inline-block;
    width: 100%;
  }
  .comments-area {
    padding-left: 0px;
  }
  p.comment-form-author {
    width: 100%;
    margin-right: 0;
  }
  p.comment-form-email {
    width: 100%;
    margin-right: 0;
  }
  .woocommerce #review_form #respond p {
    margin-right: 0;
  }
  .caption-content {
    font-size: 22px;
  }
  .caption-content {
    font-size: 22px;
  }
  .promo-title {
    font-size: 22px;
  }
  .promo-title {
    margin-bottom: 5px;
  }
  a.promo-price-title {
    font-size: 18px;
  }
  .product-subtitle {
    font-size: 20px;
  }
  .cta-title {
    font-size: 18px;
  }
  .cta-subtitle {
    font-size: 25px;
  }
  .cta-price-text {
    font-size: 22px;
  }
  .plx_blog_section .blog-inner-content .blog-title {
    font-size: 18px;
  }
  .plx_blog_section .blog-date-inner .posted-day {
    font-size: 30px;
  }
  .store-mart-lite-feat-prod-cat-wrap .woocommerce ul.products.columns-4 li.product {
    margin-bottom: 20px;
  }
  .woocommerce ul.products.columns-3 li.product {
    margin-bottom: 20px;
  }
  h2,
  h3 {
    font-size: 24px;
  }
  .breadcrumb-title h1.page-title {
    font-size: 26px;
  }
  .entry-content p {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .site-branding {
    margin-left: 0;
  }
  .header-two .store-mart-lite-login-wrap {
    margin-right: 0px;
    display: none;
  }
  .plx_slider_promo_section .store-mart-lite-banner-wrapper {
    width: 100%;
  }
  .plx_slider_promo_section .store-mart-lite-container {
    width: 100%;
  }
  .store-mart-lite-feat-prod-cat-wrap .woocommerce ul.products.columns-4 li.product {
    width: 100%;
  }
  .plx_lat_prod_cat_section li.product {
    width: 100%;
  }
  .store-mart-lite-header-icons {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .store-mart-lite-logos {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .prev-text h2 a {
    display: inline-block;
    padding-left: 20px;
  }
  p.comment-form-author {
    width: 100%;
    padding: 0;
  }
  p.comment-form-email {
    width: 100%;
    padding: 0px;
  }
  /*product*/
  .woocommerce ul.products.columns-3 li.product {
    width: 100%;
  }
  /*blog*/
  .sml-blog-wrapp .content-wrapp-inner {
    padding-left: 0px;
  }
  .both-sidebar-enabled .sidebar-left {
    width: 100%;
  }
  .both-sidebar-enabled .sidebar-right {
    width: 100%;
  }
  .content-area {
    width: 100%;
  }
  .sidebar-left {
    width: 100%;
    -ms-order: 2;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .sidebar-right {
    width: 100%;
    -ms-order: 2;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .comment-list li.comment .comment-content {
    padding-left: 20px;
    margin-top: 0px;
  }
  /*.menu-primary-menu-container{
    width: 325px;
}*/
  .sm_search_wrap {
    width: 130px;
  }
  .wishlist-dropdown.product_list_widget {
    right: -125px;
  }
  .sm_search_form input[type="text"] {
    width: 260px;
  }
  .store-mart-lite-login-wrap {
    margin-top: 10px;
  }
  .col-3 .block.footer-widget-1 {
    width: 100%;
  }
  .col-3 .block.footer-widget-2 {
    width: 100%;
  }
  .col-3 .block.footer-widget-3 {
    width: 100%;
  }
  .woocommerce span.onsale {
    left: 1.5em;
  }
  .woocommerce span.onsale::after {
    left: -13px;
  }
  .woocommerce span.onsale::before {
    left: -13px;
  }
  .single_post_pagination_wrapper .next-link h2 a::before {
    right: -20px;
  }
  .wpcf7-form-control-wrap.your-email {
    width: 100%;
    margin-top: 20px;
  }
  .wpcf7-form-control-wrap.your-name {
    width: 100%;
  }
  .left-sidebar .content-area,
  .right-sidebar .content-area {
    -ms-order: 1;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  p {
    font-size: 12px;
  }
  .lat-pro-subtitle {
    font-size: 20px;
  }
  .sml-blog-wrapp .content-wrapp-outer .entry-title a {
    font-size: 20px;
  }
  .cart-icon-wrap {
    top: 100px;
    right: 235px;
  }
  .store-mart-lite-login-wrap {
    top: 95px;
    right: 120px;
  }
  .store-mart-lite-logos form {
    margin-top: 20px;
  }
  .store-mart-lite-prod-cat-wrapper-one {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .store-mart-lite-prod-cat-wrapper-two {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .store-mart-lite-prod-cat-wrapper-three {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .browse-category {
    width: 100%;
  }
  .plx_prod_tab_cat_section .woocommerce ul.products.columns-4 li.product {
    width: 100%;
  }
  .widget.widget_shopping_cart {
    right: -225px;
  }
  .store-mart-lite-button.btn1 {
    margin-top: 15px;
  }
  .store-mart-lite-button.btn1 a {
    padding: 10px 25px;
    font-size: 13px;
  }
  .woocommerce .sml-shop-wrap.sml-page-wrap.sml-archive-wrapper ul.products.columns-3 li.product {
    width: 100%;
  }
  .sml-shop-wrap .site-main {
    padding-right: 15px;
  }
  .woocommerce-product-details__short-description {
    font-size: 13px;
  }
  .jqDnR p {
    font-size: 13px;
  }
  .site-branding img {
    width: 90%;
  }
  .main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu {
    min-width: 360px;
  }
  .plx_blog_section .blog-inner-content {
    width: 100%;
    margin-right: 0;
    float: none;
    margin-bottom: 25px;
  }
  .store-mart-lite-promo-content-wrap {
    margin-right: 40px;
  }
  .block.footer-widget-1 {
    width: 100%;
  }
  .block.footer-widget-2 {
    width: 33.33%;
  }
  .block.footer-widget-3 {
    width: 33.33%;
  }
  .block.footer-widget-4 {
    width: 33.33%;
  }
  /*.menu-primary-menu-container {
    width: 430px;
}*/
  .woocommerce ul.products.columns-3 li.product {
    margin-right: 0;
  }
  .woocommerce ul.products.columns-3 li.product:nth-child(3n) {
    margin-right: 0;
  }
  .mob-nav-wrapper .site-header-cart {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .mob-nav-wrapper img.custom-logo {
    width: 100px;
  }
  .woocommerce #content table.cart td.actions .coupon .button,
  .woocommerce #content table.cart td.actions .coupon .input-text,
  .woocommerce #content table.cart td.actions .coupon input,
  .woocommerce table.cart td.actions .coupon .button,
  .woocommerce table.cart td.actions .coupon .input-text,
  .woocommerce table.cart td.actions .coupon input,
  .woocommerce-page #content table.cart td.actions .coupon .button,
  .woocommerce-page #content table.cart td.actions .coupon .input-text,
  .woocommerce-page #content table.cart td.actions .coupon input,
  .woocommerce-page table.cart td.actions .coupon .button,
  .woocommerce-page table.cart td.actions .coupon .input-text,
  .woocommerce-page table.cart td.actions .coupon input {
    width: 100%;
    margin-bottom: 5px;
  }
  .plx_prod_tab_cat_section .pwtb-catname-wrapper a.pwtb-active {
    margin: 10px 5px;
    font-size: 13px;
  }
  .plx_prod_tab_cat_section .pwtb-catname-wrapper a {
    margin: 10px 5px;
    font-size: 13px;
  }
}

@media screen and (max-width: 375px) {
  .store-mart-lite-top-header-left {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sm_search_wrap {
    width: 100px;
  }
  .sm_search_form input[type="text"] {
    padding-right: 20px;
  }
  .sm_search_wrap select {
    font-size: 11px;
    padding-left: 2px;
  }
  .sm_search_form input[type="text"] {
    padding-right: 46px;
  }
  .sm_search_form {
    margin-left: 9px;
  }
  .sm_search_form input[type="text"] {
    width: 250px;
  }
  .main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu {
    min-width: 320px;
  }
  .block.footer-widget-4 {
    width: 100%;
  }
  .block.footer-widget-3 {
    width: 49.33%;
  }
  .block.footer-widget-2 {
    width: 48.33%;
  }
  /*.menu-primary-menu-container {
    width: 390px;
}*/
  .woocommerce ul.products.columns-3 li.product {
    margin-right: 0;
  }
  .woocommerce ul.products.columns-3 li.product:nth-child(3n) {
    margin-right: 0;
  }
  .plx_pro_cat_slider_section .item-img {
    width: 100%;
  }
  .plx_pro_cat_slider_section .item-info-wrapp {
    width: 100%;
    padding-top: 19px;
    font-size: 16px;
    padding-left: 0;
  }
}

@media screen and (max-width: 320px) {
  .sm_search_form {
    margin-left: 0px;
  }
  .sm_search_wrap:after {
    left: 100%;
  }
  .sm_search_wrap {
    width: 100px;
  }
  .store-mart-lite-cta-content-wrap {
    right: 15%;
  }
  .sm_search_wrap select {
    padding-left: 0px;
  }
  .sm_search_form input[type="text"] {
    padding-right: 0px;
  }
  .columns-3 a.link-quickview.yith-wcqv-button {
    padding: 10px 30px;
  }
  .team-members-contents:nth-child(4) {
    border-right: 1px solid #e3e3e3;
  }
  .columns-3 a.added_to_cart {
    padding: 10px 35px;
  }
  .sm_search_form input[type="text"] {
    width: 210px;
  }
  .promo-subtitle {
    font-size: 10px;
  }
  .promo-title {
    font-size: 15px;
  }
  a.promo-price-title {
    font-size: 15px;
  }
  .sml-promo-price-btn {
    margin: 0;
  }
  .store-mart-lite-button.btn1 {
    margin: 0;
  }
  .wishlist-dropdown.product_list_widget {
    right: -104px;
  }
  /*.menu-primary-menu-container {
    width: 100%;
}*/
  .store-mart-lite-login-wrap {
    right: 80px;
  }
  .cart-icon-wrap {
    right: 200px;
  }
  .site-branding img {
    width: 75%;
  }
  .store-mart-lite-button.btn1 a {
    padding: 5px 10px;
    font-size: 11px;
  }
  .main-navigation .menu-primary-menu-container ul li.menu-item-has-children > ul.sub-menu {
    min-width: 270px;
  }
  .wishlist-dropdown.product_list_widget {
    width: 315px;
  }
  .widget.widget_shopping_cart {
    right: -185px;
    min-width: 290px;
  }
  /*.menu-primary-menu-container {
    width: 310px;
}*/
  a.slider-button {
    padding: 7px 10px;
    font-size: 16px;
  }
  .caption-content {
    font-size: 30px;
  }
  .caption-content {
    margin-bottom: 10px;
  }
  .mob-side-nav-wrapp {
    max-width: 310px;
  }
}

/**
 * Owl Carousel v2.3.3
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  -webkit-transition: height .5s ease-in-out;
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  //background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform .1s ease;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease;
  transition: transform .1s ease, -webkit-transform .1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.mt-1 {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.mb-3 {
  margin-bottom: 3rem;
}

input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email],
input[type=month], input[type=number], input[type=password],
input[type=range], input[type=search], input[type=tel], input[type=text],
input[type=time], input[type=url], input[type=week], textarea {
  background: #fafafa;
  border: 1px solid #aaa;
  padding: 5px;
}

select.form-control {
  background-color: #fafafa !important;
  border: 1px solid #aaa;
}

.panel-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

p.comment-form-email, p.comment-form-author {
  width: 48%;
}

/* custom */
.store-mart-lite-cta-wrapper {
  background-image: url(https://demo.accesspressthemes.com/zigcy-lite/wp-content/uploads/2018/11/home1-large-banner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.shadow {
  text-shadow: 1px 2px 12px #000;
}

@media only screen and (min-width: 900px) {
  .store-mart-lite-banner-wrapper, .store-mart-lite-slider-promo-banner {
    max-height: 500px;
  }
  .promo-one-image {
    max-height: 250px;
  }
}

.text-white {
  color: #fff;
}

a.store-mart-cat-prod-btn.text-white {
  color: #fff;
}

a.store-mart-cat-prod-btn.text-white:hover {
  color: #e8650d;
}

a.store-mart-cat-prod-btn.text-white:hover::after {
  background-color: #e8650d;
}

a.store-mart-cat-prod-btn.text-white:after {
  background-color: #fff;
}

.font-size-larger, a.font-size-larger {
  font-size: larger;
}

.bg-light {
  background: #fafafa;
}

.box-shadow {
  -webkit-box-shadow: 1px 2px 12px #e6e6e6;
  box-shadow: 1px 2px 12px #e6e6e6;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.latest-product-image {
  width: 135px;
  height: 135px;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: 1px 2px 9px #eaeaea;
  box-shadow: 1px 2px 9px #eaeaea;
}

.latest-product-image > img {
  height: 135px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog-img-wrapper img {
  min-height: 350px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.blog-img-wrapper img:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.sml-blog-ct img {
  min-height: 320px;
  -o-object-fit: cover;
  object-fit: cover;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100% !important;
}

article.blog-article {
  width: 48%;
  margin: 5px;
}

@media only screen and (max-width: 600px) {
  article.blog-article {
    width: 100%;
  }
}

.float-left {
  float: left;
}

span.posted-cat {
  font-size: 18px;
  font-weight: 500;
  display: block;
  padding-top: 1.3rem;
  text-transform: uppercase;
}

.sml-blog-ct-main-wp {
  padding: 40px 10px;
}

.post-meta-wrapp {
  width: 100%;
}

.blog-title {
  float: left;
  width: 75%;
}

@media only screen and (max-width: 330px) {
  .blog-title {
    width: 100%;
  }
}

.blog-title > h2 {
  font-size: 27px;
  padding: 0;
  margin: 0;
}

.blog-title > h2 > a {
  color: #000;
}

.blog-date {
  width: 25%;
  float: left;
}

.sml-blog-wrapp .blog-date {
  margin: 0px 0 35px 0;
}

.cart-collaterals {
  width: 100%;
}

.cart_totals {
  width: 48%;
  float: right;
}

.product-thumbnail {
  text-align: center;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
  display: block;
}

.p-3 {
  padding: 3rem;
}

.bg-light {
  background: #f6f6f6;
}

.m-2 {
  margin: 2rem;
}

.rounded {
  border-radius: 0.25rem !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.confirm_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  color: #4a4a4a;
  padding: 3px;
  font-weight: 100;
}

.confirm_table th {
  padding: 5px;
}

.custom-checkbox > label {
  width: 90%;
  float: left;
}

.custom-checkbox > input {
  float: left;
  margin: 3px;
}

a.buy-button {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  width: auto;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  background: #222;
  color: #fff;
  border-radius: 0;
  float: right;
}

a.buy-button:hover {
  color: #fff;
  background: #e8650d;
}

.products.columns-3 {
  margin: 0 0 1em;
  padding: 0;
  list-style: none outside;
  clear: both;
}

.sml-product-wrapp img {
  min-height: 280px;
  -o-object-fit: cover;
  object-fit: cover;
}

ul.product_list_widget img {
  min-height: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  ul.products.columns-3 li.product {
    width: 100%;
  }
}

.buy-button {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  width: auto;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  background: #222;
  color: #fff;
  border-radius: 0;
  float: right;
  cursor: pointer;
}
.sml-blog-ct.pdt img{
  min-height: 150px;
  max-height: 170px;
}
.m-5{
  margin: 5px;
}