.show-more-425px{
	@media screen and (min-width: 425px) {
		display: none;
	}
}
.w-lg-50{
	@media screen and (min-width: 600px) {
		width: 50%;
	}
}
.w-sm-100{
	@media screen and (max-width: 599px) {
		width: 100%;
	}
}
.mb-4{
	margin-bottom: 4rem !important;
}
.mb-2{
	margin-bottom: 2rem !important;
}
.mt-1{
	margin-top: 1rem !important;
}
.mb-1{
	margin-bottom: 1rem !important;
}
.p-5{
	padding: 5px;
}
.variants label{
	font-weight: bold;
}
.content-area{
	width: 70%;
}
.sidebar-right, .sidebar-left{
	width: 30%;
}
.m-auto{
	margin: auto;
}
@media screen and (max-width: 899px) {
	.sidebar-right, .sidebar-left{
		width: 100%;
	}
}
.short-description{
	padding: 10px;
	& ul {
		margin: 0;
	}
}
.color-default{
	color: $colorDefault;
}
.font-weight-bold{
	font-weight: 900;
}
.sml-product-wrapp img{
	min-height: 250px;
    max-height: 250px;
    object-fit: contain;
}
.d-flex{
	display: flex;
}
.pagination > li{
	margin: 0;
    text-decoration: none;
    line-height: 1;
    font-size: 1em;
    font-weight: 400;
    padding: .5em;
    min-width: 1em;
    display: block;
    font-size: larger;
    & a{
    	color: #e8650d;
    }
    &.active{
    	background: $colorDefault;
    	color: #fff;
    	& a{
    		color: #fff;
    	}
    }
    
}
ul.product-categories{
	&  label{
		display: inline;
		padding: 0 5px 0 0;
		color: #000;
	}
}

.product-categories label{
	cursor: pointer;
	font-size: 16px;
	transition: .3s ease-in-out;
	&:hover{
		color: $colorDefault;
	}
}
.custom-control{
	position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}
.custom-control-input{
	position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-checkbox .custom-control-label::before{
	border-radius: .25rem;
	position: absolute;
    top: .25rem;
    left: -0.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #dee2e6;
    border: #adb5bd solid 1px;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0,0,0,.1);
}
.custom-control-input:checked~.custom-control-label::before{
	color: #fff;
    border-color: $colorDefault;
    background-color: $colorDefault;
    box-shadow: none;
}
.custom-control-input:checked ~ .custom-control-label::after {
   	position: absolute;
    top: .25rem;
    left: -0.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
    background-image: $custom-checkbox-indicator-icon-checked;
}
@media screen and (max-width: 600px) {
	.sidebar-left, .content-area{
		width: 100%;
	}
}
.widget{
	display: grid;
}
.widget-area .widget li{
	color: #000;
	padding-bottom: 0;
    margin-bottom: 0;
}
.widget-area .widget .product-filters ul > li {
	border-bottom: none;
	&:after{
		content: '';
		height: 1px;
	    width: 100%;
	    background: $colorDefault;
	    display: inline-block;
	    bottom: 10px;
    	position: relative;
	}
}
.widget h4{
	display: block;
	margin-top: 10px;
	font-size: 15px !important;
	color: $colorDefault;
	position: relative;
	padding-left: 15px;
	& a{
		color: #000;	
	}
	&:after{
		position: absolute;
	    background-color: #e8650d;
	    content: '';
	    width: 1px;
	    height: 100%;
	    top: 0;
	    left: 0;
	}
}
.w-25{
	width: 25% !important;
}
.w-20{
	width: 20% !important;
}
.widget-area h2.widget-title{
	margin-bottom: 15px;
	line-height: 1.3;
}
hr{
	background-color: $colorDefault;
}
.m-0{
	margin: 0;
}

.trail-items li > a:hover{
	text-decoration: underline;
}
.minicart-thumbnail{
	max-height: 100px;
}
ul.product_list_widget img.minicart-thumbnail{
	object-fit: contain !important;
}
tr.cart_item .product-thumbnail img{
	max-height: 100px;
	object-fit: contain;
}
@media screen and (max-width: 599px) {
	.cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cart_totals{
		width: 100%;
	}
	.wc-proceed-to-checkout a.button{
		width: 100%;
    	text-align: center;
	}
	.cart_totals{
		text-align: center;
	}
}
.alert{
	position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
.alert-success{
	color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert-danger{
	color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}
.alert-warning{
	color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.d-none{
	display: none;
}
.d-block{
	display: block;
}
//pagination
nav {
	display: block;
	width: 100%;
}
.pagination{
	display: flex;
    //padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    & ul {
    	margin-top: 0;
    	margin-bottom: 1rem;
    }
}
.sml-blog-ct img{
	max-height: 325px;
}
.blog-title>h2{
	font-size: 24px;
}
.entry-content{
	min-height: 50px;
}
@media (max-width: 600px) {
	table.shop_table thead {
		display: none;
	}
	table.shop_table td {
		display: block;
		text-align: center;
		&.product-subtotal{
			font-weight: bold;
		}
		&.product-remove a{
			margin: auto;
		}
		&.product-name small{
			font-size: unset;
		}
	}
}
.fs-16{
	font-size: 16px;
}
.color-default{
	color: $colorDefault;
}
.d-inline-block{
	display: inline-block;
}
.float-none{
	float: none !important;
}
.pb-5{
	padding-bottom: 5rem;
}
///spinner
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.spinner{
	text-align: center;
    padding: 40px;
    position: absolute;
    width: 100%;
}
.spinner-border{
	display: inline-block;
    width: 3rem;
    height: 3rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.sr-only{
	position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
.plx_blog_section .blog-img-wrapper img{
	max-height: 350px;
}
ol.commentlist{
	padding: 0;
    margin: 0;
    list-style: none;
}
li.review{
	margin: 0 0 10px 50px;
    border: 1px solid #e4e1e3;
    border-radius: 4px;
    padding: 20px;
    margin-left: 0;
}
.star-rating span::before{
	content: "";
    top: 0;
    position: absolute;
    left: 0;
}
.star-rating.rating-1 span::before{
	content: "\53";
}
.star-rating.rating-2 span::before{
	content: "\53\53";
}
.star-rating.rating-3 span::before{
	content: "\53\53\53";
}
.star-rating.rating-4 span::before{
	content: "\53\53\53\53";
}
.star-rating.rating-5 span::before{
	content: "\53\53\53\53\53";
}
li.review.response{
	position: relative;
	left: 20px;
}
.comment-text .badge{
	float: right;
    color: #dc9232;
    font-size: unset;
}
.confirm_table{
    color: #000;
    font-weight: 400;
}
.comments-list{
	margin: 0;
	& li.comment{
		list-style: none;
		margin-bottom: 25px;
	}
}
span.say{
	margin-left: 5px;
    font-style: italic;
    font-size: small;
}
time{
	font-size: small;
}
.comments{
	padding-top: 20px;
}

.comment-content, .comment-meta{
	& .comment-author{
		font-size: 16px;
	}
	
	& p{
		font-size: 16px;
	}
	
}
.float-right{
	float: right;
}
.btn-bcpoland{
	background: $colorDefault;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    opacity: .99;
    transition: .2s ease-in-out;
    &:hover{
    	opacity: 1;
    }
}

////disabled color
#respond input#submit.alt.disabled, #respond input#submit.alt.disabled:hover, #respond input#submit.alt:disabled, #respond input#submit.alt:disabled:hover, #respond input#submit.alt:disabled[disabled], #respond input#submit.alt:disabled[disabled]:hover, a.button.alt.disabled, a.button.alt.disabled:hover, a.button.alt:disabled, a.button.alt:disabled:hover, a.button.alt:disabled[disabled], a.button.alt:disabled[disabled]:hover, button.button.alt.disabled, button.button.alt.disabled:hover, button.button.alt:disabled, button.button.alt:disabled:hover, button.button.alt:disabled[disabled], button.button.alt:disabled[disabled]:hover, input.button.alt.disabled, input.button.alt.disabled:hover, input.button.alt:disabled, input.button.alt:disabled:hover, input.button.alt:disabled[disabled], input.button.alt:disabled[disabled]:hover{
	background: $colorDefault;
}

.comments-list li.comment.response{
	margin-left: 25px;
}
.single-content-wrapp{
	font-weight: 400;
}
.widget-area .widget.default li{
	padding-bottom: 20px;
    margin-bottom: 20px;
}
@media only screen and (max-width: 600px){
	ul.products.columns-3 li.product{
		width: 48%;
    	float: left;
	}
}
.custom-control{
	display: inline-block;
}
.orderform textarea{
	background: $lightgrey;
}
.widget-area .widget .product-filters ul>li{
	min-width: 50%;
}
.t-capitalize{
	text-transform: capitalize;
}
.container p{
	padding: 0 10px;
	font-size: 1rem;
}
.menu-toggle{
	position: relative;
	padding: .21rem 1rem .51rem 0px;
}
.menu-toggle:after{
	content: 'Menu';
    position: absolute;
    font-size: unset;
    left: 0px;
    top: 25px;
}
li.product{
	border-bottom: 1px solid;
}
.sml-product-title-wrapp{
	min-height: 6.5rem;
}
.product-related h5, span.amount{
	font-size: 14px;
}
.product-related img{
	max-height: 150px;
	height: 150px;
    object-fit: cover;
}

.description{
	overflow: hidden;
	transition: .4s ease-in-out;
	/* &.hidden{
	-webkit-mask-image: -webkit-gradient(linear, left top, 
	    left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
	} */
}
span.arrow{
	cursor: pointer;
	&:before{
		content: '';
	    width: 50%;
	    height: 2px;
	    background: $colorDefault;
	    display: block;
	    margin: auto;
	}
}
.store-mart-lite-bread-home{
	background-repeat: no-repeat;
    background-size: cover;
    background-image: url(https://bcpoland.com.pl/storage/gallery/2020/06/02/kolibri-dulka-bcpoland.jpg);
}
.widget-area a:visited{
	color: #222;
	&:hover{
		color: $colorDefault;
	}
}
.container h3 > a{
	color: $colorDefault
}
nav, h1.page-title{
	text-shadow: 1px 2px 7px #fff;
}
.p-10px-20px{
	padding: 10px 20px !important;
}
.content-area a {
	color: $colorDefault;
	font-weight: bold;
	&:hover{
		color: #000;
	}
}
@media only screen and (min-width: 600px){
	.p-horizontal-10{
		padding: 0 10%;
	}
}
.table-specs{
	margin: 20px 0;
	text-align: center;
	border: 0;
	line-height: 1.9;
	& td{
    	border: 1px solid #bbbbbb5c;
    	border-left: none;
    	border-right: none;
    	width: 50%;
	}
	& tr > td:first-child{
		font-weight: bold;
		text-align: right;
	}
	& tr > td:nth-child(2){
		text-align: left;
	}
	& tbody > tr:nth-child(odd){
		background: #f9f9f9;
	}
	& tbody > tr:hover{
		background: #e8650d66;
	}
}
.cookiesmsg{
	text-align: center;
	background: #e8650d75;
    padding: 5px 20px 10px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    font-size: small;
    color: #fff;
}
#cookiesmsgremove{
	padding: 0px 10px;
	color: #fff;
	background: $colorDefault;
	font-size: x-large;
}

//product single page
@media screen and (min-width: 768px) {
	.product{
		padding: 0 50px;
	}
	.p-md-20px{
		padding: 20px;
	}
}
.fontsize-large{
	font-size: large;
}
.product p.image{
	text-align: center;
	& > img{
		border-radius: 20px;
	}
}

#tab-description{
	@media screen and (min-width: 1020px){
		margin: 10px 0px;
	}
	@media screen and (min-width: 768px) and (max-width: 1019px) {
		margin: 10px 0px;
	}
	@media screen and (max-width: 767px){
		margin: 10px 0px;
	}
	
}
ul.products li.product a img{
	width: auto;
	height: 150px;
	border-radius: 5px;
	margin: 0 auto !important;
}
section.related_products .owl-stage{
	display: flex;
}
section.related_products .owl-item{
	margin: 10px;
	border-radius: 10px;
	padding: 10px;
	transition: .6s ease-in-out;
	&:hover{
		box-shadow: 1px 2px 7px;
	}
	& .sml-price-wrap span.amount, & .sml-price-wrap .sml-add-to-cart-wrap > a{
		font-size: 16px;
	}
	& h5.related-product-title{
		@media screen and (max-width: 500px){
			min-height: 95px;
		}
		@media screen and (min-width: 501px){
			min-height: 40px;
		}
	}
	& h5.related-product-title > a{
		font-size: 16px;
		color: #404040;
	}
	& li.product-related{
		border-bottom: 2px solid $colorDefault;
	}
}
.owl-carousel .owl-stage-outer{
	z-index: 1;
}
.owl-nav{
	display: flex;
    position: absolute;
    width: 100%;
    bottom: 50%;
    justify-content: space-between;
	& > button{
		z-index: 2;
		border-radius: 50%;
	    width: 50px;
	    height: 50px;
	    background: #f4f4f4 !important;
    	border: 1px solid #e0e0e0;
    	box-shadow: 1px 2px 10px #bcbcbc;
    	& span{
    		font-size: xx-large;
    	}
	}
} 
.related-product-title{
	text-align: center;
}
.color-black > a, .after-product li > a{
	color: #000;
	&:hover{
		color: $colorDefault;
	}
}
.after-product{
	width: 100%;
	background: #f6f6f6;
}
.store-mart-lite-section-footer-wrap-main{
	border-top: 1px solid #d3d3d3;
	background-color: #e8e8e8;
}
.col-md-w-25{
	@media screen and (min-width: 768px){
		width: 25%;
	}
}
ul.style-none{
    list-style: none;
}
.p-0{
	padding: 0;
}
.m-0-20{
	margin: 0 20px;
}
.p-0-20{
	padding: 0 20px;
}
.ml-20px{
	margin-left: 20px;
}
@media screen and (max-width: 899px){
	.content-area{
		width: 100%;
	}
}
p.comment-form-author, p.comment-form-email{
	@media screen and (max-width: 576px){
	width: 100%;
	}
}
.btn-default-outline{
	border: 2px solid $colorDefault;
    padding: 5px;
    margin: 2px;
    border-radius: 5px;
    color: $colorDefault;
}
.fs-sm{
	font-size: small;
}
span.posted_in{
	font-size: 16px;
}
ul.products li.product a img{
	object-fit: cover;
}
.sml-latest-prod-add-to-cart a {
	text-transform: capitalize;
}
.plx_prod_cat_section{
	margin: 0px 0 15px 0;
}
ul.products li.product .sml-product-wrapp a img{
	object-fit: contain;
}
.sml-product-wrapp > a img {
	object-fit: contain;
}
.mob-nav-wrapper{
	position: fixed;
    z-index: 2;
    width: 100%;
    background: #fffffff5;
}
.sm-cart-count {
	transition: .3s ease-in-out;
	&.increase{
		transform: scale(1.3);
}}
.store-mart-lite-promo-content-wrap, .store-mart-lite-cat-prod-content, .store-mart-lite-prod-cat-wrapper-one, .store-mart-lite-prod-cat-wrapper-two{
	z-index: 1;
}
.justify-content-center{
	justify-content: center;
}
///close button
.lnr.lnr-cross, .lnr.lnr-cross-after{
	display: block;
    height: 30px;
    width: 30px;
    font-size: 28px;
    text-align: center;
    line-height: 30px;
    font-weight: 400;
    cursor: pointer;
}
////mobile filter
.mob-filter-wrapp {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	background: #fff;
	width: 100%;
	max-width: 380px;
	height: 100vh;
	overflow: auto;
	padding: 0px;
	-webkit-transform: translateX(100vw);
	transform: translateX(100vw);
	-webkit-transition: all ease 0.15s 0.25s;
	transition: all ease 0.15s 0.25s;
	& .mob-nav-close{
		top: 5px;
		right: 0;
	}
	& .mob-filter-header{
		padding: 10px 10px 15px 10px;
		border-bottom: 1px solid $grey;
	}
	& .mob-filter-header > div{
		flex-direction: column;
	    display: flex;
	    justify-content: flex-end;
	    position: relative;
	}
	& .filter-wrapper{
		padding: 0px 10px;
		padding-bottom: 50px;
	}
	& ul.product-filters ul.attr-values{
		padding: 0 10px;
		li::after{
			content: none !important;
		}
	}
	span.pdt-count{
		font-size: smaller;
		color: $grey;
	}
}
.mob-filter-wrapp.active {
	-webkit-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.075);
	box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.075);
	-webkit-transform: translateX(calc(100vw - 100%));
	transform: translateX(calc(100vw - 100%));
}
.justify-content-between{
	justify-content: space-between;
}
a{
	color: $colorDefault;
}
.font-size-small{
	font-size: small;
}
.text-bold{
	font-weight: bold !important;
}
///button
.mob-filter-button-wrapper{
	text-align: center;
    position: fixed;
    padding: 5px 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    background: $lightgrey;
    width: 100%;
    max-width: 380px;
    overflow: auto;
    transform: translateX(100vw);
    transition: all ease 0.15s 0.25s;
    box-shadow: 5px 0px 7px;
}
.mob-filter-button-wrapper.active{
	transform: translateX(calc(100vw - 100%));
}
.btn-default{
    background: $colorDefault;
    color: #fff;
    cursor: pointer;
}
.w-75{
	width: 75% !important;
}
.text-unset{
	font-size: unset;
}
.text-larger{
	font-size: larger;
}
.mw-7rem{
	min-width: 7rem;
}
.widget-area .widget.widget-nopadding-noborders{
	margin: 0;
    padding: 0;
    border: none;
}
//mob categories
.mob-category-wrap{
	position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background: #FFF;
    width: 100%;
    max-width: 350px;
    height: 100vh;
    overflow: auto;
    padding: 30px 20px;
    transform: translateX(-100%);
    transition: all ease 0.15s 0.25s;
    & ul.product-categories{
    	margin: 0;
    }
    & h4{
    	font-size: 20px;
    }
}
.mob-category-wrap.active{
	box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.075);
    transform: translateX(0);
}
.lnr-cross-after:after{
	content: "\E870";
}
li.product.view-line{
	@media only screen and (max-width: 600px){
		width: 100% !important;
	}
	.button{
		margin-top: 1.4em !important;
		font-size: 1.2rem;
	}
	overflow: hidden;
    display: flex;
    height: 20vh;
    align-items: center;
    justify-content: space-between;
    & .sml-product-image-wrapp{
    	max-width: 50%;
    	max-height: 100%;
    }
    & .sml-product-wrapp{
    	margin-top: -4rem;
    }
    .sml-product-title-wrapp{
    	border-top: none;
    	text-align: right;
    }
    span.amount{
    	font-size: larger;
    	color: $colorDefault;
    }
    @media only screen and (min-width: 601px){
		width: 48% !important;
		height: 35vh;
		.sml-product-wrapp{
			margin-top: 0;
		}
		a {
			font-size: larger;
		}
	}
}
@media only screen and (max-width: 768px){
	.w-md-100{
		width: 100% !important;
	}
}
///wishlist
@media only screen and (min-width: 769px){
	li.wishlist{
	    position: relative;
	    height: 0;
	    right: 2rem;
	}
}
.wishlist {
	span.sm-cart-icon-wrap{
		padding-right: 10px;
	}
	span.icon{
		font-size: 26px;
	    top: 2px;
	    position: relative;
	    vertical-align: middle;
	}
	span.sm-cart-count{
		height: 20px;
	    width: 20px;
	    line-height: 2;
	    bottom: 9px;
	}
}
button > span.lnr.lnr-heart{
	font-size: x-large;
	font-weight: bolder;
}
.mainblack-button.button{
	display: inline-block;
    font-size: 16px;
    font-weight: 400;
    padding: 0 30px;
    line-height: 45px;
    background: #222222;
    color: #fff;
    border-radius: 0;
    width: 100%;
    text-align: center;
    &:hover{
	    background-color: $colorDefault;
	    color: $dark;
    }
}
.height{
	transition: .3s ease-in-out;
}
.height-hidden{
	height: 0px;
    min-height: 0;
    padding: 0;
    border: none;
    overflow: hidden;
}
.btn-alt{
	cursor: pointer;
	background-color: #000;
    color: $white;
    border-radius: 0;
    height: 45px;
    transition: all ease-in-out 0.5s;
    font-size: 100%;
    font-weight: bold;
    &:hover{
    	background-color: $colorDefault;
    }
}
.text-small{
	font-size: small !important;
}
.products.columns-3{
	position: relative;
}